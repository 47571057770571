import './GraphicType2.css';

function GraphicType2(props: { src: string }) {
    const { src } = props;

    return (
        <img
            draggable="false"
            className="graphic-coverimage-1"
            alt=""
            src={src}
        />
    );
}

export default GraphicType2;
