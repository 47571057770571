/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { axiosPrivate } from '../../../services/api/apiCommonConfig';
import { VaazException } from '../../../services/api/initialStates';
import { commonStatusTypes } from '../../../services/constant';
import { API } from '../../api.v1';
import { RootState } from '../../store';

export const verifyCertificateNum = createAsyncThunk(
    '/cert/verify',
    async (certificateNum: string, { rejectWithValue }) => {
        try {
            const { data } = await axiosPrivate.get(
                `${API.CERTIFICATE.VERIFY}${certificateNum}`,
            );
            return data;
        } catch (error: unknown) {
            const err = error as VaazException;
            return rejectWithValue(err.response.data);
        }
    },
);

const getCertificateInfoSlice = createSlice({
    name: '/cert/verify',
    initialState: {
        response: {},
        loading: false,
        errorMessage: '',
        status: commonStatusTypes.IDLE,
    },
    reducers: {},
    extraReducers: builder => {
        builder
            .addCase(verifyCertificateNum.pending, state => {
                state.loading = true;
                state.errorMessage = '';
                state.status = commonStatusTypes.IDLE;
            })
            .addCase(verifyCertificateNum.rejected, (state, action) => {
                state.loading = false;
                state.status = commonStatusTypes.FAILED;

                const { payload } = action;
                const errMsg = payload as VaazException['response']['data'];
                const error = errMsg || action.error;
                state.errorMessage = error.message || '';
            })
            .addCase(verifyCertificateNum.fulfilled, (state, action) => {
                state.loading = false;
                state.status = commonStatusTypes.SUCCESS;
                state.errorMessage = '';
                state.response = action.payload;
            });
    },
});

export const getCertificateInfoResult = (state: RootState) =>
    state.certificate.verify;

export default getCertificateInfoSlice.reducer;
