import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import './ActivityList.css';
import moment from 'moment';
import { useLocation, useNavigate } from 'react-router-dom';
import {
    localStorageTypes,
    MASTER_MEMBER_CODE,
    MASTER_MEMBER_DESCRIPTION,
    customFormatDate,
} from '../../services/constant';
import { URLS } from '../../services/urls';
import { ActivityListProps } from '../../services/interfaces';
import { ActivityListTypes } from '../../services/types';
import { useTranslation } from 'react-i18next';
import 'moment/locale/ms';
import { IconButton, Tooltip } from '@mui/material';
import VerifiedIcon from '@mui/icons-material/Verified';
import { useEffect, useState } from 'react';

export default function ActivityList(props: ActivityListProps) {
    moment.locale('en');
    const { i18n } = useTranslation();
    const currentLanguage = i18n.language;
    // Determine if the current locale is Malay ('ms') or English ('en')
    const isMalay = currentLanguage === 'ms';
    const navigate = useNavigate();
    const { pathname } = useLocation();
    const [listing, setListing] = useState<ActivityListTypes[]>([]);

    const { list, isFulfilment, isRemoveData } = props;
    useEffect(() => {
        setListing(list);
    }, [list]);
    const selfVerificationStatus = localStorage.getItem(
        localStorageTypes.SELF_VERIFICATION_STATUS,
    );

    const handleClick = (
        requestId: string,
        status: string,
        statusCode: string,
        subjectSvReportConsentFlag: boolean,
    ) => {
        localStorage.setItem(localStorageTypes.PREV_URL, pathname);

        if ((status || statusCode) && isFulfilment) {
            if (statusCode === MASTER_MEMBER_CODE.CANCELLED) {
                navigate(URLS.MEMBER.REQUEST.CANCELLED, {
                    state: { requestId, path: pathname },
                });
            } else {
                navigate(URLS.MEMBER.REQUEST.DETAILS, { state: { requestId } });
            }

            if (
                status === MASTER_MEMBER_DESCRIPTION.NOT_STARTED ||
                statusCode === MASTER_MEMBER_CODE.NOT_STARTED
            ) {
                navigate(URLS.MEMBER.REQUEST.INITIAL_VERIFY, {
                    state: { requestId, subjectSvReportConsentFlag },
                });
            }

            if (
                status === MASTER_MEMBER_DESCRIPTION.REQUEST_ACCEPTED ||
                statusCode === MASTER_MEMBER_CODE.REQUEST_ACCEPTED
            ) {
                navigate(URLS.MEMBER.REQUEST.ACCEPTED, {
                    state: { url: '', requestId, subjectSvReportConsentFlag },
                });
            }

            if (
                status === MASTER_MEMBER_DESCRIPTION.IN_PROGRESS ||
                statusCode === MASTER_MEMBER_CODE.IN_PROGRESS
            ) {
                localStorage.setItem(
                    localStorageTypes.FULFILMENT_REQUEST_ID,
                    requestId,
                );
                if (
                    !subjectSvReportConsentFlag ||
                    selfVerificationStatus !== 'VERIFIED'
                ) {
                    navigate(URLS.MEMBER.FULFILMENT.ID_TYPE, {
                        state: { requestId, subjectSvReportConsentFlag },
                    });
                } else {
                    navigate(URLS.MEMBER.REQUEST.FULFILMENT_OPTION, {
                        state: { requestId, subjectSvReportConsentFlag },
                    });
                }
            }

            if (status === MASTER_MEMBER_DESCRIPTION.COMPLETED) {
                navigate(URLS.MEMBER.REQUEST.RESULTS, {
                    state: { status, requestId },
                });
            }

            if (statusCode === MASTER_MEMBER_CODE.COMPLETED) {
                localStorage.setItem(
                    localStorageTypes.FULFILMENT_REQUEST_ID,
                    requestId,
                );
                navigate(`${URLS.MEMBER.FULFILMENT.STATIC}complete`, {
                    state: { id: requestId },
                });
            }
            // }
        }

        if (statusCode && !isFulfilment) {
            if (statusCode === MASTER_MEMBER_CODE.CANCELLED) {
                navigate(URLS.MEMBER.REQUEST.CANCELLED, {
                    state: { requestId, path: pathname },
                });
            } else {
                navigate(URLS.MEMBER.REQUEST.DETAILS, { state: { requestId } });
            }
        }
    };

    return (
        <Grid item xs={12} style={{ paddingTop: '0px' }}>
            <List>
                {listing.map((item: ActivityListTypes) => (
                    <ListItem
                        sx={{
                            pr: 1,
                            mb: 1,
                            background: 'white',
                            borderRadius: '10px',
                            cursor: 'pointer',
                        }}
                        className="border-1px-mercury"
                        key={item.requestId ?? item.requesterUserId}
                        onClick={() =>
                            !isRemoveData &&
                            handleClick(
                                item.requestId,
                                item.status,
                                item.statusCode,
                                item.subjectSvReportConsentFlag,
                            )
                        }
                    >
                        <Grid container spacing={1}>
                            <Grid item xs={6}>
                                <ListItemText
                                    primary={
                                        <Grid container>
                                            <Grid
                                                item
                                                xs={12}
                                                style={{
                                                    textAlign: 'left',
                                                    display: 'flex',
                                                }}
                                            >
                                                <span className="montserrat-medium-black-16px">
                                                    {item.requesterName ??
                                                        item.subjectName}
                                                </span>

                                                {item.userVerifyStatus ===
                                                    'VERIFIED' && (
                                                    <div>
                                                        <Tooltip
                                                            title="Verified User"
                                                            placement="right-start"
                                                            arrow
                                                            disableFocusListener
                                                            enterTouchDelay={0}
                                                        >
                                                            <IconButton
                                                                style={{
                                                                    padding: 0,
                                                                }}
                                                            >
                                                                <VerifiedIcon className="appbar-verified-icon appbar-verified-icon-additional" />
                                                            </IconButton>
                                                        </Tooltip>
                                                    </div>
                                                )}
                                            </Grid>
                                        </Grid>
                                    }
                                    secondary={
                                        <span className="montserrat-light-eastern-blue-12px">
                                            {customFormatDate(
                                                item.createDateTime ??
                                                    item.createdDatetime,
                                                'D MMMM YYYY, hh:mm A',
                                                isMalay,
                                                true,
                                            )}
                                        </span>
                                    }
                                />
                            </Grid>
                            <Grid item xs={6} style={{ paddingLeft: '0px' }}>
                                <ListItemText
                                    sx={{ textAlign: 'right' }}
                                    primary={
                                        !isRemoveData && (
                                            <div>
                                                <span
                                                    className={
                                                        item.status ===
                                                            MASTER_MEMBER_DESCRIPTION.IN_PROGRESS ||
                                                        item.statusCode ===
                                                            MASTER_MEMBER_CODE.IN_PROGRESS ||
                                                        item.status ===
                                                            MASTER_MEMBER_DESCRIPTION.COMPLETED ||
                                                        item.statusCode ===
                                                            MASTER_MEMBER_CODE.COMPLETED
                                                            ? 'success-status-dot'
                                                            : 'error-status-dot'
                                                    }
                                                />
                                                <span
                                                    className={
                                                        item.status ===
                                                            MASTER_MEMBER_DESCRIPTION.IN_PROGRESS ||
                                                        item.statusCode ===
                                                            MASTER_MEMBER_CODE.IN_PROGRESS ||
                                                        item.status ===
                                                            MASTER_MEMBER_DESCRIPTION.COMPLETED ||
                                                        item.statusCode ===
                                                            MASTER_MEMBER_CODE.COMPLETED
                                                            ? 'success-status'
                                                            : 'error-status'
                                                    }
                                                    // style={{
                                                    //     whiteSpace:
                                                    //         'break-spaces',
                                                    // }}
                                                >
                                                    {item.status ??
                                                        item.statusDescription}
                                                </span>
                                            </div>
                                        )
                                    }
                                />
                            </Grid>
                        </Grid>
                    </ListItem>
                ))}
            </List>
        </Grid>
    );
}
