/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/no-danger */
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import GraphicType2 from '../../../components/GraphicType2';
import { PrimaryButton, SecondaryButton } from '../../../components/Button';
import AppBarWithoutMenu from '../../../components/AppBar/AppBarWithoutMenu';
import { AppBarBody } from '../../../components/AppBar/AppBarBody';
import {
    AuthTokens,
    CustomizedState,
    ResultPageProps,
} from '../../../services/interfaces';
import { ERROR_CODE, localStorageTypes } from '../../../services/constant';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Spinner from '../../../components/Spinner';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import {
    resendVerificationEmail,
    resendVerificationEmailResult,
} from '../../../store/slices/register/resendVerificationEmailSlice';
import AlertMessage from '../../../components/Alert';
import { URLS } from '../../../services/urls';
import { ReqRegister } from '../../../services/api/requestBody';
import { useTranslation } from 'react-i18next';

export default function VerifyRegisterFailedPage(props: ResultPageProps) {
    const {
        title,
        appBarTitle,
        button,
        link,
        imgUrl,
        isIncludeAppBar,
        description,
    } = props;

    const location = useLocation();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const stateResult = location.state as CustomizedState;
    const { t } = useTranslation();

    const prevUrl = localStorage.getItem(localStorageTypes.PREV_URL);
    const authTokens: AuthTokens = JSON.parse(
        localStorage.getItem(localStorageTypes.AUTH_TOKENS) || '{}',
    );
    const isFromRequest = localStorage.getItem(
        localStorageTypes.IS_FROM_REQUEST,
    );

    const [prevLink, setPrevLink] = useState('/');
    const [loading, setLoading] = useState(false);
    const [backdropOpen, setBackdropOpen] = useState(false);

    useEffect(() => {
        if (stateResult === null) {
            localStorage.clear();
            navigate('/');
        }
    }, [stateResult, navigate]);

    useEffect(() => {
        if (authTokens.refreshToken) {
            setPrevLink(URLS.MEMBER.HOME.MAIN);
        }

        if (prevUrl) {
            setPrevLink(prevUrl);
        }
    }, [authTokens.refreshToken, prevLink, prevUrl]);

    const onButtonClick = () => {
        localStorage.removeItem(localStorageTypes.IS_FROM_REQUEST);

        setLoading(true);
        setBackdropOpen(true);

        setTimeout(() => {
            setLoading(false);
            setBackdropOpen(false);

            if (isFromRequest === 'true') {
                navigate(URLS.MEMBER.REQUEST.CREATE_NEW_REQUEST_CONFIRM);
            } else {
                navigate(link);
                if (link === URLS.PUBLIC.LOGIN.MAIN) {
                    window.location.reload();
                }
            }
        }, 5000);
    };

    const onClickResendButton = () => {
        const reqBody: ReqRegister['resendVerificationEmail'] = {
            email: null,
            verificationCode: stateResult.verificationCode,
        };
        dispatch(resendVerificationEmail(reqBody));
    };

    const getResendEmailResult = useAppSelector(resendVerificationEmailResult);

    return (
        <>
            {isIncludeAppBar ? (
                <>
                    <AppBarWithoutMenu title={appBarTitle} link={prevLink} />
                    <AppBarBody />
                </>
            ) : (
                <div />
            )}

            {loading ? <Spinner open={backdropOpen} /> : <div />}

            <Box sx={{ p: 4, textAlign: 'center' }}>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        {getResendEmailResult.response === t('text.success') ? (
                            <AlertMessage
                                showAlert
                                errorMessage={
                                    getResendEmailResult.response as string
                                }
                                severity="success"
                            />
                        ) : (
                            <div />
                        )}
                        <GraphicType2 src={imgUrl} />
                    </Grid>
                    <Grid item xs={12}>
                        <span className="montserrat-medium-black-22px">
                            {title}
                        </span>
                    </Grid>
                    <Grid item xs={12}>
                        <span
                            className="montserrat-medium-black-17px"
                            dangerouslySetInnerHTML={{
                                __html: stateResult.errorMessage || description,
                            }}
                        />
                    </Grid>

                    <Grid item xs={12} style={{ paddingTop: '30%' }}>
                        <PrimaryButton
                            isFullWidth
                            buttonName={button}
                            callback={onButtonClick}
                            disabled={false}
                            type="button"
                        />
                    </Grid>

                    {stateResult.errorCode ===
                        ERROR_CODE.REGISTER_VERIFICATION_LINK_EXPIRED &&
                        getResendEmailResult.response !== t('text.success') && (
                            <Grid item xs={12}>
                                <Grid item xs={12}>
                                    <SecondaryButton
                                        isFullWidth
                                        buttonName={t('button.resend')}
                                        callback={onClickResendButton}
                                        disabled={false}
                                        type="button"
                                    />
                                </Grid>
                                <Grid item xs={12} />
                            </Grid>
                        )}
                </Grid>
            </Box>
        </>
    );
}
