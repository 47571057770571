export interface VaazException {
    response: {
        data: {
            code?: string;
            error?: string;
            message?: string;
            path?: string;
            status?: number;
            timestamp?: string;
        };
    };
}

export interface ConsentList {
    consentChecked: boolean;
    consentCode: string;
    consentDescription: string;
}

export interface RequestCheckItems {
    id: string;
    masterCode: string;
    masterMemberCode: string;
    masterMemberDesc: string;
    masterMemberParentCode: string | null;
    sortOrder: number;
    status: string;
}

export interface IGenericState<T> {
    meta?: {
        requestStatus?: T;
    };
    payload?: {
        code?: T;
        message?: T;
    };
}

export interface IParameter {
    id: string;
    masterCode: string;
    masterMemberCode: string;
    masterMemberDesc: string;
    masterMemberParentCode: string | null;
    sortOrder: number;
    status: string;
}

export const ISParameter = [
    {
        id: '',
        masterCode: '',
        masterMemberCode: '',
        masterMemberDesc: '',
        masterMemberParentCode: null,
        sortOrder: -1,
        status: '',
    },
];

export interface IRegister {
    parameter: {
        id: string;
        masterCode: string;
        masterMemberCode: string;
        masterMemberDesc: string;
        masterMemberParentCode: string;
        sortOrder: string;
        status: string;
    };
}

export const ISRegister = {
    parameter: [
        {
            id: '',
            masterCode: '',
            masterMemberCode: '',
            masterMemberDesc: '',
            masterMemberParentCode: '',
            sortOrder: '',
            status: '',
        },
    ],
};

export interface IPLanguage {
    parameter: {
        id: string;
        masterCode: string;
        masterMemberCode: string;
        masterMemberDesc: string;
        masterMemberParentCode: string;
        sortOrder: string;
        status: string;
    };
}

export const ISPLanguage = {
    parameter: [
        {
            id: '',
            masterCode: '',
            masterMemberCode: '',
            masterMemberDesc: '',
            masterMemberParentCode: '',
            sortOrder: '',
            status: '',
        },
    ],
};

export interface IRequest {
    orderSummary: {
        orderTotalCredits: number;
        currentBalance: number;
        newBalance: number;
        status: string;
    };

    request: {
        referenceId: string;
        requestId: string;
        requesterUserId: string;
        statusCode: string;
        subjectCountryCode: string;
        subjectEmail: string;
        subjectName: string;
        subjectPhoneNumber: string;
    };

    result: {
        additionalDetails: string[];
        addressEdit: string;
        address: string;
        birthDate: string;
        birthDateEdit: string;
        documentNumber: string;
        documentNumberEdit: string;
        documentType: string;
        gender: string;
        genderEdit: string;
        imgIcBack: string;
        imgIcFront: string;
        imgSelfie: string;
        issuingState: string;
        issuingStateEdit: string;
        journeyId: string;
        name: string;
        nameEdit: string;
        requestId: string;
        requestStatus: string;
        scorecardRating: string;
        scorecardRatingLabel: string;
        updatedDate: string;
        updatedTime: string;
        ipAddress: string;
        dataObfuscationConsentFlag: boolean;
        dataRemoveFlag: boolean;
    };
}

export const ISRequest = {
    orderSummary: {
        orderTotalCredits: 0,
        currentBalance: 0,
        newBalance: 0,
        discountAmount: 0,
        status: '',
    },

    request: {
        referenceId: '',
        requestId: '',
        requesterUserId: '',
        statusCode: '',
        subjectCountryCode: '',
        subjectEmail: '',
        subjectName: '',
        subjectPhoneNumber: '',
    },

    fulfilment: {
        requestId: '',
        requesterName: '',
        requesterUserId: '',
        requesterEmail: '',
        vaazmeCheckedItems: [],
        requestFulfilmentConsents: ISParameter,
        userVerifyStatus: '',
    },

    result: {
        additionalDetails: [''],
        addressEdit: '',
        address: '',
        birthDate: '',
        birthDateEdit: '',
        documentNumber: '',
        documentNumberEdit: '',
        documentType: '',
        genderEdit: '',
        gender: '',
        imgIcBack: '',
        imgIcFront: '',
        imgSelfie: '',
        issuingState: '',
        issuingStateEdit: '',
        journeyId: '',
        nameEdit: '',
        name: '',
        requestId: '',
        requestStatus: '',
        scorecardRating: '',
        scorecardRatingLabel: '',
        updatedDate: '',
        updatedTime: '',
        ipAddress: '',
        dataObfuscationConsentFlag: false,
        dataRemoveFlag: false,
    },

    selfVerification: {
        requestId: '',
        requesterUserId: '',
        subjectName: '',
        subjectEmail: '',
        subjectPhoneNumber: '',
        subjectCountryCode: '',
        subjectUserId: '',
        referenceId: '',
        statusCode: '',
        statusDescription: '',
        createdDatetime: '',
        orderTotal: 0,
        promoCode: '',
        discountAmount: 0,
        discountType: '',
        vaazmeCheckedItems: [''],
        parameters: [
            {
                id: '',
                masterCode: '',
                masterMemberParentCode: '',
                masterMemberCode: '',
                masterMemberDesc: '',
                sortOrder: 0,
                status: '',
            },
        ],
    },
};

export const ISPromoCode = {
    promoCode: {
        discountAmount: 0,
        discountType: '',
        endDateTime: '',
        promoCode: '',
        startDateTime: '',
    },
};

export interface IVaazCredit {
    searchContent: {
        createdBy: string;
        createdDatetime: string;
        credit: number;
        message: string;
        referenceId: string;
        statusCode: string;
        title: string;
        transactionDatetime: string;
        transactionId: string;
        transactionTypeCode: string;
        transactionTypeDescription: string;
        updatedBy: string;
        updatedDatetime: string;
        userId: string;
    };

    search: {
        totalElements: number;
        totalPages: number;
        contents: IVaazCredit['searchContent'][];
    };
}

export const ISVaazCredit = {
    search: {
        totalElements: 0,
        totalPages: 0,
        contents: [
            {
                createdBy: '',
                createdDatetime: '',
                credit: 0,
                message: '',
                referenceId: '',
                statusCode: '',
                title: '',
                transactionDatetime: '',
                transactionId: '',
                transactionTypeCode: '',
                transactionTypeDescription: '',
                updatedBy: '',
                updatedDatetime: '',
                userId: '',
            },
        ],
    },
};

export interface IMessage {
    messageContent: {
        messageId: string;
        requestId: string;
        requesterUserId: string;
        requesterName: string;
        subjectName: string;
        subjectEmail: string;
        subjectPhoneNumber: string;
        requestStatusCode: string;
        requestStatusDescription: string;
        messageTypeCode: string;
        subject: string;
        body: string;
        recipientUserId: string;
        readFlag: boolean;
        msgStatusCode: string;
        createdDatetime: string;
        lastUpdatedDatetime: string;
        subjectSvReportConsentFlag: boolean;
    };

    message: {
        contents: IMessage['messageContent'][];
        totalElements: number;
        totalPages: number;
    };
}

export const ISMessage = {
    message: {
        totalElements: 0,
        totalPages: 0,
        contents: [
            {
                messageId: '',
                requestId: '',
                requesterUserId: '',
                requesterName: '',
                subjectName: '',
                subjectEmail: '',
                subjectPhoneNumber: '',
                requestStatusCode: '',
                requestStatusDescription: '',
                messageTypeCode: '',
                subject: '',
                body: '',
                recipientUserId: '',
                readFlag: false,
                msgStatusCode: '',
                createdDatetime: '',
                lastUpdatedDatetime: '',
                subjectSvReportConsentFlag: false,
            },
        ],
    },
};

export interface IFulfilment {
    confirmation: {
        address: string;
        dateOfBirth: string;
        documentNumber: string;
        gender: string;
        idType: string;
        imgIdBack: string;
        imgIdFront: string;
        imgSelfie: string;
        issuingStateName: string;
        name: string;
        requestId: string;
        additionalDetails: string[];
        scorecardRatingLabel: string;
        scorecardRating: string;
        addressEdit: string;
        dateOfBirthEdit: string;
        documentNumberEdit: string;
        genderEdit: string;
        issuingStateNameEdit: string;
        nameEdit: string;
        updatedDate: string;
        updatedTime: string;
        ipAddress: string;
        dataObfuscationConsentFlag: boolean;
        dataRemoveFlag: boolean;
    };
}

export const ISFulfilment = {
    confirmation: {
        address: '',
        dateOfBirth: '',
        documentNumber: '',
        gender: '',
        idType: '',
        imgIdBack: '',
        imgIdFront: '',
        imgSelfie: '',
        issuingStateName: '',
        name: '',
        requestId: '',
        additionalDetails: [''],
        scorecardRatingLabel: '',
        scorecardRating: '',
        updatedDate: '',
        updatedTime: '',
        ipAddress: '',

        addressEdit: '',
        dateOfBirthEdit: '',
        documentNumberEdit: '',
        genderEdit: '',
        issuingStateNameEdit: '',
        nameEdit: '',
    },
};

export interface ILogin {
    requestId: string;
    requestStatus: string;
    accessToken: string;
    refreshToken: string;
    tokenType: string;
    expiresIn: string;
    userId: string;
    lastLoginDatetime: string;
}

export const ISLogin = {
    requestId: '',
    requestStatus: '',
    accessToken: '',
    refreshToken: '',
    tokenType: '',
    expiresIn: '',
    userId: '',
    lastLoginDatetime: '',
};
