import { combineReducers } from '@reduxjs/toolkit';
import getDashboardSlice from './dashboardSlice';
import authentication from './authentication';
import profile from './profile';
import register from './register';
import requests from './requests';
import messages from './messages';
import fulfilment from './fulfilment';
import credit from './credit';
import shares from './shares';
import contact from './contact';
import promocode from './promocode';
import certificate from './certificate';
import data from './data';

export default combineReducers({
    authentication,
    profile,
    register,
    requests,
    messages,
    fulfilment,
    credit,
    promocode,
    shares,
    contact,
    dashboard: getDashboardSlice,
    certificate,
    data,
});
