import {
    DataAuthenticationProps,
    DetailPageProps,
} from '../../../services/interfaces';
import { useLocation } from 'react-router-dom';
import { useEffect, useState } from 'react';
import './index.css';
import logo from '../../../assets/img/logo@2x.svg';
import { Box, Grid } from '@mui/material';
import { Table1, Table2 } from './Table';

export default function DetailPage(props: DetailPageProps) {
    const { title, descriptionOne, descriptionTwo } = props;
    const location = useLocation();
    const { state } = location;
    const [certificateNumInfo, setCertificateNumInfo] =
        useState<DataAuthenticationProps>({} as DataAuthenticationProps);

    useEffect(() => {
        document.body.style.maxWidth = '100%';
        const data = state as DataAuthenticationProps;
        console.log(data);
        setCertificateNumInfo(data);
        return () => {
            document.body.style.maxWidth = '480px';
        };
    }, [state]);

    return (
        <Box sx={{ p: 4, textAlign: 'center' }}>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <img className="logo" alt="" src={logo} />
                </Grid>
                <Grid item xs={12}>
                    <div className="welcome-to-vaaz-me montserrat-medium-black-22px">
                        {title}
                    </div>
                </Grid>
                <Grid item xs={12} style={{ textAlign: 'center' }}>
                    <p
                        className="montserrat-medium-black-18px"
                        style={{
                            lineHeight: '30px',
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'center',
                            fontWeight: 'bolder',
                        }}
                    />
                    <p>&nbsp;</p>
                    <p
                        className="montserrat-medium-black-16px"
                        style={{ lineHeight: '30px' }}
                    >
                        {descriptionOne}
                    </p>
                </Grid>
            </Grid>
            <Grid item xs={12} style={{ textAlign: 'center' }}>
                <p
                    className="montserrat-medium-black-18px"
                    style={{
                        lineHeight: '30px',
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'center',
                        fontWeight: 'bolder',
                    }}
                />
                <p>&nbsp;</p>
                <p
                    className="montserrat-medium-black-16px"
                    style={{ lineHeight: '30px' }}
                >
                    {descriptionTwo}
                </p>
            </Grid>
            <Grid item xs={2} className="text-center">
                <div className="watermarked-div">
                    <div className="reportTable1">
                        <Table1
                            address={certificateNumInfo.address}
                            birthDate={certificateNumInfo.birthDate}
                            documentNumber={certificateNumInfo.documentNumber}
                            gender={certificateNumInfo.gender}
                            idType={certificateNumInfo.idType}
                            imgIcBack={certificateNumInfo.imgIcBack}
                            imgIcFront={certificateNumInfo.imgIcFront}
                            imgSelfie={certificateNumInfo.imgSelfie}
                            issuingStateName={
                                certificateNumInfo.issuingStateName
                            }
                            name={certificateNumInfo.name}
                            additionalDetails={
                                certificateNumInfo.additionalDetails
                            }
                            scorecardRatingLabel={
                                certificateNumInfo.scorecardRatingLabel
                            }
                            scorecardRating={certificateNumInfo.scorecardRating}
                            updatedDate={certificateNumInfo.updatedDate}
                            updatedTime={certificateNumInfo.updatedTime}
                            ipAddress={certificateNumInfo.ipAddress}
                            requesterName={certificateNumInfo.requesterName}
                            addressEdit={certificateNumInfo.addressEdit}
                            birthDateEdit={certificateNumInfo.birthDateEdit}
                            documentNumberEdit={
                                certificateNumInfo.documentNumberEdit
                            }
                            genderEdit={certificateNumInfo.genderEdit}
                            issuingStateNameEdit={
                                certificateNumInfo.issuingStateNameEdit
                            }
                            nameEdit={certificateNumInfo.nameEdit}
                        />
                    </div>
                    <div className="reportTable2">
                        <Table2
                            address={certificateNumInfo.address}
                            birthDate={certificateNumInfo.birthDate}
                            documentNumber={certificateNumInfo.documentNumber}
                            gender={certificateNumInfo.gender}
                            idType={certificateNumInfo.idType}
                            imgIcBack={certificateNumInfo.imgIcBack}
                            imgIcFront={certificateNumInfo.imgIcFront}
                            imgSelfie={certificateNumInfo.imgSelfie}
                            issuingStateName={
                                certificateNumInfo.issuingStateName
                            }
                            name={certificateNumInfo.name}
                            additionalDetails={
                                certificateNumInfo.additionalDetails
                            }
                            scorecardRatingLabel={
                                certificateNumInfo.scorecardRatingLabel
                            }
                            scorecardRating={certificateNumInfo.scorecardRating}
                            updatedDate={certificateNumInfo.updatedDate}
                            updatedTime={certificateNumInfo.updatedTime}
                            ipAddress={certificateNumInfo.ipAddress}
                            requesterName={certificateNumInfo.requesterName}
                            addressEdit={certificateNumInfo.addressEdit}
                            birthDateEdit={certificateNumInfo.birthDateEdit}
                            documentNumberEdit={
                                certificateNumInfo.documentNumberEdit
                            }
                            genderEdit={certificateNumInfo.genderEdit}
                            issuingStateNameEdit={
                                certificateNumInfo.issuingStateNameEdit
                            }
                            nameEdit={certificateNumInfo.nameEdit}
                        />
                    </div>
                </div>
            </Grid>
        </Box>
    );
}
