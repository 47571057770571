/* eslint-disable no-param-reassign */
import axios, { AxiosRequestConfig } from 'axios';
import { AuthTokens } from '../interfaces';
import { localStorageTypes } from '../constant';
import { BASE_URL, BASE_MOCK_URL, TIMEOUT } from './config';

export default axios.create({
    baseURL: BASE_URL,
    timeout: TIMEOUT,
});

export const mockJsonAxios = axios.create({
    baseURL: BASE_MOCK_URL,
    timeout: TIMEOUT,
});

export const axiosPrivate = axios.create({
    baseURL: BASE_URL,
    timeout: TIMEOUT,
});

let handleUnauthorized: () => void;

axiosPrivate.interceptors.request.use(
    (config: AxiosRequestConfig): AxiosRequestConfig => {
        const authTokens: AuthTokens = JSON.parse(
            localStorage.getItem(localStorageTypes.AUTH_TOKENS) || '{}',
        );
        const selectedLanguage = localStorage.getItem('i18nextLng') || 'en';

        if (config.headers !== undefined) {
            config.headers.Authorization = `${authTokens.tokenType} ${authTokens.accessToken}`;
            config.headers['Accept-Language'] = `${selectedLanguage}`;
        }

        return config;
    },
);

axiosPrivate.interceptors.response.use(
    response => response,
    error => {
        // Check if the response status is 401
        if (error.response && error.response.status === 401) {
            handleUnauthorized();
        }

        return Promise.reject(error);
    },
);

export function setUnauthorizedHandler(callback: () => void) {
    handleUnauthorized = callback;
}
