/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { axiosPrivate } from '../../../services/api/apiCommonConfig';
import {
    IRequest,
    ISRequest,
    VaazException,
} from '../../../services/api/initialStates';
import { commonStatusTypes } from '../../../services/constant';
import { API } from '../../api.v1';
import { RootState } from '../../store';

export const getRequestSelfVerificationResult = createAsyncThunk(
    '/requests/self-verification/result/get',
    async (requestId: string, { rejectWithValue }) => {
        try {
            const { data } = await axiosPrivate.get(
                `${API.REQUEST.SELF_VERIFICATION_RESULT}${requestId}`,
            );

            return data;
        } catch (error: unknown) {
            const err = error as VaazException;
            return rejectWithValue(err.response.data);
        }
    },
);

const getRequestSelfVerificationResultSlice = createSlice({
    name: '/requests/self-verification/result/get',
    initialState: {
        response: ISRequest.result,
        loading: false,
        errorMessage: '',
        status: commonStatusTypes.IDLE,
    },
    reducers: {},
    extraReducers: builder => {
        builder
            .addCase(getRequestSelfVerificationResult.pending, state => {
                state.loading = true;
                state.status = commonStatusTypes.IDLE;
            })
            .addCase(
                getRequestSelfVerificationResult.rejected,
                (state, action) => {
                    state.loading = false;
                    state.status = commonStatusTypes.FAILED;

                    const { payload } = action;
                    const errMsg = payload as VaazException['response']['data'];
                    const error = errMsg || action.error;
                    state.errorMessage = error.message || '';
                },
            )
            .addCase(
                getRequestSelfVerificationResult.fulfilled,
                (state, action) => {
                    state.loading = false;
                    state.status = commonStatusTypes.SUCCESS;
                    const data: IRequest['result'] = action.payload;
                    if (data.address) {
                        const search = '^';
                        const replaceWith = '\n';
                        data.address = data.address
                            .split(search)
                            .join(replaceWith); // .replace(/^/g, '\n');
                        if (data.addressEdit) {
                            data.addressEdit = data.addressEdit
                                .split(search)
                                .join(replaceWith); // .replace(/^/g, '\n');
                        }
                    }
                    state.response = data;
                },
            );
    },
});

export const getResultOfRequestSelfVerification = (state: RootState) =>
    state.requests.selfVerificationResults;

export default getRequestSelfVerificationResultSlice.reducer;
