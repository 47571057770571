/* eslint-disable react/no-danger */
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import GraphicType2 from '../../components/GraphicType2';
import { PrimaryButton } from '../../components/Button';
import AppBarWithoutMenu from '../../components/AppBar/AppBarWithoutMenu';
import { AppBarBody } from '../../components/AppBar/AppBarBody';
import {
    AuthTokens,
    CustomizedState,
    ResultPageProps,
} from '../../services/interfaces';
import { localStorageTypes } from '../../services/constant';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Spinner from '../../components/Spinner';
import { URLS } from '../../services/urls';
import { getSponsoredMessage } from '../../store/slices/requests/getSponsoredMessageSlice';
import { useAppDispatch } from '../../store/hooks';
import SponsoredMessage from '../../components/SponsoredMessage';

export default function ResultPage(props: ResultPageProps) {
    const {
        title,
        appBarTitle,
        description,
        button,
        link,
        imgUrl,
        isIncludeAppBar,
    } = props;

    const location = useLocation();
    const navigate = useNavigate();
    const [sponsorMsg, setSponsorMsg] = useState('');
    const [sponsorImg, setSponsorImg] = useState('');
    const [requestId, setRequestId] = useState('');
    const access = location.state as CustomizedState;

    useEffect(() => {
        if (access === null) {
            localStorage.clear();
            navigate('/');
        } else if (access.requestId) {
            setRequestId(access.requestId);
        }
    }, [access, navigate]);

    const localStoragePrevUrl = localStorage.getItem(
        localStorageTypes.PREV_URL,
    );
    const authTokens: AuthTokens = JSON.parse(
        localStorage.getItem(localStorageTypes.AUTH_TOKENS) || '{}',
    );
    const isFromRequest = localStorage.getItem(
        localStorageTypes.IS_FROM_REQUEST,
    );

    const [prevLink, setPrevLink] = useState('/');
    const [loading, setLoading] = useState(false);
    const [backdropOpen, setBackdropOpen] = useState(false);
    const dispatch = useAppDispatch();

    useEffect(() => {
        if (authTokens.refreshToken) {
            setPrevLink(URLS.MEMBER.HOME.MAIN);
        }

        if (authTokens.requestId) {
            setRequestId(authTokens.requestId);
        }

        if (requestId) {
            dispatch(getSponsoredMessage(requestId || '')).then(res => {
                if (res.payload.sponsoredMsg) {
                    setSponsorMsg(res.payload.sponsoredMsg);
                    setSponsorImg(res.payload.sponsoredLogo || '');
                }
            });
        }

        if (localStoragePrevUrl) {
            setPrevLink(localStoragePrevUrl);
        }
    }, [
        authTokens.refreshToken,
        prevLink,
        localStoragePrevUrl,
        authTokens.requestId,
        dispatch,
        requestId,
    ]);

    const onButtonClick = () => {
        localStorage.removeItem(localStorageTypes.IS_FROM_REQUEST);
        setLoading(true);
        setBackdropOpen(true);

        setTimeout(() => {
            setLoading(false);
            setBackdropOpen(false);

            if (isFromRequest === 'true') {
                navigate(URLS.MEMBER.REQUEST.CREATE_NEW_REQUEST_CONFIRM);
            } else {
                navigate(link);
                if (link === URLS.PUBLIC.LOGIN.MAIN) {
                    localStorage.clear();
                    window.location.reload();
                }
            }
        }, 5000);
    };

    const onClickBackButton = () => {
        localStorage.removeItem(localStorageTypes.PREV_URL);
        navigate(prevLink);
    };

    return (
        <>
            {isIncludeAppBar ? (
                <>
                    <AppBarWithoutMenu
                        title={appBarTitle}
                        link=""
                        callbackBackButton={onClickBackButton}
                    />
                    <AppBarBody />
                </>
            ) : (
                <div />
            )}

            {loading ? <Spinner open={backdropOpen} /> : <div />}

            <Box sx={{ p: 4, textAlign: 'center' }}>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <GraphicType2 src={imgUrl} />
                    </Grid>
                    <Grid item xs={12}>
                        <span className="montserrat-medium-black-22px">
                            {title}
                        </span>
                    </Grid>
                    <Grid item xs={12}>
                        <span
                            className="montserrat-medium-black-17px"
                            style={{ lineHeight: '30px' }}
                            dangerouslySetInnerHTML={{ __html: description }}
                        />
                    </Grid>
                    {sponsorMsg ? (
                        <Grid item xs={12}>
                            <SponsoredMessage
                                sponsorMsg={sponsorMsg}
                                sponsorImg={sponsorImg}
                            />
                        </Grid>
                    ) : (
                        false
                    )}
                    <Grid item xs={12} style={{ paddingTop: '30%' }}>
                        <PrimaryButton
                            isFullWidth
                            buttonName={button}
                            callback={onButtonClick}
                            disabled={false}
                            type="button"
                        />
                    </Grid>
                </Grid>
            </Box>
        </>
    );
}
