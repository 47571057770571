import { combineReducers } from '@reduxjs/toolkit';
import getCreditsSlice from './creditsSlice';
import getCreditsSearchSlice from './searchSlice';
import getCreditsParameterSlice from './parameterSlice';
import submitCreditTopupSlice from './topupSlice';
import getTopUpOfflineParameterSlice from './topupOfflineParameterSlice';
import submitCreditTopupOfflineSlice from './topupOfflineSlice';

export default combineReducers({
    credits: getCreditsSlice,
    search: getCreditsSearchSlice,
    parameters: getCreditsParameterSlice,
    topup: submitCreditTopupSlice,
    topupOfflineParameter: getTopUpOfflineParameterSlice,
    topupOffline: submitCreditTopupOfflineSlice,
});
