export const properties = {
    button: {
        submit: 'SUBMIT',
        close: 'CLOSE',
        i_am_ready: "I'M READY",
        use_this_photo: 'USE THIS PHOTO',
        take_another_photo: 'TAKE ANOTHER PHOTO',
        skip: 'SKIP',
        view_all: 'VIEW ALL',
        next: 'NEXT',
        ok: 'OKAY',
        proceed: 'Proceed',
        save: 'SAVE',
        edit: 'EDIT',
        verify_yourself: 'VERIFY YOURSELF',
        logout: 'LOG OUT',
        delete: 'DELETE',
        register_now: 'REGISTER NOW',
        results: 'RESULTS',
        begin_fulfilment: 'BEGIN FULFILMENT',
        start_fulfilment: 'Start Fulfilment',
        back_to_home: 'BACK TO HOME',
        make_payment_now: 'MAKE PAYMENT NOW',
        topup: 'TOP UP',
        login_now: 'LOG IN NOW',
        cancel: 'CANCEL',
        retake_photo: 'Retake Photo',
        done: 'Done',
        add_subject_details: "Add Subject's Details",
        confirm_request: 'Confirm VaazMe Request',
        create_request: 'Create VaazMe Request',
        cancel_request: 'Cancel Request',
        confirm: 'Confirm',
        verify_now: 'Verify Now',
        save_changes: 'Save Changes',
        resend: 'Resend Verification Email',
        need_more_credits: 'Need More Credits',
        start_now: 'Start Now',
        start_again: 'Start Again',
        yes: 'Yes',
        back: 'Back',
        apply: 'APPLY',
        remove: 'REMOVE',
    },

    // AppBar title
    app_bar_title: {
        fulfil_vaazme_request: 'Fulfil VaazMe Request',
        submit_vaazme_fulfilment: 'Submit VaazMe Fulfilment',
        result_vaazme_fulfilment: 'VaazMe Fulfilment Results',
        history: 'History',
        message: 'Messages',
        pdpa: 'VaazMe Privacy Policy',
        terms_and_conditions: 'TERMS AND CONDITIONS',
        terms_laid_out_in_id_verification: 'ID VERIFICATION TERMS & CONDITIONS',
        profile: 'Profile',
        register_now: 'Register Now',
        create_new_vaazme_request: 'Create New VaazMe Request',
        vaazme_request: 'VaazMe Request',
        verification_results: 'VaazMe Verification Results',
        vaazme_verification_request: 'VaazMe Verification Request',
        vaazme_terms_accepted: 'VaazMe T&C Accepted',
        vaaz_credits: 'VaazCredits',
        top_up_vaaz_credits: 'Top Up VaazCredits',
        login: 'LOG IN',
        get_more_credits: 'Get More Credits',
        request_accepted: 'Request Accepted',
        setting: 'Settings',
    },

    // Error
    error: {
        an_error_has_occurred: 'An error has occurred',
        message_no_image_preview: 'Image cannot be previewed.',
        okay_id_return_error: 'An error has occurred (ID)',
        okay_face_return_error: 'An error has occurred (Face)',
        all_fields_required: 'All fields are required.',
        number_of_credits_must_more_than_zero:
            'Please top up at least 1 credit.',
        this_is_required: 'This field is required.',
        max_length_messages: 'Maximum is 1000 characters',
        insufficient_credits: 'Insufficient Credits',
        an_error_has_occurred_please_try_again:
            'An error has occurred. Please try again.',
        fill_up_the_form_to_continue: 'Please fill up the form to continue.',
        phone_number_is_not_valid: 'Phone number is not valid',
        not_able_to_process_fulfilment:
            'Not able to process your fulfilment. You need to retake the images.',
        not_able_to_retrieve_score_card_rating:
            'Not able to retrieve Score Card',
        sorry: 'Sorry!',
    },

    // text field label
    label: {
        name: 'Name',
        email: 'Email',
        country: 'Country',
        phone_number: 'Phone Number',
        messages: 'Message',
        amount_due: 'Amount Due',
        number_of_credits_to_top_up: 'Number of Credits To Top Up',
        document_number: 'Document Number',
        gender: 'Gender',
        date_of_birth: 'Date Of Birth',
        issuing_state_name: 'Issuing State Name',
        address: 'Address',
        watermark: 'Add a custom watermark (optional)',
        promo_code: 'Promo Code',
        preferred_language: 'Preferred Language',
        updatedDate: 'Updated Date',
        updatedTime: 'Updated Time',
        ipAddress: 'IP Address',
        companyName: 'Company Name (Optional)',
        companyAddress: 'Company Address (Optional)',
        companyTelNo: 'Company Tel No (Optional)',
    },

    // common
    text: {
        welcome: 'Welcome',
        alert: 'Alert',
        success: 'SUCCESS',
        let_get_started: "Let's get started!",
        vaazme_score_card_rating: 'VaazMe Results',
        login_instead: 'Login instead',
        proceed_to_registration: 'Proceed to Registration',
        verifying_user_email: "Verifying User's Email",
        document_details: 'DOCUMENT DETAILS',
        enter_promo_code: 'Enter Promo Code',
        self_verification_message:
            'Perform a self-verification to complete account setup. You may only send {requestLeft} more VaazMe requests until this step is completed.',

        fulfilment: {
            information_will_be_encrypted:
                'Your information will be encrypted, stored securely, and will only ever be used to verify your identity.',
            please_select_your_id_type: 'Please select your ID type',
            avoid_light_reflection_glare: 'Avoid light reflection/glare',
            cancelled:
                'The Requester has cancelled this VaazMe Request. You are not able to proceed with fulfilling this Request',

            passport: {
                label: 'Photo of your passport',
                capture: 'Capture passport photo',
                take_a_photo: 'Take a photo of your passport',
                within_frame: 'Make sure your passport is within the frame.',
                preview:
                    "Is your passport in frame and easy to read? If so, you're good to go!",

                get_ready: 'Get ready with your passport',
                align_within_frame: 'Align your passport within the frame',
                avoid_blocking_shadow:
                    'Avoid blocking/casting a shadow on your passport',
            },

            id: {
                get_ready: 'Get ready with your ID card',
                align_within_frame: 'Align your ID card within the frame',
                avoid_blocking_shadow:
                    'Avoid blocking/casting a shadow on your ID card',

                front: {
                    label: 'Front of your ID card',
                    capture: 'Capture front ID photo',
                    take_a_photo:
                        'Take a photo of the front of your <br /> ID card',
                    within_frame:
                        'Make sure the front of your ID card <br /> is within the frame.',
                    preview:
                        "Is the front of your ID card in frame and easy to read? If so, you're good to go!",
                },

                back: {
                    label: 'Back of your ID card',
                    capture: 'Capture back ID photo',
                    take_a_photo:
                        'Take a photo of the back of your <br /> ID card',
                    within_frame:
                        'Make sure the back of your ID card <br /> is within the frame.',
                    preview:
                        "Is the back of your ID card in frame and easy to read? If so, you're good to go!",
                },
            },

            selfie: {
                label: 'Photo of your selfie',
                take_a_photo: 'Take a photo of your face',
                within_frame:
                    'Please align your face within the frame <br/> and capture a photo.',
                align_within_frame: 'Align your face within the frame.',
                avoid_blocking_shadow:
                    'Avoid blocking/casting a shadow on your face',
                preview:
                    "Awesome! Now let's check your photo! <br /> Make sure that nothing is blocking your face before proceeding.",

                find_a_well_lit_area: 'Find a well-lit area and take a photo',
                avoid_light_reflection_on_your_glasses:
                    'Avoid light reflection on your glasses. <br/> Avoid wearing highly reflective glasses.',
            },
        },

        request: {
            request_items: [
                'The legitimacy of your document and the liveness of your selfie will be verified.',
            ],
            edits_detected:
                'Please note that some of the following details have been edited by the request subject before submission. The originally captured details is displayed in red.',
            cancelled: 'This request has been cancelled',
            subject_cancelled:
                'The Requester has cancelled this VaazMe Request. You are not able to proceed with fulfilling this Request.',
        },

        vaaz_credits: {
            rm_equivalent_to_credits: 'RM 1 = 10 credits',
            select_payment_method: 'Select Payment Method',
            contact_form_description:
                'Send us a message through the form below and we will get in touch',
        },

        result_page: {
            success: {
                title: {
                    thank_you_for_joining_use: 'Thank you for joining us!',
                },
                description: {
                    register:
                        'Please verify your email address and phone number to log in.',
                    verify_email:
                        'Your email has been verified successfully. Please proceed to login.',
                    contact_form:
                        'Your message has been sent! Keep an eye on your email inbox and we will get in touch within 3-5 working days.',
                    fulfilment: `Thank you for making a VaazMe submission! Rest assured that your information will only be shared with the Requester, and the data will be permanently deleted after 10 days. For more information, click <a href="https://vaazme.com.my/privacy-policy/" target="_blank">here</a> to view our Privacy Policy.`,
                    request: 'You have successfully sent a VaazMe request!',
                    vaaz_credits:
                        'You have successfully made payment. <br />Kindly check your account for the new credits.',
                },
            },
            failed: {
                title: {},
                description: {
                    verify_email:
                        'Sorry, we could not verify your account. It may have already been verified, or the verification code is incorrect.',
                    verify_shares:
                        'Sorry, the link to this report has expired and is no longer valid',
                    general_failed_message:
                        'Please contact your administrator for more information',
                },
            },
        },

        dialog: {
            title: {
                qr_code: 'Scan Here',
                email_doesnt_match: 'Email is not a match',
            },
            description: {
                update_email:
                    'Please verify your email. You will be logged out automatically.',
                update_phone_number: 'Phone Number has been updated',
                profile_update_success:
                    'Your profile has been updated successfully',
                progress_lost: 'All of your progress will be lost',
                qr_code:
                    'To continue, please scan the following QR code with your mobile device:',
                first_time_login:
                    'Thank you for registering a VaazMe account! To show our appreciation, here are 300 Credits that you can use for free!',
                share_success:
                    "Your VaazMe request report has been shared to the recipient's email address.",
                fulfilment_start_again:
                    'Are you sure you want to start again?<br /> <br />All of your progress will be lost.',
                cancel_request:
                    'Are you sure you want to cancel this Request? If you click "Proceed", you will be refunded your credits and the Subject won&#39;t be able to fulfil this Request.',
                update_preferred_language: 'Changes made have been saved',
                minimum_os_0:
                    'It looks like you&#130;re visiting us on a device with',
                minimum_os_1: 'version of',
                minimum_os_2:
                    'Please note that we only support devices with the following browser/OS versions or higher, so VaazMe may not work as smoothly on you device.',
                email_doesnt_match:
                    'Email does not match. You have been automatically logged out.',
            },
        },

        share_result: {
            description_1:
                "Share your self-verification report to a recipient's email. You will be charged 5 credits.",
            description_2:
                'Set a 10-digit password for your self-verification report. You will need to manually share this password to your recipient separately, so we suggest saving the password somewhere. Your recipient will need the password to access your results report.',
        },
    },

    // tooltips
    tooltips: {
        idv: 'Use this to verify that the Subject has legitimate identity documents (e.g. NRIC, Passport)',
    },
};
