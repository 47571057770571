import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import { useForm, Controller } from 'react-hook-form';
import { PrimaryButton } from '../../../components/Button';
import { useAppDispatch } from '../../../store/hooks';
import { useState, useEffect } from 'react';
import Spinner from '../../../components/Spinner';
import AppBarWithoutMenu from '../../../components/AppBar/AppBarWithoutMenu';
import { AppBarBody } from '../../../components/AppBar/AppBarBody';
import { FormValuesTypes } from '../../../services/types';
import { useTranslation } from 'react-i18next';
import { requestRemove } from '../../../store/slices/data/requestRemoveSlice';
import CustomizedDialogs from '../../../components/CustomizedDialogs';
import { useNavigate } from 'react-router-dom';
import { URLS } from '../../../services/urls';

function RequestRemoveDataPage() {
    const dispatch = useAppDispatch();
    const [loading, setLoading] = useState(false);
    const [backdropOpen, setBackdropOpen] = useState(false);
    const { t } = useTranslation();
    const [titleDialog, setTitleDialog] = useState('Alert');
    const [dialogErrorMsg, setDialogErrorMsg] = useState('');
    const [openDialog, setOpenDialog] = useState(false);

    const { control, handleSubmit } = useForm<FormValuesTypes>({
        mode: 'onChange',
    });
    const navigate = useNavigate();

    useEffect(() => {
        localStorage.clear();
    }, []);

    const onSubmit = (data: FormValuesTypes) => {
        setBackdropOpen(true);
        setLoading(true);
        dispatch(requestRemove(data.email)).then(res => {
            if (res.meta.requestStatus === 'fulfilled') {
                navigate(URLS.PUBLIC.MAIN, {
                    state: { successfulRequestRemove: true },
                });
            }
            if (
                res.meta.requestStatus === 'rejected' &&
                res.payload.status !== 401
            ) {
                setTitleDialog(t('text.alert'));
                setDialogErrorMsg(res.payload.message);
                setOpenDialog(true);
                setLoading(false);
            }
            setLoading(false);
        });
    };

    return (
        <>
            {openDialog && (
                <CustomizedDialogs
                    open={openDialog}
                    title={titleDialog}
                    body={dialogErrorMsg}
                    primaryButton={t('button.ok')}
                    onPrimaryButtonClick={() => setOpenDialog(false)}
                    isContainDialogAction
                    isContainTwoButtons={false}
                />
            )}

            {loading ? <Spinner open={backdropOpen} /> : <div />}

            <AppBarWithoutMenu
                title={t('app_bar_title.remove_your_data')}
                link="/"
            />
            <AppBarBody />

            <Box sx={{ p: 4, textAlign: 'center' }}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <Grid container spacing={3}>
                                <Grid
                                    item
                                    xs={12}
                                    style={{ textAlign: 'left' }}
                                >
                                    <p className="montserrat-medium-gray-17px">
                                        {t('text.confirmation_remove')}
                                    </p>
                                    <br />
                                    <br />
                                    <p className="montserrat-medium-gray-17px">
                                        {t('text.input_your_email_address')}
                                    </p>
                                </Grid>
                                <Grid item xs={12}>
                                    <Controller
                                        name="email"
                                        control={control}
                                        defaultValue=""
                                        render={({
                                            field: { onChange, value },
                                            fieldState: { error },
                                        }) => (
                                            <TextField
                                                fullWidth
                                                id="email"
                                                type="email"
                                                label="Email Address"
                                                className="montserrat-medium-outer-space-16px"
                                                value={value}
                                                onChange={onChange}
                                                error={!!error}
                                                helperText={
                                                    error ? error.message : null
                                                }
                                            />
                                        )}
                                        rules={{
                                            required: 'Email required',
                                            pattern: {
                                                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                                message: t(
                                                    'error.invalid_email',
                                                ),
                                            },
                                        }}
                                    />
                                </Grid>

                                <Grid item xs={12}>
                                    <PrimaryButton
                                        isFullWidth
                                        buttonName={t('button.continue')}
                                        disabled={false}
                                        type="submit"
                                    />
                                </Grid>
                            </Grid>
                        </form>
                    </Grid>
                </Grid>
            </Box>

            <div />
        </>
    );
}

export default RequestRemoveDataPage;
