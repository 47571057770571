import { Outlet, Navigate } from 'react-router-dom';
import { localStorageTypes } from '../../services/constant';

function useAuth() {
    return localStorage.getItem(localStorageTypes.USER_AUTH);
}

export default function NonMemberRoute() {
    const auth = useAuth();

    return auth ? <Outlet /> : <Navigate to="/" />;
}
