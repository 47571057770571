import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import GraphicType2 from '../../components/GraphicType2';
import logo from '../../assets/img/logo@2x.svg';
import {
    PrimaryButton,
    SecondaryButton,
    NegativeBorderButton,
} from '../../components/Button';
import { useLocation, useNavigate } from 'react-router-dom';
import {
    AuthTokens,
    WelcomePageProps,
    successfulRequestRemoveState,
} from '../../services/interfaces';
import { localStorageTypes } from '../../services/constant';
import { useEffect, useState } from 'react';
import { URLS } from '../../services/urls';
import { useTranslation } from 'react-i18next';
import CustomizedDialogs from '../../components/CustomizedDialogs';

function WelcomePage(props: WelcomePageProps) {
    const { welcomeToVaazme, registerNow, login, graphicType2Props } = props;
    const navigate = useNavigate();
    const { t } = useTranslation();
    // const location = useLocation<successfulRequestRemoveState>;
    // const [successfulRequestRemove, setSuccessfulRequestRemove] = useState(location.state?.successfulRequestRemove || false)

    const location = useLocation();
    const [successfulRequestRemove, setSuccessfulRequestRemove] = useState<
        boolean | successfulRequestRemoveState
    >((location.state as successfulRequestRemoveState) || false);
    const authTokens: AuthTokens = JSON.parse(
        localStorage.getItem(localStorageTypes.AUTH_TOKENS) || '{}',
    );

    useEffect(() => {
        if (authTokens.refreshToken) {
            navigate(URLS.MEMBER.HOME.MAIN);
        }
    });

    const onLoginClick = () => {
        localStorage.clear();
        navigate(URLS.PUBLIC.LOGIN.MAIN);
        window.location.reload();
    };

    const onRegisterClick = () => {
        localStorage.clear();
        navigate(URLS.PUBLIC.REGISTER.MAIN);
    };

    const onRemoveAllDataClick = () => {
        navigate(URLS.PUBLIC.REMOVE_DATA.REQUEST);
    };

    return (
        <>
            {successfulRequestRemove && (
                <CustomizedDialogs
                    open={successfulRequestRemove && true}
                    title={t('text.dialog.title.remove_your_data')}
                    body={t('text.dialog.description.remove_data_link')}
                    primaryButton={t('button.ok')}
                    onPrimaryButtonClick={() => {
                        setSuccessfulRequestRemove(false);
                        navigate(URLS.PUBLIC.MAIN);
                    }}
                    isContainDialogAction
                    isContainTwoButtons={false}
                />
            )}

            {!authTokens.refreshToken && (
                <Box sx={{ p: 4, textAlign: 'center' }}>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <img className="logo" alt="" src={logo} />
                        </Grid>
                        <Grid item xs={12}>
                            <GraphicType2 src={graphicType2Props} />
                        </Grid>
                        <Grid item xs={12}>
                            <div className="welcome-to-vaaz-me montserrat-medium-black-22px">
                                {welcomeToVaazme}
                            </div>
                        </Grid>
                        <Grid item xs={12}>
                            <PrimaryButton
                                isFullWidth
                                buttonName={registerNow}
                                disabled={false}
                                type="button"
                                callback={onRegisterClick}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <SecondaryButton
                                isFullWidth
                                buttonName={login}
                                callback={onLoginClick}
                                disabled={false}
                                type="button"
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <NegativeBorderButton
                                isFullWidth
                                buttonName={t('button.remove_your_data')}
                                callback={onRemoveAllDataClick}
                                type="submit"
                            />
                        </Grid>
                        {/* <Grid item xs={12}>
                    <Link
                        component="button"
                        variant="body2"
                        color="inherit"
                        href="/"
                    >
                        {submitFeedback}
                    </Link>
                </Grid> */}
                    </Grid>
                </Box>
            )}
            <div />
        </>
    );
}

export default WelcomePage;
