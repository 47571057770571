import { Grid } from '@mui/material';
import { useEffect, useState } from 'react';
import ActivityList from '../../../components/ActivityList';
import VaazMePagination from '../../../components/Pagination';
import { AuthTokens } from '../../../services/interfaces';
import { commonPagination } from '../../../services/constant';
import { getAuthTokenFromStorage } from '../../../services/utils';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { ReqPagination } from '../../../services/api/requestBody';
import {
    removeList,
    removeListResults,
} from '../../../store/slices/data/removeListSlice';

interface RemoveDataProps {
    isRemoveData?: boolean;
    verificationCode: string;
}

export default function RemoveDataTab(props: Readonly<RemoveDataProps>) {
    const { isRemoveData, verificationCode } = props;
    const dispatch = useAppDispatch();
    const [page, setPage] = useState(1);
    const [count, setCount] = useState(0);

    const authTokens: AuthTokens = getAuthTokenFromStorage();

    useEffect(() => {
        const params: ReqPagination = {
            verificationCode,
            page: page - 1,
            size: commonPagination.size,
            sort: commonPagination.sort,
        };
        dispatch(removeList(params));
    }, [dispatch, authTokens.userId, page, verificationCode]);

    const handlePagination = (
        event: React.ChangeEvent<unknown>,
        pageValue: number,
    ) => {
        setPage(pageValue);
        const params: ReqPagination = {
            verificationCode,
            page: pageValue - 1,
            size: commonPagination.size,
            sort: commonPagination.sort,
        };
        dispatch(removeList(params));
    };

    const dataList = useAppSelector(removeListResults);

    useEffect(() => {
        setCount(dataList.response.totalPages);
        localStorage.setItem('userEmail', dataList.response.subjectEmail);
    }, [dataList]);

    return (
        <>
            <ActivityList
                list={dataList.response.contents}
                isFulfilment
                isRemoveData={isRemoveData}
            />
            {dataList.response.contents.length > 0 ? (
                <VaazMePagination
                    count={count}
                    page={page}
                    handlePagination={handlePagination}
                />
            ) : (
                <Grid item xs={12} style={{ textAlign: 'center' }}>
                    <span className="all-set">You&rsquo;re all set!</span>
                </Grid>
            )}
        </>
    );
}
