import { combineReducers } from '@reduxjs/toolkit';
import getFulfilmentHistorySlice from './historySlice';
import submitCaptureIdSlice from './captureIdSlice';
import submitCaptureSelfieSlice from './captureSelfieSlice';
import submitBeginFulfilmentSlice from './beginSlice';
import getFulfilmentParameterSlice from './parameterSlice';
import submitFulfilmentSlice from './submitSlice';
import getFulfilmentConfirmationSlice from './confirmationSlice';
import getFulfilmentCheckStatusSlice from './checkStatusSlice';
import submitInProgressFulfilmentSlice from './inProgressSlice';
import getFulfilmentApplyWaterMarkSlice from './applyWatermarkSlice';
import getFulfilmentOkayIdCheckStatusSlice from './okayIdCheckStatus';
import generateFulfilmentQrCodeSlice from './qrCodeSlice';
import submitFulfilmentResetSlice from './resetSlice';
import generateFulfilmentRequestPdfSlice from './generateFulfilmentRequestPdfSlice';
import getFulfilmentSelfVerificationApplyWaterMarkSlice from './selfVerificationApplyWatermarkSlice';
import getSelfVerificationSubmitSlice from './submitSelfVerificationSlice';
import getSelfVerificationConfirmationSlice from './selfVerficationConfirmationSlice';

export default combineReducers({
    history: getFulfilmentHistorySlice,
    begin: submitBeginFulfilmentSlice,
    captureId: submitCaptureIdSlice,
    captureSelfie: submitCaptureSelfieSlice,
    parameters: getFulfilmentParameterSlice,
    submit: submitFulfilmentSlice,
    confirmation: getFulfilmentConfirmationSlice,
    checkStatus: getFulfilmentCheckStatusSlice,
    inProgress: submitInProgressFulfilmentSlice,
    applyWatermark: getFulfilmentApplyWaterMarkSlice,
    okayIdCheckStatus: getFulfilmentOkayIdCheckStatusSlice,
    qrCode: generateFulfilmentQrCodeSlice,
    reset: submitFulfilmentResetSlice,
    generate: generateFulfilmentRequestPdfSlice,
    selfVerificationApplyWatermark:
        getFulfilmentSelfVerificationApplyWaterMarkSlice,
    submitSelfVerification: getSelfVerificationSubmitSlice,
    selfVerificationConfirmation: getSelfVerificationConfirmationSlice,
});
