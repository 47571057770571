import { combineReducers } from '@reduxjs/toolkit';
import requestRemoveSlice from './requestRemoveSlice';
import removeSlice from './removeSlice';
import removeListSlice from './removeListSlice';

export default combineReducers({
    requestRemove: requestRemoveSlice,
    remove: removeSlice,
    list: removeListSlice,
});
