/* eslint-disable radix */
/* eslint-disable @typescript-eslint/no-use-before-define */
import { useEffect, useState } from 'react';
import { useIdleTimer } from 'react-idle-timer';
import { Outlet, Navigate, useNavigate } from 'react-router-dom';
import { AuthTokens } from '../../services/interfaces';
import { localStorageTypes } from '../../services/constant';
import { useAppDispatch } from '../../store/hooks';
import { getRefreshToken } from '../../store/slices/authentication/refreshTokenSlice';
import CustomizedDialogs from '../CustomizedDialogs';
import { setUnauthorizedHandler } from '../../services/api/apiCommonConfig';
import { useTranslation } from 'react-i18next';

function useAuth() {
    return localStorage.getItem(localStorageTypes.USER_AUTH);
}

const TIMEOUT_REFRESH = 120 * 1000;

function PrivateRoute() {
    const { t } = useTranslation();
    const auth = useAuth();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const [openDialog, setOpenDialog] = useState(false);
    const [timeout, setTimeout] = useState(TIMEOUT_REFRESH);
    const [messageDialog, setMessageDialog] = useState('');
    const [titleDialog, setTitleDialog] = useState('');

    const authTokens: AuthTokens = JSON.parse(
        localStorage.getItem(localStorageTypes.AUTH_TOKENS) || '{}',
    );

    useEffect(() => {
        setUnauthorizedHandler(() => {
            setTitleDialog(t('error.an_error_has_occurred'));
            setMessageDialog(t('error.token_expired'));
            handleOpenDialog();
            // setOpenDialog(true);
        });

        if (!authTokens.refreshToken) {
            localStorage.clear();
            navigate('/');
        }
    }, [t, authTokens.refreshToken, navigate, dispatch, authTokens.userId]);

    const handleOpenDialog = () => {
        setOpenDialog(true);
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
        localStorage.clear();
        navigate('/');
    };

    const handleOnIdle = () => {
        refreshToken();
        reset();
        start();
    };

    const refreshToken = () => {
        dispatch(getRefreshToken(authTokens.refreshToken)).then(res => {
            localStorage.removeItem(localStorageTypes.AUTH_TOKENS);
            if (res.meta.requestStatus === 'fulfilled') {
                localStorage.setItem(
                    localStorageTypes.AUTH_TOKENS,
                    JSON.stringify(res.payload),
                );

                const time = (parseInt(res.payload.expiresIn) - 60) * 1000;
                setTimeout(time);
            }
            if (
                res.meta.requestStatus === 'rejected' &&
                res.payload.status !== 401
            ) {
                setTitleDialog(t('error.an_error_has_occurred'));
                setMessageDialog(res.payload);
                handleOpenDialog();
            }
        });
    };

    const { reset, start } = useIdleTimer({
        timeout, // in milliseconds
        onIdle: handleOnIdle,
        stopOnIdle: true,
        debounce: 500,
    });

    return (
        <>
            <CustomizedDialogs
                open={openDialog}
                title={titleDialog}
                body={messageDialog}
                onPrimaryButtonClick={handleCloseDialog}
                primaryButton={t('button.close')}
                isContainDialogAction
                isContainTwoButtons={false}
            />
            {auth ? <Outlet /> : <Navigate to="/" />}
        </>
    );
}

export default PrivateRoute;
