import { Suspense, useEffect, useState } from 'react';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import WelcomePage from './containers/WelcomePage';
import LoginEmailPage from './containers/LoginPage/LoginEmailPage';
import LoginOTPPage from './containers/LoginPage/LoginOTPPage';
import graphicTypeImg from './assets/img/graphic-coverimage-1@2x.png';
import ScrollToTop from './components/ScrollToTop';
import PrivateRoute from './components/PrivateRoute';
import NonMemberRoute from './components/NonMemberRoute';
import routes from './routes/routes';
import './App.css';
import RegisterPage from './containers/RegisterUserPage';
import VerifyEmailPage from './containers/VerifyPage/VerifyEmailPage';
import ResultPage from './containers/ResultPage';
import welcomeSvg from './assets/img/frame-2@2x.svg';
import createRequestSuccessGraphic from './assets/img/frame-6@2x.svg';
import fulfilmentSubmitSuccessGraphic from './assets/img/frame@2x.svg';
import CreditSubmitSuccessGraphic from './assets/img/frame-9@2x.svg';
import SearchPageGraphic from './assets/img/frame-7@2x.svg';
import VerifyRequestsPage from './containers/VerifyPage/VerifyRequestsPage';
import nonMemberRoutes from './routes/nonMemberRoutes';
import VerifyRegisterFailedPage from './containers/ResultPage/VerifyRegisterFailedPage';
import VerifyShareRequestPage from './containers/VerifyPage/VerifyShareRequestPage';
import { SharesOTPPage } from './containers/LoginPage/SharesOTPPage';
import {
    COMMON_NON_MEMBER_PATH,
    COMMON_PRIVATE_PATH,
    URLS,
} from './services/urls';
import { RoutesProps } from './services/interfaces';
import { MOBILE_OS, WEB_OS } from './services/constant';
import CustomizedDialogs from './components/CustomizedDialogs';
import {
    isAndroid,
    isChrome,
    isDesktop,
    isEdge,
    isFirefox,
    isMobile,
    isMobileSafari,
    isOpera,
    isSafari,
    isSamsungBrowser,
    parseUserAgent,
} from 'react-device-detect';
import './i18n/i18n';
import SearchPage from './containers/CertificatePage/SearchPage';
import DetailPage from './containers/CertificatePage/DisplayInfoPage';
import { useTranslation } from 'react-i18next';
import ShareResultPage from './containers/ProfilePage/ShareResultPage';
import RemoveDataPage from './containers/RemoveDataPage';
import SuccessRemoveDataPage from './containers/RemoveDataPage/RemovedDataPage';
import RequestRemoveDataPage from './containers/RemoveDataPage/RequestRemoveDataPage';

function App() {
    const { t } = useTranslation();
    const [os, setOS] = useState('');
    const [bad, setBad] = useState({
        os: '',
        version: '',
        expected: '',
        open: false,
    });

    useEffect(() => {
        const check: boolean = JSON.parse(
            localStorage.getItem('osChecked') || 'false',
        );
        if (check) return;
        const { userAgent } = navigator;

        if (isMobile) {
            if (isMobileSafari) setOS('Safari');
            else if (isChrome) setOS('Chrome');
            else if (isFirefox) setOS('Firefox');
            else if (isOpera) setOS('Opera');
            else if (isSamsungBrowser) setOS('Samsung');
            else if (isAndroid) setOS('WebView');

            const find = MOBILE_OS.find(o => o.Name === os);
            const parsed = parseUserAgent(userAgent);
            const version = parseInt(parsed.browser.version, 10);
            if (find) {
                if (version < (find?.Expected || 0)) {
                    setBad({
                        os,
                        version: version.toString(),
                        expected: find?.Expected.toString() || '0',
                        open: true,
                    });
                }
                localStorage.setItem('osChecked', 'true');
            }
        } else if (isDesktop) {
            if (isSafari) setOS('Safari');
            else if (isChrome) setOS('Chrome');
            else if (isFirefox) setOS('Firefox');
            else if (isOpera) setOS('Opera');
            else if (isEdge) setOS('Edge');

            const find = WEB_OS.find(o => o.Name === os);
            const parsed = parseUserAgent(userAgent);
            const version = parseInt(parsed.browser.version, 10);
            if (find) {
                if (version < (find?.Expected || 0)) {
                    setBad({
                        os,
                        version: version.toString(),
                        expected: find?.Expected.toString() || '0',
                        open: true,
                    });
                }
                localStorage.setItem('osChecked', 'true');
            }
        }
    }, [os]);

    const close = () => {
        setBad({
            os: '',
            version: '',
            expected: '',
            open: false,
        });
        localStorage.setItem('osChecked', 'true');
    };

    return (
        <div className="app-screen app-test">
            <BrowserRouter basename={process.env.REACT_APP_ROUTER_BASE || ''}>
                <ScrollToTop />
                <CustomizedDialogs
                    open={bad.open}
                    title={t('text.alert')}
                    isContainTwoButtons={false}
                    isContainDialogAction
                    primaryButton={t('button.ok')}
                    onPrimaryButtonClick={() => close()}
                    body={`${t('text.dialog.description.minimum_os_0')} ${
                        bad.os
                    } ${t('text.dialog.description.minimum_os_1')} ${
                        bad.version
                    }. ${t(
                        'text.dialog.description.minimum_os_2',
                    )} <br/><br/> ${bad.os}:${bad.expected}`}
                />
                <Routes>
                    <Route
                        path={URLS.PUBLIC.MAIN}
                        element={
                            <WelcomePage
                                exact
                                welcomeToVaazme={t('text.welcome_vaazme')}
                                registerNow={t('app_bar_title.register_now')}
                                login={t('button.login')}
                                removeAllData={t('button.remove_your_data')}
                                submitFeedback={t('text.submit_feedback')}
                                graphicType2Props={graphicTypeImg}
                            />
                        }
                    />
                    <Route
                        path={URLS.PUBLIC.LOGIN.MAIN}
                        element={<LoginEmailPage />}
                    />
                    <Route
                        path={URLS.PUBLIC.LOGIN.OTP}
                        element={<LoginOTPPage />}
                    />
                    <Route
                        path={`${URLS.PUBLIC.REMOVE_DATA.MAIN}/:verificationCode/`}
                        element={<RemoveDataPage />}
                    />
                    <Route
                        path={URLS.PUBLIC.REMOVE_DATA.REMOVED}
                        element={<SuccessRemoveDataPage />}
                    />
                    <Route
                        path={URLS.PUBLIC.REMOVE_DATA.REQUEST}
                        element={<RequestRemoveDataPage />}
                    />
                    <Route
                        path={URLS.PUBLIC.REGISTER.MAIN}
                        element={<RegisterPage />}
                    />
                    <Route
                        path={URLS.PUBLIC.REGISTER.SUCCESS}
                        element={
                            <ResultPage
                                title={t(
                                    'text.result_page.success.title.thank_you_for_joining_use',
                                )}
                                description={t(
                                    'text.result_page.success.description.register',
                                )}
                                button={t('button.login_now')}
                                link={URLS.PUBLIC.LOGIN.MAIN}
                                imgUrl={welcomeSvg}
                                isIncludeAppBar={false}
                                appBarTitle=""
                            />
                        }
                    />

                    <Route path={`${URLS.PUBLIC.VERIFY.VERIFY_EMAIL}/:code/`}>
                        <Route path=":id" element={<VerifyEmailPage />} />
                        <Route path="" element={<VerifyEmailPage />} />
                    </Route>

                    <Route
                        path={URLS.PUBLIC.VERIFY.VERIFY_EMAIL_SUCCESS}
                        element={
                            <ResultPage
                                title={t(
                                    'text.result_page.success.title.thank_you_for_joining_use',
                                )}
                                description={t(
                                    'text.result_page.success.description.verify_email',
                                )}
                                button={t('button.login_now')}
                                link={URLS.PUBLIC.LOGIN.MAIN}
                                imgUrl={welcomeSvg}
                                isIncludeAppBar={false}
                                appBarTitle=""
                            />
                        }
                    />
                    <Route
                        path={URLS.PUBLIC.VERIFY.VERIFY_EMAIL_FAILED}
                        element={
                            <VerifyRegisterFailedPage
                                title=""
                                description={t(
                                    'text.result_page.failed.description.verify_email',
                                )}
                                button={t('button.ok')}
                                link={URLS.PUBLIC.MAIN}
                                imgUrl={graphicTypeImg}
                                isIncludeAppBar={false}
                                appBarTitle=""
                            />
                        }
                    />

                    <Route
                        path={`${URLS.PUBLIC.VERIFY.REQUEST_VERIFY}/:verificationId/:verificationCode/:member`}
                        element={<VerifyRequestsPage />}
                    />
                    <Route
                        path={URLS.PUBLIC.VERIFY.REQUEST_VERIFY_FAILED}
                        element={
                            <ResultPage
                                title=""
                                description={t(
                                    'text.result_page.failed.description.general_failed_message',
                                )}
                                button={t('button.ok')}
                                link={URLS.PUBLIC.MAIN}
                                imgUrl={graphicTypeImg}
                                isIncludeAppBar={false}
                                appBarTitle=""
                            />
                        }
                    />

                    <Route
                        path={`${URLS.PUBLIC.SHARE.RESULT}/:verificationCode/:email`}
                        element={<VerifyShareRequestPage />}
                    />
                    <Route
                        path={URLS.PUBLIC.SHARE.VALIDATE_OTP}
                        element={<SharesOTPPage />}
                    />
                    <Route
                        path={URLS.PUBLIC.SHARE.VERIFY_FAILED}
                        element={
                            <ResultPage
                                title=""
                                description={t(
                                    'text.result_page.failed.description.verify_shares',
                                )}
                                button={t('button.ok')}
                                link={URLS.PUBLIC.MAIN}
                                imgUrl={graphicTypeImg}
                                isIncludeAppBar={false}
                                appBarTitle=""
                            />
                        }
                    />
                    <Route
                        path={URLS.PUBLIC.CERT.MAIN}
                        element={
                            <SearchPage
                                title={t('text.data_auth')}
                                description={t(
                                    'text.data_authentication.search.description',
                                )}
                                graphic={SearchPageGraphic}
                            />
                        }
                    />

                    <Route
                        path={URLS.PUBLIC.CERT.DETAIL}
                        element={
                            <DetailPage
                                title={t('text.data_auth')}
                                descriptionOne={t(
                                    'text.data_authentication.detail.description_1',
                                )}
                                descriptionTwo={t(
                                    'text.data_authentication.detail.description_2',
                                )}
                            />
                        }
                    />

                    {/* Non Member routes */}
                    <Route
                        path={COMMON_NON_MEMBER_PATH}
                        element={<NonMemberRoute />}
                    >
                        {nonMemberRoutes.map((route: RoutesProps) => {
                            return (
                                <Route
                                    key={route.path}
                                    path={route.path}
                                    element={
                                        <Suspense
                                            fallback={
                                                <Backdrop
                                                    sx={{
                                                        color: '#fff',
                                                        zIndex: theme =>
                                                            theme.zIndex
                                                                .drawer + 1,
                                                    }}
                                                    open
                                                >
                                                    <CircularProgress color="inherit" />
                                                </Backdrop>
                                            }
                                        >
                                            <route.element />
                                        </Suspense>
                                    }
                                />
                            );
                        })}
                        <Route
                            path={URLS.NON_MEMBER.FULFILMENT.RESULT}
                            element={
                                <ResultPage
                                    title={`${t('text.success')}!`}
                                    appBarTitle={t(
                                        'app_bar_title.submit_vaazme_fulfilment',
                                    )}
                                    description={t(
                                        'text.result_page.success.description.fulfilment',
                                    )}
                                    button={t('button.back_to_home')}
                                    link={URLS.PUBLIC.MAIN}
                                    imgUrl={fulfilmentSubmitSuccessGraphic}
                                    isIncludeAppBar
                                />
                            }
                        />
                    </Route>

                    {/* Member Routes */}
                    <Route
                        path={COMMON_PRIVATE_PATH}
                        element={<PrivateRoute />}
                    >
                        {routes.map(route => {
                            return (
                                <Route
                                    key={route.path}
                                    path={route.path}
                                    element={
                                        <Suspense
                                            fallback={
                                                <Backdrop
                                                    sx={{
                                                        color: '#fff',
                                                        zIndex: theme =>
                                                            theme.zIndex
                                                                .drawer + 1,
                                                    }}
                                                    open
                                                >
                                                    <CircularProgress color="inherit" />
                                                </Backdrop>
                                            }
                                        >
                                            <route.element />
                                        </Suspense>
                                    }
                                />
                            );
                        })}

                        <Route
                            path={URLS.MEMBER.FAILED}
                            element={
                                <ResultPage
                                    title=""
                                    description={t(
                                        'text.result_page.failed.description.general_failed_message',
                                    )}
                                    button={t('button.back_to_home')}
                                    link={URLS.MEMBER.HOME.MAIN}
                                    imgUrl={graphicTypeImg}
                                    isIncludeAppBar={false}
                                    appBarTitle=""
                                />
                            }
                        />

                        <Route
                            path={
                                URLS.MEMBER.REQUEST.CREATE_NEW_REQUEST_COMPLETE
                            }
                            element={
                                <ResultPage
                                    title={`${t('text.success')}!`}
                                    description={t(
                                        'text.result_page.success.description.request',
                                    )}
                                    button={t('button.back_to_home')}
                                    link={URLS.MEMBER.HOME.MAIN}
                                    imgUrl={createRequestSuccessGraphic}
                                    isIncludeAppBar={false}
                                    appBarTitle=""
                                />
                            }
                        />
                        <Route
                            path={URLS.MEMBER.FULFILMENT.RESULT}
                            element={
                                <ResultPage
                                    title={`${t('text.success')}!`}
                                    appBarTitle={t(
                                        'app_bar_title.submit_vaazme_fulfilment',
                                    )}
                                    description={t(
                                        'text.result_page.success.description.fulfilment',
                                    )}
                                    button={t('button.back_to_home')}
                                    link={URLS.MEMBER.HOME.MAIN}
                                    imgUrl={fulfilmentSubmitSuccessGraphic}
                                    isIncludeAppBar
                                />
                            }
                        />
                        {/* <Route
                            path={
                                pathname.pathname_vaazme_home_vaazcredits_result
                            }
                            element={
                                <ResultPage
                                    title={`${properties.text_success}!`}
                                    appBarTitle={
                                        properties.app_bar_title_top_up_vaaz_credits
                                    }
                                    description={
                                        properties.text_you_have_successfully_make_the_payment
                                    }
                                    button={`${properties.button_name_done}!`}
                                    link={
                                        pathname.pathname_vaazme_home_vaazcredits
                                    }
                                    imgUrl={CreditSubmitSuccessGraphic}
                                    isIncludeAppBar
                                />
                            }
                        /> */}
                        <Route
                            path={URLS.MEMBER.VAAZCREDITS.RESULT}
                            element={
                                <ResultPage
                                    title={`${t('text.success')}!`}
                                    appBarTitle={t(
                                        'app_bar_title.get_more_credits',
                                    )}
                                    description={t(
                                        'text.result_page.success.description.contact_form',
                                    )}
                                    button={`${t('button.done')}!`}
                                    link={URLS.MEMBER.VAAZCREDITS.MAIN}
                                    imgUrl={CreditSubmitSuccessGraphic}
                                    isIncludeAppBar
                                />
                            }
                        />
                        <Route
                            path={
                                URLS.MEMBER.FULFILMENT.SELF_VERIFICATION.CONTACT
                            }
                            element={
                                <ResultPage
                                    title=""
                                    appBarTitle={t(
                                        'app_bar_title.self_verification',
                                    )}
                                    description={t(
                                        'text.result_page.success.description.contact_self_verification',
                                    )}
                                    button={t('button.back_to_home')}
                                    link={URLS.MEMBER.HOME.MAIN}
                                    imgUrl={fulfilmentSubmitSuccessGraphic}
                                    isIncludeAppBar
                                />
                            }
                        />
                        <Route
                            path={
                                URLS.MEMBER.FULFILMENT.SELF_VERIFICATION.RESULT
                            }
                            element={
                                <ResultPage
                                    title=""
                                    appBarTitle={t(
                                        'app_bar_title.self_verification',
                                    )}
                                    description={t(
                                        'text.result_page.success.description.success_self_verification',
                                    )}
                                    button={t('button.back_to_home')}
                                    link={URLS.MEMBER.HOME.MAIN}
                                    imgUrl={fulfilmentSubmitSuccessGraphic}
                                    isIncludeAppBar
                                />
                            }
                        />
                        <Route
                            path={URLS.MEMBER.SHARE.RESULTS}
                            element={<ShareResultPage />}
                        />
                    </Route>
                    <Route path="*" element={<Navigate to="" />} />
                </Routes>
            </BrowserRouter>
        </div>
    );
}

export default App;
