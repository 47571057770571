import { Grid, Pagination } from '@mui/material';
import { VaazMePaginationProps } from '../../services/interfaces';

export default function VaazMePagination(props: VaazMePaginationProps) {
    const { count, page, handlePagination } = props;

    return (
        <Grid
            item
            xs={12}
            sx={{
                py: 2,
                display: 'flex',
                justifyContent: 'center',
            }}
        >
            <Pagination
                count={count}
                variant="outlined"
                color="primary"
                page={page}
                onChange={handlePagination}
            />
        </Grid>
    );
}
