import { combineReducers } from '@reduxjs/toolkit';
import profileSlice from './profileSlice';
import updateProfileSlice from './updateProfileSlice';
import verifyProfileUserSlice from './verifyProfileUserSlice';
import parameterSlice from './parameterSlice';
import updatePreferredLanguageSlice from './updatePreferredLanguageSlice';
import shareResultSlice from './shareResultSlice';

export default combineReducers({
    profile: profileSlice,
    updateProfile: updateProfileSlice,
    verify: verifyProfileUserSlice,
    parameter: parameterSlice,
    language: updatePreferredLanguageSlice,
    shareResult: shareResultSlice,
});
