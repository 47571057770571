/* eslint-disable react/no-danger */
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { DialogProps } from '../../services/interfaces';
import { NegativeButton, PrimaryButton } from '../Button';
import './index.css';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));

export default function CustomizedDialogs(props: DialogProps) {
    const {
        open,
        title,
        body,
        children,

        // button properties
        onPrimaryButtonClick,
        onSecondaryButtonClick,
        buttonType,
        primaryButton,
        secondaryButton,
        isContainTwoButtons,
        isContainDialogAction,
        customButtonStyle,
        withoutButton,
    } = props;

    return (
        <div>
            <BootstrapDialog open={open} className="dialog customized-dialog">
                <DialogTitle sx={{ m: 0, p: 2 }} className="dialog">
                    {title}
                </DialogTitle>
                <DialogContent
                    className="montserrat-medium-tuatara-14px"
                    style={{
                        padding: withoutButton ? '32px' : '8px',
                        width: '100%',
                    }}
                >
                    {body ? (
                        <p dangerouslySetInnerHTML={{ __html: body }} />
                    ) : (
                        children
                    )}
                </DialogContent>

                {isContainDialogAction && isContainTwoButtons && (
                    <DialogActions sx={{ width: '100%' }}>
                        <PrimaryButton
                            isFullWidth
                            buttonName={secondaryButton as string}
                            callback={onSecondaryButtonClick}
                            disabled={false}
                            type="button"
                            customStyle={customButtonStyle || ''}
                        />
                        <NegativeButton
                            isFullWidth
                            buttonName={primaryButton as string}
                            callback={onPrimaryButtonClick}
                            disabled={false}
                            type="button"
                            customStyle={customButtonStyle || ''}
                        />
                    </DialogActions>
                )}

                {isContainDialogAction && !isContainTwoButtons && (
                    <DialogActions sx={{ width: '100%' }}>
                        <PrimaryButton
                            isFullWidth
                            buttonName={primaryButton as string}
                            callback={onPrimaryButtonClick}
                            disabled={false}
                            type={buttonType || 'button'}
                            customStyle={customButtonStyle || ''}
                        />
                    </DialogActions>
                )}
            </BootstrapDialog>
        </div>
    );
}
