import i18next, { i18n as i18nInstance } from 'i18next';
import { initReactI18next } from 'react-i18next';
import { languages } from './i18n.constants';
import enTranslation from './locales/en/properties.json';
import zhTranslation from './locales/zh/properties.json';
import msTranslation from './locales/ms/properties.json';
import LanguageDetector from 'i18next-browser-languagedetector';

const resources = {
    en: { translation: enTranslation },
    zh: { translation: zhTranslation },
    ms: { translation: msTranslation },
};

const createI18n = (): i18nInstance => {
    const i18n = i18next
        .createInstance()
        .use(initReactI18next)
        .use(LanguageDetector);

    i18n.init({
        resources,
        lng: localStorage.getItem('i18nextLng') || languages.en,
        fallbackLng: languages.en,
        interpolation: {
            escapeValue: false,
        },
    });

    return i18n;
};

export const i18n = createI18n();
