/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { axiosPrivate } from '../../../services/api/apiCommonConfig';
import {
    IRequest,
    ISRequest,
    VaazException,
} from '../../../services/api/initialStates';
import { commonStatusTypes } from '../../../services/constant';
import { API } from '../../api.v1';
import { RootState } from '../../store';

export const getResultsRequests = createAsyncThunk(
    '/requests/results/get',
    async (requestId: string, { rejectWithValue }) => {
        try {
            const { data } = await axiosPrivate.get(
                `${API.REQUEST.RESULT}${requestId}`,
            );

            return data;
        } catch (error: unknown) {
            const err = error as VaazException;
            return rejectWithValue(err.response.data);
        }
    },
);

const getRequestResultsSlice = createSlice({
    name: '/requests/results/get',
    initialState: {
        response: ISRequest.result,
        loading: false,
        errorMessage: '',
        status: commonStatusTypes.IDLE,
    },
    reducers: {},
    extraReducers: builder => {
        builder
            .addCase(getResultsRequests.pending, state => {
                state.loading = true;
                state.status = commonStatusTypes.IDLE;
            })
            .addCase(getResultsRequests.rejected, (state, action) => {
                state.loading = false;
                state.status = commonStatusTypes.FAILED;

                const { payload } = action;
                const errMsg = payload as VaazException['response']['data'];
                const error = errMsg || action.error;
                state.errorMessage = error.message || '';
            })
            .addCase(getResultsRequests.fulfilled, (state, action) => {
                state.loading = false;
                state.status = commonStatusTypes.SUCCESS;
                const data: IRequest['result'] = action.payload;
                if (data.address) {
                    const search = '^';
                    const replaceWith = '\n';
                    data.address = data.address.split(search).join(replaceWith); // .replace(/^/g, '\n');
                    if (data.addressEdit) {
                        data.addressEdit = data.addressEdit
                            .split(search)
                            .join(replaceWith); // .replace(/^/g, '\n');
                    }
                }
                state.response = data;
            });
    },
});

export const getRequestResults = (state: RootState) => state.requests.results;

export default getRequestResultsSlice.reducer;
