import {
    Grid,
    Table,
    TableBody,
    TableCell,
    TableRow,
    Typography,
} from '@mui/material';
import {
    CardImageCertBox,
    CardTextBox,
    CertAuthCardBox,
    WebScreenCardImageCertBox,
} from '../../../../components/CardMenu';
import { properties } from '../../../../services/properties';
import { DataAuthenticationProps } from '../../../../services/interfaces';
import {
    BASE64_COMMON_STRING,
    MASTER_MEMBER_CODE,
} from '../../../../services/constant';
import { checkDateIsValid } from '../../../../services/utils';
import { useTranslation } from 'react-i18next';

export function Table1(props: DataAuthenticationProps) {
    const { t } = useTranslation();
    const {
        address,
        addressEdit,
        birthDate,
        birthDateEdit,
        documentNumber,
        documentNumberEdit,
        gender,
        genderEdit,
        idType,
        imgIcBack,
        imgIcFront,
        imgSelfie,
        issuingStateName,
        issuingStateNameEdit,
        name,
        nameEdit,
        additionalDetails,
        scorecardRatingLabel,
        scorecardRating,
        updatedDate,
        updatedTime,
        ipAddress,
        requesterName,
    } = props;

    return (
        <Grid container spacing={2} sx={{ textAlign: 'center' }}>
            <Grid item xs={12}>
                <CardTextBox
                    text={`${properties.text.vaazme_score_card_rating}: ${scorecardRatingLabel}`}
                    textStyle="montserrat-medium-white-14px"
                    boxStyle={
                        scorecardRating === 'suspicious' ||
                        scorecardRating === 'cautious'
                            ? 'card-box-text'
                            : 'card-box-text-clear'
                    }
                    isScorecard={
                        !!(additionalDetails && additionalDetails.length > 0)
                    }
                    scorecardDetails={additionalDetails}
                />
            </Grid>
            <Grid item xs={12}>
                {idType === MASTER_MEMBER_CODE.ID_PASSPORT ? (
                    <CardImageCertBox
                        image1={
                            imgIcFront ? BASE64_COMMON_STRING + imgIcFront : ''
                        }
                        imageDesc1={t('text.fulfilment.passport.cert_label')}
                        imageIsCheck1
                        imageIsCheck2
                        image3={
                            imgSelfie ? BASE64_COMMON_STRING + imgSelfie : ''
                        }
                        imageDesc3={t('text.fulfilment.selfie.cert_label')}
                        imageIsCheck3
                        withBox
                    />
                ) : (
                    <CardImageCertBox
                        image1={
                            imgIcFront ? BASE64_COMMON_STRING + imgIcFront : ''
                        }
                        imageDesc1={t('text.fulfilment.id.front.cert_label')}
                        imageIsCheck1
                        image2={
                            imgIcBack ? BASE64_COMMON_STRING + imgIcBack : ''
                        }
                        imageDesc2={t('text.fulfilment.id.back.cert_label')}
                        imageIsCheck2
                        image3={
                            imgSelfie ? BASE64_COMMON_STRING + imgSelfie : ''
                        }
                        imageDesc3={t('text.fulfilment.selfie.cert_label')}
                        imageIsCheck3
                        withBox
                    />
                )}
            </Grid>
            <Grid item xs={12}>
                <CertAuthCardBox
                    idType={idType}
                    title={properties.text.document_details}
                    documentNumber={documentNumber}
                    name={name}
                    gender={gender}
                    birthDate={birthDate}
                    issuingState={issuingStateName}
                    address={address || ''}
                    updatedTime={updatedTime}
                    updatedDate={updatedDate}
                    ipAddress={ipAddress}
                    requesterName={requesterName}
                    documentNumberEdit={documentNumberEdit}
                    nameEdit={nameEdit}
                    genderEdit={genderEdit}
                    birthDateEdit={birthDateEdit}
                    issuingStateNameEdit={issuingStateNameEdit}
                    addressEdit={addressEdit}
                />
            </Grid>
        </Grid>
    );
}

export function Table2(props: DataAuthenticationProps) {
    const {
        address,
        addressEdit,
        birthDate,
        birthDateEdit,
        documentNumber,
        documentNumberEdit,
        gender,
        genderEdit,
        idType,
        imgIcBack,
        imgIcFront,
        imgSelfie,
        issuingStateName,
        issuingStateNameEdit,
        name,
        nameEdit,
        additionalDetails,
        scorecardRatingLabel,
        scorecardRating,
        updatedDate,
        updatedTime,
        ipAddress,
        requesterName,
    } = props;

    const { t } = useTranslation();

    return (
        <Grid container spacing={3}>
            <Grid item xs={5}>
                {idType === MASTER_MEMBER_CODE.ID_PASSPORT ? (
                    <WebScreenCardImageCertBox
                        image1={
                            imgIcFront ? BASE64_COMMON_STRING + imgIcFront : ''
                        }
                        imageIsCheck1
                        image2={
                            imgIcBack ? BASE64_COMMON_STRING + imgIcBack : ''
                        }
                        imageIsCheck2
                        image3={
                            imgSelfie ? BASE64_COMMON_STRING + imgSelfie : ''
                        }
                        imageIsCheck3
                    />
                ) : (
                    <WebScreenCardImageCertBox
                        image1={
                            imgIcFront ? BASE64_COMMON_STRING + imgIcFront : ''
                        }
                        imageIsCheck1
                        image2={
                            imgIcBack ? BASE64_COMMON_STRING + imgIcBack : ''
                        }
                        imageIsCheck2
                        image3={
                            imgSelfie ? BASE64_COMMON_STRING + imgSelfie : ''
                        }
                        imageIsCheck3
                    />
                )}
            </Grid>
            <Grid item xs={7}>
                <CardTextBox
                    text={`${properties.text.vaazme_score_card_rating}: ${scorecardRatingLabel}`}
                    textStyle="montserrat-medium-white-14px"
                    boxStyle={
                        scorecardRating === 'suspicious' ||
                        scorecardRating === 'cautious'
                            ? 'card-box-text'
                            : 'card-box-text-clear'
                    }
                    isScorecard={
                        !!(additionalDetails && additionalDetails.length > 0)
                    }
                    scorecardDetails={additionalDetails}
                />
                <Table>
                    <TableBody>
                        <TableRow>
                            <TableCell>
                                <p>Document Number</p>
                            </TableCell>
                            <TableCell>
                                {documentNumberEdit || documentNumber}
                                {documentNumberEdit && (
                                    <p className="montserrat-medium-sunset-orange-12px">
                                        **{t('label.captured')}:{' '}
                                        {documentNumber}
                                    </p>
                                )}
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>
                                <p>Name</p>
                            </TableCell>
                            <TableCell>
                                {nameEdit || name}
                                {nameEdit && (
                                    <p className="montserrat-medium-sunset-orange-12px">
                                        **{t('label.captured')}: {name}
                                    </p>
                                )}
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>
                                <p>Gender</p>
                            </TableCell>
                            <TableCell>
                                {genderEdit || gender}
                                {genderEdit && (
                                    <p className="montserrat-medium-sunset-orange-12px">
                                        **{t('label.captured')}: {gender}
                                    </p>
                                )}
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>
                                <p>Date Of Birth</p>
                            </TableCell>
                            <TableCell>
                                {checkDateIsValid(birthDateEdit || birthDate)}
                                {birthDateEdit && (
                                    <p className="montserrat-medium-sunset-orange-12px">
                                        **{t('label.captured')}:{' '}
                                        {checkDateIsValid(birthDate)}
                                    </p>
                                )}
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>
                                <p>Issuing State Name</p>
                            </TableCell>
                            <TableCell>
                                {issuingStateNameEdit || issuingStateName}
                                {issuingStateNameEdit && (
                                    <p className="montserrat-medium-sunset-orange-12px">
                                        **{t('label.captured')}:{' '}
                                        {issuingStateName}
                                    </p>
                                )}
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>
                                <p>Address</p>
                            </TableCell>
                            <TableCell>
                                <Typography
                                    variant="body1"
                                    style={{ whiteSpace: 'pre-line' }}
                                >
                                    {addressEdit || address}
                                </Typography>
                                {addressEdit && (
                                    <p className="montserrat-medium-sunset-orange-12px">
                                        **{t('label.captured')}: {address}
                                    </p>
                                )}
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>
                                <p>Updated Date</p>
                            </TableCell>
                            <TableCell>
                                {checkDateIsValid(updatedDate)}
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>
                                <p>Updated Time</p>
                            </TableCell>
                            <TableCell>{updatedTime}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>
                                <p>IP Address</p>
                            </TableCell>
                            <TableCell>{ipAddress}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>
                                <p>Requested By</p>
                            </TableCell>
                            <TableCell>{requesterName}</TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </Grid>
        </Grid>
    );
}
