export const API = {
    DASHBOARD: '/dashboards/',

    AUTHENTICATION: {
        LOGIN: '/oauth/token',
        LOGOUT: '/oauth/token/invalidate',
        OTP: '/oauth/token/otp',
        REFRESH_TOKEN: '/oauth/token/refresh/',
        VERIFICATION: '/oauth/token/verification',
    },

    CONTACTS: {
        SUBMIT: '/contacts/submit',
        SELF_VERIFICATION_SUBMIT: '/contacts/self-verification/submit',
    },

    VAAZ_CREDIT: {
        CREDITS: '/credits/',
        PARAMETERS: '/credits/parameters',
        SEARCH: '/credits/search/',
        TOPUP: '/credits/topup',
        GET_TOPUP_OFFLINE_PARAMETER: '/credits/topup/parameters',
        TOPUP_OFFLINE: '/credits/topup/offline',
    },

    FULFILMENT: {
        APPLY_WATERMARK: '/fulfilments/apply-watermark',
        BEGIN: '/fulfilments/begin',
        CAPTURE_ID: '/fulfilments/capture-id',
        CAPTURE_SELFIE: '/fulfilments/capture-selfie',
        CHECK_STATUS: '/fulfilments/check-status',
        CONFIRMATION: '/fulfilments/confirmation',
        HISTORY: '/fulfilments/history/',
        IN_PROGRESS: '/fulfilments/in-progress',
        OKAY_ID_CHECK_STATUS: '/fulfilments/okayid/check-status',
        PARAMETERS: '/fulfilments/parameters',
        QR_CODE: '/fulfilments/qr-code',
        FULFILMENTS: '/fulfilments',
        RESET: '/fulfilments/reset/',
        GENERATE: '/fulfilments/generate/',
        SELF_VERIFICATION_APPLY_WATERMARK:
            '/fulfilments/self-verification/apply-watermark',
        SUBMIT_SELF_VERIFICATION: '/fulfilments/self-verification/submit',
        CONFIRMATION_SELF_VERIFICATION:
            '/fulfilments/confirmation/self-verification',
    },

    MESSAGES: {
        MESSAGES: '/messages/',
        UPDATE_STATUS: '/messages/update-status',
    },

    PROFILE: {
        PROFILES: '/profiles/',
        PARAMETERS: '/profiles/parameters',
        UPDATE_PROFILES: '/profiles',
        VERIFY: '/profiles/verify',
        UPDATE_PREFERRED_LANGUAGE: '/profiles/language',
        SHARE_RESULT: '/profiles/share-result',
    },

    REGISTER: {
        PARAMETERS: '/public/registration/parameters',
        REGISTRATION: '/public/registration',
        RESEND_VERIFICATION_EMAIL:
            '/public/registration/resend/verification-email',
        VERIFY: '/public/registration/verify',
    },

    REQUEST: {
        REQUESTS: '/requests',
        DETAIL: '/requests/detail/',
        HISTORY: '/requests/history/',
        ORDER_SUMMARY: '/requests/order-summary',
        PARAMETERS: '/requests/parameters',
        RESULT: '/requests/result/',
        FULFILMENT: '/requests/fulfilment',
        CANCEL: '/requests/cancel',
        VERIFICATION: '/requests/member-verification',
        GENERATE: '/requests/generate/',
        SELF_VERIFICATION: '/requests/self-verification',
        SELF_VERIFICATION_RESULT: '/requests/self-verification/result/',
        SELF_VERIFICATION_GENERATE: '/requests/generate/self-verification/',
    },

    PROMOCODE: {
        PROMO_CODE: '/promo-code/apply',
        SPONSOR: '/promo-code/',
    },

    SHARES: {
        RESULT: '/shares/result/',
        SHARES: '/shares',
        VALIDATE_LINK: '/shares/validate-link',
        VALIDATE_OTP: '/shares/validate-otp',
    },
    CERTIFICATE: {
        VERIFY: '/cert/verify/',
    },
    DATA: {
        REQUEST_REMOVE: '/data/request-remove',
        REMOVE: '/data/remove',
        REMOVE_LIST: '/data/remove/list',
    },
};
