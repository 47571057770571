/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { axiosPrivate } from '../../../services/api/apiCommonConfig';
import { VaazException } from '../../../services/api/initialStates';
import { ReqPagination } from '../../../services/api/requestBody';
import { commonStatusTypes } from '../../../services/constant';
import { API } from '../../api.v1';
import { RootState } from '../../store';

export const removeList = createAsyncThunk(
    '/data/remove/list',
    async (param: ReqPagination, { rejectWithValue }) => {
        try {
            const { data } = await axiosPrivate.post(
                `${API.DATA.REMOVE_LIST}/${param.verificationCode}?page=${param.page}&size=${param.size}&sort=${param.sort}`,
            );
            return data;
        } catch (error: unknown) {
            const err = error as VaazException;
            return rejectWithValue(err.response.data);
        }
    },
);

const removeListSlice = createSlice({
    name: '/data/remove/list',
    initialState: {
        response: {
            totalPages: 0,
            totalElements: 0,
            contents: [],
            subjectEmail: '',
        },
        loading: false,
        errorMessage: '',
        status: commonStatusTypes.IDLE,
    },
    reducers: {},
    extraReducers: builder => {
        builder
            .addCase(removeList.pending, state => {
                state.loading = true;
                state.errorMessage = '';
                state.status = commonStatusTypes.IDLE;
            })
            .addCase(removeList.rejected, (state, action) => {
                state.loading = false;
                state.status = commonStatusTypes.FAILED;

                const { payload } = action;
                const errMsg = payload as VaazException['response']['data'];
                const error = errMsg || action.error;
                state.errorMessage = error.message || '';
            })
            .addCase(removeList.fulfilled, (state, action) => {
                state.loading = false;
                state.status = commonStatusTypes.SUCCESS;
                state.errorMessage = '';
                state.response = action.payload;
            });
    },
});

export const removeListResults = (state: RootState) => state.data.list;

export default removeListSlice.reducer;
