import React from 'react';
import { SponsoredProps } from '../../services/interfaces';
import { Stack } from '@mui/material';

export default function SponsoredMessage(prop: SponsoredProps) {
    const { sponsorMsg, sponsorImg } = prop;

    const sponsoredImg = `data:image/png;base64,${sponsorImg}`;

    return (
        <Stack flexDirection="row" justifyContent="center" alignItems="center">
            <p
                style={{
                    maxWidth: '50%',
                    wordWrap: 'break-word',
                }}
                className="montserrat-medium-tuatara-14px"
            >
                {sponsorMsg}
            </p>
            {sponsorImg !== '' ? (
                <img
                    src={sponsoredImg}
                    style={{
                        maxHeight: '150px',
                        maxWidth: '150px',
                        marginLeft: 'auto',
                    }}
                    alt=""
                />
            ) : (
                ''
            )}
        </Stack>
    );
}
