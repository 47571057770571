import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import logo from '../../../assets/img/logo@2x.svg';
import { SearchPageProps } from '../../../services/interfaces';
import GraphicType2 from '../../../components/GraphicType2';
import { CertPrimaryButton } from '../../../components/Button';
import { properties } from '../../../services/properties';
import { useForm } from 'react-hook-form';
import TextField from '@mui/material/TextField';
import { FormValuesTypes } from '../../../services/types';
import { FormHelperText } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { URLS } from '../../../services/urls';
import { verifyCertificateNum } from '../../../store/slices/certificate/CertificateSlice';
import { useState } from 'react';
import { useAppDispatch } from '../../../store/hooks';
import Spinner from '../../../components/Spinner';
import CustomizedDialogs from '../../../components/CustomizedDialogs';
import { useTranslation } from 'react-i18next';

function SearchPage(props: SearchPageProps) {
    const { t } = useTranslation();
    const { title, description, graphic } = props;
    const {
        handleSubmit,
        getValues,
        register,
        formState: { errors },
    } = useForm<FormValuesTypes>({ mode: 'onChange' });
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const [alertMessage, setAlertMessage] = useState('');
    const [loading, setLoading] = useState(false);
    const [backdropOpen, setBackdropOpen] = useState(false);
    const [openDialog, setOpenDialog] = useState(false);

    const onSubmit = () => {
        setLoading(true);
        setBackdropOpen(true);
        const certificateNum = getValues('certificateNum');
        dispatch(verifyCertificateNum(certificateNum)).then(res => {
            if (res.meta.requestStatus === 'fulfilled') {
                navigate(URLS.PUBLIC.CERT.DETAIL, {
                    state: res.payload,
                });
            }
            if (
                res.meta.requestStatus === 'rejected' &&
                res.payload.status !== 401
            ) {
                setOpenDialog(true);
                setLoading(false);
                setBackdropOpen(false);
                setAlertMessage(res.payload.message);
            }
        });
    };

    return (
        <Box sx={{ p: 4, textAlign: 'center' }}>
            {loading ? <Spinner open={backdropOpen} /> : <div />}
            {openDialog && (
                <CustomizedDialogs
                    open={openDialog}
                    title={t('text.data_authentication.title')}
                    body={alertMessage}
                    primaryButton={t('button.ok')}
                    onPrimaryButtonClick={() => setOpenDialog(false)}
                    isContainTwoButtons={false}
                    isContainDialogAction
                />
            )}
            <form onSubmit={handleSubmit(onSubmit)}>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <img className="logo" alt="" src={logo} />
                    </Grid>
                    <Grid item xs={12}>
                        <div className="welcome-to-vaaz-me montserrat-medium-black-22px">
                            {title}
                        </div>
                    </Grid>
                    <Grid item xs={12}>
                        <GraphicType2 src={graphic} />
                    </Grid>
                    <Grid item xs={12} style={{ textAlign: 'left' }}>
                        <p
                            className="montserrat-medium-black-18px"
                            style={{
                                lineHeight: '30px',
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'center',
                                fontWeight: 'bolder',
                            }}
                        />
                        <p
                            className="montserrat-medium-black-16px"
                            style={{ lineHeight: '30px' }}
                        >
                            {description}
                        </p>
                    </Grid>
                    <Grid item xs={12} style={{ display: 'flex' }}>
                        <TextField
                            fullWidth
                            inputProps={{
                                ...register('certificateNum', {
                                    required: properties.error.this_is_required,
                                }),
                            }}
                            label={t('label.certificateNum')}
                            error={!!errors.certificateNum}
                            style={{
                                flex: 1,
                                marginRight: '-10px',
                            }}
                        />
                        <CertPrimaryButton
                            buttonName={t('button.view')}
                            disabled={false}
                            type="submit"
                        />
                    </Grid>
                    <Grid item xs={12} style={{ paddingTop: '0' }}>
                        {errors.certificateNum && (
                            <FormHelperText style={{ color: 'red' }}>
                                {errors.certificateNum?.message}
                            </FormHelperText>
                        )}
                    </Grid>
                </Grid>
            </form>
        </Box>
    );
}
export default SearchPage;
