/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '../../../services/api/apiCommonConfig';
import { VaazException } from '../../../services/api/initialStates';
import { ReqRegister } from '../../../services/api/requestBody';
import { commonStatusTypes } from '../../../services/constant';
import { API } from '../../api.v1';
import { RootState } from '../../store';

export const resendVerificationEmail = createAsyncThunk(
    '/register/resend/verificationEmail/submit',
    async (
        body: ReqRegister['resendVerificationEmail'],
        { rejectWithValue },
    ) => {
        try {
            const { data } = await axios.post(
                API.REGISTER.RESEND_VERIFICATION_EMAIL,
                body,
            );
            return data;
        } catch (error: unknown) {
            const err = error as VaazException;
            return rejectWithValue(err.response.data);
        }
    },
);

const resendVerificationEmailSlice = createSlice({
    name: '/register/resend/verificationEmail/submit',
    initialState: {
        response: {},
        loading: false,
        errorMsg: '',
        status: commonStatusTypes.IDLE,
    },
    reducers: {},
    extraReducers: builder => {
        builder
            .addCase(resendVerificationEmail.pending, state => {
                state.loading = true;
                state.errorMsg = '';
                state.status = commonStatusTypes.IDLE;
            })
            .addCase(resendVerificationEmail.rejected, (state, action) => {
                state.loading = false;
                state.status = commonStatusTypes.FAILED;

                const { payload } = action;
                const errMsg = payload as VaazException['response']['data'];
                const error = errMsg || action.error;
                state.errorMsg = error.message || '';
            })
            .addCase(resendVerificationEmail.fulfilled, (state, action) => {
                state.loading = false;
                state.status = commonStatusTypes.SUCCESS;
                state.errorMsg = '';
                state.response = action.payload;
            });
    },
});

export const resendVerificationEmailResult = (state: RootState) =>
    state.register.resend;

export default resendVerificationEmailSlice.reducer;
