import { useEffect, useCallback } from 'react';
import { NavigateFunction, useNavigate, useParams } from 'react-router-dom';
import { Typography } from '@mui/material';
import { useAppDispatch } from '../../../store/hooks';
import { verifyRegisterNewUser } from '../../../store/slices/register/verifyRegisterUserSlice';
import { verifyProfileUser } from '../../../store/slices/profile/verifyProfileUserSlice';
import { URLS } from '../../../services/urls';
import { ReqProfile } from '../../../services/api/requestBody';
import { useTranslation } from 'react-i18next';
// import { IGenericState } from '../../../services/api/initialStates';

function redirectAfterVerify(
    res: {
        requestStatus: string;
        code: string;
        message: string;
    },
    navigate: NavigateFunction,
    verificationCode: string,
) {
    if (res.requestStatus === 'fulfilled') {
        navigate(URLS.PUBLIC.VERIFY.VERIFY_EMAIL_SUCCESS, {
            state: {
                access: true,
            },
        });
    } else if (res.requestStatus === 'rejected') {
        navigate(URLS.PUBLIC.VERIFY.VERIFY_EMAIL_FAILED, {
            state: {
                access: true,
                verificationCode,
                errorCode: res.code,
                errorMessage: res.message,
            },
        });
    }
}

export default function VerifyEmailPage() {
    const { code, id } = useParams();

    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const { t } = useTranslation();

    const verifyNewUser = useCallback(() => {
        if (code) {
            if (id) {
                const requestBody: ReqProfile['verify'] = {
                    verificationCode: code,
                    userId: id,
                };
                dispatch(verifyProfileUser(requestBody)).then(res => {
                    redirectAfterVerify(
                        {
                            requestStatus: res.meta.requestStatus,
                            code: res.payload.code,
                            message: res.payload.message,
                        },
                        navigate,
                        code,
                    );
                });
            } else {
                dispatch(verifyRegisterNewUser(code)).then(res => {
                    redirectAfterVerify(
                        {
                            requestStatus: res.meta.requestStatus,
                            code: res.payload.code,
                            message: res.payload.message,
                        },
                        navigate,
                        code,
                    );
                });
            }
        } else {
            localStorage.clear();
            navigate('/');
        }
    }, [dispatch, code, id, navigate]);

    useEffect(() => {
        verifyNewUser();
    }, [verifyNewUser]);

    return <Typography>{`${t('text.verifying_user_email')}...`}</Typography>;
}
