/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { axiosPrivate } from '../../../services/api/apiCommonConfig';
import {
    ISVaazCredit,
    VaazException,
} from '../../../services/api/initialStates';
import { ReqCredit } from '../../../services/api/requestBody';
import { commonStatusTypes } from '../../../services/constant';
import { API } from '../../api.v1';
import { RootState } from '../../store';

export const getCreditsSearch = createAsyncThunk(
    '/credits/search/get',
    async (param: ReqCredit['search'], { rejectWithValue }) => {
        try {
            const { data } = await axiosPrivate.get(
                `${API.VAAZ_CREDIT.SEARCH}${param.userId}?page=${param.page}&size=${param.size}&sort=${param.sort}`,
            );
            return data;
        } catch (error: unknown) {
            const err = error as VaazException;
            return rejectWithValue(err.response.data);
        }
    },
);

const getCreditsSearchSlice = createSlice({
    name: '/credits/search/get',
    initialState: {
        response: ISVaazCredit.search,
        loading: false,
        errorMessage: '',
        status: commonStatusTypes.IDLE,
    },
    reducers: {},
    extraReducers: builder => {
        builder
            .addCase(getCreditsSearch.pending, state => {
                state.loading = true;
                state.errorMessage = '';
                state.status = commonStatusTypes.IDLE;
            })
            .addCase(getCreditsSearch.rejected, (state, action) => {
                state.loading = false;
                state.status = commonStatusTypes.FAILED;

                const { payload } = action;
                const errMsg = payload as VaazException['response']['data'];
                const error = errMsg || action.error;
                state.errorMessage = error.message || '';
            })
            .addCase(getCreditsSearch.fulfilled, (state, action) => {
                state.loading = false;
                state.status = commonStatusTypes.SUCCESS;
                state.errorMessage = '';
                state.response = action.payload;
            });
    },
});

export const getCreditsSearchResults = (state: RootState) =>
    state.credit.search;

export default getCreditsSearchSlice.reducer;
