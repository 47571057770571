import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Typography } from '@mui/material';
import { useAppDispatch } from '../../../store/hooks';
import { sharesValidateLink } from '../../../store/slices/shares/validateLinkSlice';
import { URLS } from '../../../services/urls';
import { ReqShares } from '../../../services/api/requestBody';
import { useTranslation } from 'react-i18next';

export default function VerifyShareRequestPage() {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const { t } = useTranslation();

    const { verificationCode, email } = useParams<string>();

    useEffect(() => {
        localStorage.clear();
        const requestBody: ReqShares['validateLink'] = {
            verificationCode: verificationCode || '',
            email: email || '',
        };
        dispatch(sharesValidateLink(requestBody)).then(res => {
            if (res.meta.requestStatus === 'fulfilled') {
                navigate(URLS.PUBLIC.SHARE.VALIDATE_OTP, {
                    state: {
                        verificationCode,
                    },
                });
            }

            if (
                res.meta.requestStatus === 'rejected' &&
                res.payload.status !== 401
            ) {
                navigate(URLS.PUBLIC.SHARE.VERIFY_FAILED, {
                    state: {
                        access: true,
                    },
                });
            }
        });
    }, [dispatch, email, navigate, verificationCode]);

    return <Typography>{`${t('text.verifying_user_email')}...`}</Typography>;
}
