import { lazy } from 'react';
import { URLS } from '../services/urls';

const nonMemberRoutes = [
    {
        path: URLS.NON_MEMBER.REQUEST.VERIFY,
        exact: true,
        element: lazy(
            () => import('../containers/RequestsPage/VerificationPage/index'),
        ),
    },
    {
        path: URLS.NON_MEMBER.REQUEST.INITIAL_VERIFY,
        exact: true,
        element: lazy(
            () =>
                import(
                    '../containers/RequestsPage/InitialVerificationPage/index'
                ),
        ),
    },
    {
        path: URLS.NON_MEMBER.REQUEST.ACCEPTED,
        exact: true,
        element: lazy(
            () => import('../containers/RequestsPage/TermAcceptedPage/index'),
        ),
    },
    {
        path: URLS.NON_MEMBER.REQUEST.RESULTS,
        exact: true,
        element: lazy(
            () =>
                import(
                    '../containers/RequestsPage/ResultsPage/ShareResultPage/index'
                ),
        ),
    },
    {
        path: URLS.NON_MEMBER.REQUEST.CANCELLED,
        exact: true,
        element: lazy(
            () => import('../containers/RequestsPage/CancelPage/index'),
        ),
    },

    // fulfilment
    {
        path: `${URLS.NON_MEMBER.FULFILMENT.STATIC}:page`,
        exact: true,
        element: lazy(
            () => import('../containers/FulfilmentPage/StaticPage/index'),
        ),
    },
    {
        path: `${URLS.NON_MEMBER.FULFILMENT.CAMERA}:page`,
        exact: true,
        element: lazy(
            () => import('../containers/FulfilmentPage/CameraPage/index'),
        ),
    },
    {
        path: `${URLS.NON_MEMBER.FULFILMENT.PREVIEW}:page`,
        exact: true,
        element: lazy(
            () => import('../containers/FulfilmentPage/PreviewPage/index'),
        ),
    },
    {
        path: URLS.NON_MEMBER.FULFILMENT.ID_TYPE,
        exact: true,
        element: lazy(
            () => import('../containers/FulfilmentPage/IDTypePage/index'),
        ),
    },
];

export default nonMemberRoutes;
