/* eslint-disable react/jsx-props-no-spreading */
import {
    Box,
    FormControl,
    FormHelperText,
    Grid,
    TextField,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { AppBarBody } from '../../../components/AppBar/AppBarBody';
import AppBarWithoutMenu from '../../../components/AppBar/AppBarWithoutMenu';
import {
    NegativeButton,
    PrimaryButton,
    RemoveButton,
} from '../../../components/Button';
import CustomizedDialogs from '../../../components/CustomizedDialogs';
import Spinner from '../../../components/Spinner';
import SponsoredMessage from '../../../components/SponsoredMessage';
import { ReqProfile, ReqRequest } from '../../../services/api/requestBody';
import { localStorageTypes } from '../../../services/constant';
import { AuthTokens } from '../../../services/interfaces';
import { FormValuesTypes } from '../../../services/types';
import { URLS } from '../../../services/urls';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import {
    getProfile,
    getProfileResults,
} from '../../../store/slices/profile/profileSlice';
import { toShareResult } from '../../../store/slices/profile/shareResultSlice';
import { applyPromoCode } from '../../../store/slices/promocode/promoCodeSlice';
import './index.css';

export default function ShareResultPage() {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { pathname } = useLocation();
    const dispatch = useAppDispatch();
    const [otp, setOtp] = useState(new Array(10).fill(''));
    const [isSuccessful, setIsSuccessful] = useState(false);
    const [loading, setLoading] = useState(false);
    const [insufficientCredits, setInsufficientCredit] = useState(false);
    const [promoCode, setPromoCode] = useState('');
    const [appliedPromo, setAppliedPromo] = useState(false);
    const [validCode, setValidCode] = useState(false);
    const [titleDialog, setTitleDialog] = useState('');
    const allFieldRequired = t('error.all_fields_required');
    const [dialogMessage, setDialogMessage] = useState(allFieldRequired);
    const [openDialog, setOpenDialog] = useState(false);
    const alp = t('error.alphanumeric');
    const [validCodeErr] = useState(alp);
    const [sponsorMsg, setSponsorMsg] = useState('');
    const [sponsorImg, setSponsorImg] = useState('');
    const [showSponsored, setShowSponsored] = useState(false);
    const [isInsufficientBalance, setIsInsufficientBalance] = useState(false);
    const [watermark, setWatermark] = useState('FOR VAAZME USE ONLY');

    const requesterUserEmail =
        localStorage.getItem(localStorageTypes.EMAIL) || '';
    const authTokens: AuthTokens = JSON.parse(
        localStorage.getItem(localStorageTypes.AUTH_TOKENS) || '{}',
    );
    const prevUrl = localStorage.getItem(localStorageTypes.PREV_URL) || '';

    // get profiles,
    // previously can only go to shareResultPage by accessing profile page
    // right now can access by 1.profile page 2.mainpage(share_id)
    // account for 2.mainPage, need to call the api again
    const profile = useAppSelector(getProfileResults);
    const [resultPrice, changeResultPrice] = useState(profile.list.resultPrice);
    useEffect(() => {
        dispatch(getProfile(authTokens.userId)).then(res => {
            setLoading(false);
            if (
                res.meta.requestStatus === 'rejected' &&
                res.payload.status !== 401
            ) {
                setTitleDialog(t('text.alert'));
                setOpenDialog(true);
            }
        });
    }, [dispatch, authTokens.userId, t]);

    const {
        handleSubmit,
        register,
        formState: { errors },
        control,
    } = useForm<FormValuesTypes>({ mode: 'all' });

    const onSubmit = (data: FormValuesTypes) => {
        setLoading(true);
        const updatedData: ReqProfile['shareResult'] = {
            requesterUserEmail,
            reportPassword: otp.join(''),
            subjectEmail: data.email,
            promoCode,
            orderTotal: resultPrice,
            watermark,
        };
        dispatch(toShareResult(updatedData)).then(res => {
            if (res.payload === 'SUCCESS') {
                setIsSuccessful(true);
            } else {
                setInsufficientCredit(true);
            }
            setLoading(false);
        });
    };

    const handleOtpChange = (
        event: React.BaseSyntheticEvent,
        index: number,
    ) => {
        const otpValue = event.target.value.replace(/\s/g, '');
        setOtp([...otp.map((d, idx) => (idx === index ? otpValue : d))]);

        const nativeEvent = event.nativeEvent as InputEvent;
        // Focus next input
        if (
            event.target.nextSibling &&
            nativeEvent.inputType === 'insertText' &&
            otpValue !== ''
        ) {
            event.target.nextSibling.focus();
        }

        if (
            event.target.previousSibling &&
            nativeEvent.inputType === 'deleteContentBackward' &&
            otpValue === ''
        ) {
            event.target.previousSibling.focus();
        }
    };

    const handleFormOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const regex = /^[a-zA-Z0-9]+$/;

        if (e.target.value.length < 11) {
            e.preventDefault();
            setPromoCode(e.target.value.toUpperCase());
        }

        if (!regex.test(e.target.value)) {
            setValidCode(true);
        } else {
            setValidCode(false);
        }
    };

    const handlePromoCodeClick = () => {
        const req: ReqRequest['promoCode'] = {
            promoCode,
            requesterUserId: authTokens.userId,
        };

        dispatch(applyPromoCode(req)).then(res => {
            setLoading(false);

            if (res.meta.requestStatus === 'fulfilled') {
                // Cal DISCOUNT_PERCENT
                if (res.payload.discountType === 'DISCOUNT_PERCENT') {
                    const discountPercentAmt = Math.ceil(
                        profile.list.resultPrice *
                            (res.payload.discountAmount / 100),
                    );
                    changeResultPrice(
                        profile.list.resultPrice - discountPercentAmt,
                    );
                } else {
                    const currentResultPriceCredit = Math.max(
                        profile.list.resultPrice - res.payload.discountAmount,
                        0,
                    );
                    changeResultPrice(currentResultPriceCredit);
                }

                if (res.payload.sponsoredMsg) {
                    setSponsorMsg(res.payload.sponsoredMsg);
                    setSponsorImg(res.payload.sponsoredLogo || '');
                } else {
                    setSponsorMsg('');
                    setSponsorImg('');
                }
                setShowSponsored(true);
                setAppliedPromo(true);
            }

            if (
                res.meta.requestStatus === 'rejected' &&
                res.payload.status !== 401
            ) {
                setTitleDialog(t('error.sorry'));
                setDialogMessage(res.payload.message);
                setOpenDialog(true);
                setAppliedPromo(false);
            }
        });
    };

    const handleRemovePromoClick = () => {
        setPromoCode('');
        setAppliedPromo(false);
        changeResultPrice(profile.list.resultPrice);
    };

    const handleActionDialog = () => {
        localStorage.setItem(localStorageTypes.IS_FROM_REQUEST, 'true');
        localStorage.setItem(
            localStorageTypes.PREV_URL,
            URLS.MEMBER.REQUEST.CREATE_NEW_REQUEST_CONFIRM,
        );
        // navigate(pathname.pathname_vaazme_home_vaazcredits_topup);
        navigate(URLS.MEMBER.VAAZCREDITS.CONTACT);
        setIsInsufficientBalance(false);
    };

    const changeWatermark = (value: string) => {
        setWatermark(value);
    };

    return (
        <>
            <CustomizedDialogs
                open={isSuccessful}
                title={t('text.dialog.title.share_results')}
                body={t('text.dialog.description.share_report_success')}
                primaryButton={t('button.done')}
                onPrimaryButtonClick={() => {
                    setIsSuccessful(false);
                    navigate(prevUrl || URLS.MEMBER.HOME.MAIN);
                }}
                isContainDialogAction
                isContainTwoButtons={false}
            />
            <CustomizedDialogs
                open={insufficientCredits}
                title={t('error.insufficient_credits')}
                body={t('text.dialog.description.not_enough_credits')}
                secondaryButton={`${t('button.need_more_credits')}?`}
                onSecondaryButtonClick={() => {
                    localStorage.setItem(localStorageTypes.PREV_URL, pathname);
                    navigate(URLS.MEMBER.VAAZCREDITS.CONTACT);
                }}
                primaryButton={t('button.close')}
                onPrimaryButtonClick={() => {
                    setInsufficientCredit(false);
                }}
                isContainDialogAction
                isContainTwoButtons
                customButtonStyle="custom-button-style"
            />

            {openDialog ? (
                <CustomizedDialogs
                    open={openDialog}
                    title={titleDialog}
                    body={dialogMessage}
                    primaryButton={
                        titleDialog === t('text.success')
                            ? t('button.ok')
                            : t('button.close')
                    }
                    onPrimaryButtonClick={() => setOpenDialog(false)}
                    secondaryButton={
                        isInsufficientBalance
                            ? `${t('button.need_more_credits')}?` // t(button_name_topup
                            : ''
                    }
                    onSecondaryButtonClick={handleActionDialog}
                    isContainDialogAction
                    isContainTwoButtons={!!isInsufficientBalance}
                />
            ) : (
                <div />
            )}

            <CustomizedDialogs
                open={showSponsored}
                title={t('text.success')}
                isContainTwoButtons={false}
                isContainDialogAction
                primaryButton={t('button.ok')}
                onPrimaryButtonClick={() => setShowSponsored(!showSponsored)}
            >
                <p>{t('text.dialog.description.promo_applied')}</p>
                <br />
                {sponsorMsg ? (
                    <SponsoredMessage
                        sponsorMsg={sponsorMsg}
                        sponsorImg={sponsorImg}
                    />
                ) : (
                    false
                )}
            </CustomizedDialogs>

            {loading ? <Spinner open={loading} /> : <div />}

            <AppBarWithoutMenu
                title={t('app_bar_title.share_results')}
                link={prevUrl}
            />
            <AppBarBody />
            <Box sx={{ p: 4, textAlign: 'center' }}>
                <Grid container spacing={4}>
                    <Grid item xs={12} style={{ textAlign: 'left' }}>
                        <span
                            className="montserrat-medium-black-16px"
                            style={{ lineHeight: '30px' }}
                        >
                            {t('text.share_result.description_1')}{' '}
                            <p style={{ fontWeight: 700 }}>
                                {resultPrice} credits.
                            </p>
                        </span>
                    </Grid>
                    <Grid item xs={12}>
                        {appliedPromo !== undefined && (
                            <Grid container spacing={3}>
                                <Grid
                                    item
                                    xs={8}
                                    sx={{
                                        textAlign: 'left',
                                    }}
                                >
                                    <TextField
                                        value={promoCode || ''}
                                        fullWidth
                                        disabled={appliedPromo}
                                        label={t('label.promo_code')}
                                        placeholder={t('text.enter_promo_code')}
                                        className="montserrat-medium-outer-space-16px"
                                        size="small"
                                        error={validCode}
                                        helperText={
                                            validCode ? validCodeErr : null
                                        }
                                        onChange={handleFormOnChange}
                                    />
                                </Grid>
                                <Grid
                                    item
                                    xs={4}
                                    sx={{
                                        textAlign: 'auto',
                                    }}
                                >
                                    {appliedPromo ? (
                                        <RemoveButton
                                            isFullWidth
                                            buttonName={t('button.remove')}
                                            callback={handleRemovePromoClick}
                                            type="reset"
                                        />
                                    ) : (
                                        <PrimaryButton
                                            isFullWidth
                                            buttonName={t('button.apply')}
                                            type="submit"
                                            callback={handlePromoCodeClick}
                                            disabled={
                                                (promoCode !== undefined &&
                                                    promoCode.length < 4) ||
                                                validCode
                                            }
                                        />
                                    )}
                                </Grid>
                            </Grid>
                        )}
                    </Grid>
                    <Grid item xs={12}>
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <Grid container spacing={3}>
                                <Grid
                                    item
                                    xs={12}
                                    style={{ paddingTop: '15px' }}
                                >
                                    <Controller
                                        name="watermark"
                                        defaultValue={watermark}
                                        control={control}
                                        render={({ field: { onChange } }) => (
                                            <Grid
                                                item
                                                xs={12}
                                                style={{
                                                    textAlign: 'left',
                                                    marginTop: '30px',
                                                }}
                                            >
                                                <p className="montserrat-medium-black-16px">
                                                    {t(
                                                        'label.add_a_custom_watermark',
                                                    )}
                                                    :
                                                </p>
                                                <FormControl
                                                    style={{
                                                        minWidth: '100%',
                                                        marginTop: '10px',
                                                    }}
                                                >
                                                    <TextField
                                                        className={
                                                            watermark.length <
                                                            10
                                                                ? 'outlined-error-helper-text'
                                                                : ''
                                                        }
                                                        error={
                                                            watermark.length <
                                                            10
                                                        }
                                                        id="outlined-basic"
                                                        placeholder="Custom Watermark"
                                                        value={watermark}
                                                        variant="outlined"
                                                        onChange={event => {
                                                            onChange(
                                                                event.target
                                                                    .value,
                                                            );
                                                            changeWatermark(
                                                                event.target
                                                                    .value,
                                                            );
                                                        }}
                                                        inputProps={{
                                                            maxLength: 25,
                                                        }}
                                                        helperText={
                                                            watermark.length <
                                                            10
                                                                ? 'Watermark must be at least 10 words.'
                                                                : ''
                                                        }
                                                    />
                                                    <FormHelperText
                                                        error
                                                        sx={{
                                                            textAlign: 'end',
                                                        }}
                                                    >
                                                        {25 - watermark.length}
                                                        /25 characters left
                                                    </FormHelperText>
                                                </FormControl>
                                            </Grid>
                                        )}
                                    />
                                </Grid>
                                <Grid
                                    item
                                    xs={12}
                                    style={{ textAlign: 'left' }}
                                >
                                    <p className="montserrat-medium-black-16px">
                                        {t('label.share_results_to')}
                                    </p>
                                </Grid>
                                <Grid
                                    item
                                    xs={12}
                                    style={{ paddingTop: '15px' }}
                                >
                                    <TextField
                                        fullWidth
                                        {...register('email', {
                                            required: t(
                                                'error.this_is_required',
                                            ),
                                            pattern: {
                                                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                                message: t(
                                                    'error.invalid_email',
                                                ),
                                            },
                                        })}
                                        type="email"
                                        label={t('label.email_address')}
                                        className="montserrat-medium-outer-space-16px"
                                        error={!!errors.email?.message}
                                        helperText={
                                            errors
                                                ? errors.email?.message
                                                : null
                                        }
                                    />
                                </Grid>
                                <Grid
                                    item
                                    xs={12}
                                    style={{
                                        textAlign: 'left',
                                        lineHeight: '30px',
                                    }}
                                >
                                    <p className="montserrat-medium-black-16px">
                                        {t('text.share_result.description_2')}
                                    </p>
                                </Grid>
                                <Grid
                                    item
                                    xs={12}
                                    style={{ textAlign: 'left' }}
                                >
                                    <p className="montserrat-medium-black-16px">
                                        {t('label.set_a_password')}
                                    </p>
                                </Grid>
                                <Grid
                                    item
                                    xs={12}
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                    }}
                                >
                                    {otp.map((data, index) => {
                                        return (
                                            <Controller
                                                // eslint-disable-next-line react/no-array-index-key
                                                key={`otp-${index}`}
                                                name="otp"
                                                control={control}
                                                defaultValue=""
                                                render={({
                                                    field: { onChange },
                                                }) => (
                                                    <input
                                                        id={`otp-${index}`}
                                                        className="otp-field montserrat-medium-outer-space-16px"
                                                        type="tel"
                                                        name="otp"
                                                        value={data}
                                                        onChange={e => {
                                                            e.currentTarget.setCustomValidity(
                                                                '',
                                                            );
                                                            handleOtpChange(
                                                                e,
                                                                index,
                                                            );
                                                            onChange(
                                                                e.target.value,
                                                            );
                                                        }}
                                                        onFocus={e =>
                                                            e.target.select()
                                                        }
                                                        inputMode="numeric"
                                                        pattern="[0-9]*"
                                                        maxLength={1}
                                                        onInvalid={e => {
                                                            if (
                                                                e.currentTarget
                                                                    .validity
                                                                    .patternMismatch
                                                            ) {
                                                                e.currentTarget.setCustomValidity(
                                                                    'Numbers only',
                                                                );
                                                            }
                                                        }}
                                                        style={{
                                                            whiteSpace:
                                                                'nowrap',
                                                            height: '30px',
                                                            width: '30px',
                                                        }}
                                                        required
                                                    />
                                                )}
                                                rules={{
                                                    required: 'Required',
                                                }}
                                            />
                                        );
                                    })}
                                </Grid>
                                <Grid item xs={6}>
                                    <PrimaryButton
                                        isFullWidth
                                        buttonName={t('button.submit')}
                                        type="submit"
                                        disabled={false}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <NegativeButton
                                        isFullWidth
                                        buttonName={t('button.cancel')}
                                        type="button"
                                        disabled={false}
                                        link={prevUrl}
                                    />
                                </Grid>
                            </Grid>
                        </form>
                    </Grid>
                </Grid>
            </Box>
        </>
    );
}
