/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { axiosPrivate } from '../../../services/api/apiCommonConfig';
import { VaazException } from '../../../services/api/initialStates';
import { commonStatusTypes } from '../../../services/constant';
import { FormValuesTypes } from '../../../services/types';
import { API } from '../../api.v1';
import { RootState } from '../../store';

export const updatePreferredLanguage = createAsyncThunk(
    '/profile/language',
    async (body: FormValuesTypes, { rejectWithValue }) => {
        try {
            const { data } = await axiosPrivate.post(
                API.PROFILE.UPDATE_PREFERRED_LANGUAGE,
                body,
            );
            return data;
        } catch (error: unknown) {
            const err = error as VaazException;
            return rejectWithValue(err.response.data);
        }
    },
);

const updatePreferredLanguageSlice = createSlice({
    name: '/profile/update',
    initialState: {
        response: {},
        loading: false,
        errorMsg: '',
        status: commonStatusTypes.IDLE,
    },
    reducers: {},
    extraReducers: builder => {
        builder
            .addCase(updatePreferredLanguage.pending, state => {
                state.loading = true;
                state.errorMsg = '';
                state.status = commonStatusTypes.IDLE;
            })
            .addCase(updatePreferredLanguage.rejected, (state, action) => {
                state.loading = false;
                state.status = commonStatusTypes.FAILED;

                const { payload } = action;
                const errMsg = payload as VaazException['response']['data'];
                const error = errMsg || action.error;
                state.errorMsg = error.message || '';
            })
            .addCase(updatePreferredLanguage.fulfilled, (state, action) => {
                state.loading = false;
                state.status = commonStatusTypes.SUCCESS;
                state.errorMsg = '';
                state.response = action.payload;
            });
    },
});

export const getProfileUpdateResults = (state: RootState) =>
    state.profile.language;

export default updatePreferredLanguageSlice.reducer;
