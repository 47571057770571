import * as React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import { Backdrop } from '@mui/material';
import { useTranslation } from 'react-i18next';

export default function Spinner(props: { open: boolean }) {
    const { open } = props;
    const timerRef = React.useRef<number>();

    React.useEffect(
        () => () => {
            clearTimeout(timerRef.current);
        },
        [],
    );

    return (
        <div>
            <Backdrop
                sx={{
                    color: '#fff',
                    zIndex: theme => theme.zIndex.drawer + 1,
                }}
                open={open}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        </div>
    );
}

export function SpinnerWait(props: { open: boolean }) {
    const { t } = useTranslation();
    const { open } = props;
    const timerRef = React.useRef<number>();

    React.useEffect(
        () => () => {
            clearTimeout(timerRef.current);
        },
        [],
    );

    return (
        <div>
            <Backdrop
                sx={{
                    color: '#fff',
                    zIndex: theme => theme.zIndex.drawer + 1,
                    display: 'flex',
                    justifyContent: 'center',
                    alignContent: 'center',
                    flexDirection: 'column',
                }}
                open={open}
            >
                <CircularProgress color="inherit" />
                <p
                    className="montserrat-medium-tuatara-14px"
                    style={{
                        maxWidth: '50%',
                        wordWrap: 'break-word',
                        textAlign: 'center',
                        color: '#fff',
                        marginTop: '15px',
                    }}
                >
                    {t('text.spinner_wait')}
                </p>
            </Backdrop>
        </div>
    );
}
