import { useEffect, useState, useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Typography } from '@mui/material';
import { useAppDispatch } from '../../../store/hooks';
import { verifyRequest } from '../../../store/slices/authentication/verifyRequestsSlice';
import {
    localStorageTypes,
    MASTER_MEMBER_CODE,
} from '../../../services/constant';
import { URLS } from '../../../services/urls';
import { ReqAuthentication } from '../../../services/api/requestBody';
import { verifyMemberRequest } from '../../../store/slices/requests/verifyMemberRequestSlice';
import CustomizedDialogs from '../../../components/CustomizedDialogs';
import { useTranslation } from 'react-i18next';

export default function VerifyRequestsPage() {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const [openDialog, setOpenDialog] = useState(false);
    const [consentFlow, setConsentFlow] = useState(false);
    const { verificationId, verificationCode, member } = useParams();
    const { t } = useTranslation();

    const handleGuestVerification = useCallback(
        (res: any) => {
            if (res.meta.requestStatus === 'fulfilled') {
                const { requestId, requestStatus } = res.payload;
                localStorage.clear();

                localStorage.setItem(localStorageTypes.USER_AUTH, 'true');
                localStorage.setItem(localStorageTypes.NON_MEMBER, 'true');
                localStorage.setItem(
                    localStorageTypes.FULFILMENT_REQUEST_ID,
                    requestId,
                );
                localStorage.setItem(
                    localStorageTypes.AUTH_TOKENS,
                    JSON.stringify(res.payload),
                );

                if (requestStatus === MASTER_MEMBER_CODE.NOT_STARTED) {
                    navigate(URLS.NON_MEMBER.REQUEST.INITIAL_VERIFY, {
                        state: { requestId },
                    });
                }
                if (requestStatus === MASTER_MEMBER_CODE.REQUEST_ACCEPTED) {
                    navigate(URLS.NON_MEMBER.REQUEST.ACCEPTED, {
                        state: { url: '', requestId },
                    });
                }
                if (requestStatus === MASTER_MEMBER_CODE.IN_PROGRESS) {
                    navigate(URLS.NON_MEMBER.FULFILMENT.ID_TYPE);
                }
                if (requestStatus === MASTER_MEMBER_CODE.COMPLETED) {
                    navigate(URLS.NON_MEMBER.FULFILMENT.RESULT, {
                        state: {
                            access: true,
                        },
                    });
                }
                if (requestStatus === MASTER_MEMBER_CODE.CANCELLED) {
                    navigate(URLS.NON_MEMBER.REQUEST.CANCELLED, {
                        state: { path: window.location.pathname },
                    });
                }
            }
            if (
                res.meta.requestStatus === 'rejected' &&
                res.payload.status !== 401
            ) {
                navigate(URLS.PUBLIC.VERIFY.REQUEST_VERIFY_FAILED, {
                    state: {
                        access: true,
                    },
                });
            }
        },
        [navigate],
    );

    useEffect(() => {
        setConsentFlow(false);

        if (verificationId && verificationCode) {
            const requestBody: ReqAuthentication['verification'] = {
                verificationId,
                verificationCode,
            };
            const loggedEmail = localStorage.getItem('email');
            const booleanLogged = loggedEmail !== '' && loggedEmail != null;

            if (member === 'true') {
                dispatch(verifyMemberRequest(requestBody)).then(res => {
                    const {
                        requestId,
                        requestStatus,
                        subjectEmail,
                        subjectSelfVerificationStatus,
                        subjectSvReportConsentFlag,
                    } = res.payload;
                    if (res.meta.requestStatus === 'fulfilled') {
                        if (!loggedEmail) {
                            localStorage.clear();
                            navigate(URLS.PUBLIC.LOGIN.MAIN);
                            window.location.reload();
                        } else {
                            if (loggedEmail !== subjectEmail) {
                                setOpenDialog(true);
                            }
                            if (loggedEmail === subjectEmail) {
                                if (
                                    requestStatus ===
                                    MASTER_MEMBER_CODE.NOT_STARTED
                                ) {
                                    navigate(
                                        URLS.MEMBER.REQUEST.INITIAL_VERIFY,
                                        {
                                            state: {
                                                requestId,
                                                subjectSvReportConsentFlag,
                                            },
                                        },
                                    );
                                }

                                if (
                                    requestStatus ===
                                    MASTER_MEMBER_CODE.REQUEST_ACCEPTED
                                ) {
                                    navigate(URLS.MEMBER.REQUEST.ACCEPTED, {
                                        state: {
                                            url: '',
                                            requestId,
                                            subjectSvReportConsentFlag,
                                        },
                                    });
                                }

                                if (
                                    requestStatus ===
                                    MASTER_MEMBER_CODE.IN_PROGRESS
                                ) {
                                    localStorage.setItem(
                                        localStorageTypes.FULFILMENT_REQUEST_ID,
                                        requestId,
                                    );
                                    if (
                                        !subjectSelfVerificationStatus ||
                                        !subjectSvReportConsentFlag
                                    ) {
                                        navigate(
                                            URLS.MEMBER.FULFILMENT.ID_TYPE,
                                            {
                                                state: {
                                                    requestId,
                                                    subjectSvReportConsentFlag,
                                                },
                                            },
                                        );
                                    } else {
                                        navigate(
                                            URLS.MEMBER.REQUEST
                                                .FULFILMENT_OPTION,
                                            {
                                                state: {
                                                    requestId,
                                                    subjectSvReportConsentFlag,
                                                },
                                            },
                                        );
                                    }
                                }

                                if (
                                    requestStatus ===
                                    MASTER_MEMBER_CODE.COMPLETED
                                ) {
                                    navigate(
                                        `${URLS.MEMBER.FULFILMENT.STATIC}complete`,
                                        {
                                            state: { id: requestId },
                                        },
                                    );
                                }

                                if (
                                    requestStatus ===
                                    MASTER_MEMBER_CODE.CANCELLED
                                ) {
                                    navigate(
                                        URLS.NON_MEMBER.REQUEST.CANCELLED,
                                        {
                                            state: {
                                                path: window.location.pathname,
                                            },
                                        },
                                    );
                                }
                            }
                        }
                    }

                    if (
                        res.meta.requestStatus === 'rejected' &&
                        res.payload.status !== 401
                    ) {
                        navigate(URLS.PUBLIC.VERIFY.REQUEST_VERIFY_FAILED, {
                            state: {
                                access: true,
                            },
                        });
                    }
                });
            } else if (member === 'false') {
                // check from guest verification
                if (booleanLogged) {
                    setOpenDialog(true);
                    setConsentFlow(true);
                } else {
                    dispatch(verifyRequest(requestBody)).then(res => {
                        handleGuestVerification(res);
                    });
                }
            } else {
                navigate(URLS.PUBLIC.VERIFY.REQUEST_VERIFY_FAILED, {
                    state: {
                        access: true,
                    },
                });
            }
        }
    }, [
        dispatch,
        navigate,
        verificationCode,
        verificationId,
        member,
        handleGuestVerification,
    ]);

    const exitUserDialog = () => {
        setOpenDialog(false);
        if (consentFlow) {
            if (verificationId && verificationCode) {
                const requestBody: ReqAuthentication['verification'] = {
                    verificationId,
                    verificationCode,
                };
                dispatch(verifyRequest(requestBody)).then(res => {
                    handleGuestVerification(res);
                });
            }
        } else {
            localStorage.clear();
            navigate(URLS.PUBLIC.LOGIN.MAIN);
            window.location.reload();
        }
    };

    return (
        <>
            <CustomizedDialogs
                open={openDialog}
                title={t('text.dialog.title.email_doesnt_match')}
                primaryButton={t('button.ok')}
                isContainTwoButtons={false}
                body={t('text.dialog.description.email_doesnt_match')}
                isContainDialogAction
                onPrimaryButtonClick={exitUserDialog}
            />
            <Typography>{`${t('text.verifying_user_email')}...`}</Typography>
        </>
    );
}
