export const COMMON_PATH = '/vm';
export const COMMON_PRIVATE_PATH = `${COMMON_PATH}/home`;
export const COMMON_NON_MEMBER_PATH = `${COMMON_PATH}/non-member`;

export const URLS = {
    PUBLIC: {
        MAIN: '/',

        LOGIN: {
            MAIN: `${COMMON_PATH}/login`,
            OTP: `${COMMON_PATH}/login-otp`,
        },

        REGISTER: {
            MAIN: `${COMMON_PATH}/register`,
            SUCCESS: `${COMMON_PATH}/register-success`,
        },

        CERT: {
            MAIN: '/certauth',
            DETAIL: '/certauth/detail',
        },

        TERMS_CONDITION: `${COMMON_PATH}/terms-condition`,
        PDPA: `${COMMON_PATH}/pdpa`,
        TERMS_IDV: `${COMMON_PATH}/term-idv`,

        VERIFY: {
            VERIFY_EMAIL: `${COMMON_PATH}/verify-email`,
            VERIFY_EMAIL_SUCCESS: `${COMMON_PATH}/verify-email-success`,
            VERIFY_EMAIL_FAILED: `${COMMON_PATH}/verify-email-failed`,

            REQUEST_VERIFY: `${COMMON_PATH}/request-verify`,
            REQUEST_VERIFY_FAILED: `${COMMON_PATH}/verify-request-failed`,
        },

        SHARE: {
            RESULT: `${COMMON_PATH}/share/result`,
            VALIDATE_OTP: `${COMMON_PATH}/share/validate-otp`,
            VERIFY_FAILED: `${COMMON_PATH}/verify-shares-failed`,
        },

        TEST_CAMERA: {
            REACT_CAMERA_PRO: `${COMMON_PATH}/test-camera/rcp`,
            CUSTOM: `${COMMON_PATH}/test-camera/custom`,
        },
        REMOVE_DATA: {
            MAIN: `${COMMON_PATH}/request-remove-data`,
            REMOVED: `${COMMON_PATH}/removed`,
            REQUEST: `${COMMON_PATH}/users-request-remove-data`,
        },
    },

    NON_MEMBER: {
        FULFILMENT: {
            RESULT: `${COMMON_NON_MEMBER_PATH}/fulfilment/result`,
            ID_TYPE: `${COMMON_NON_MEMBER_PATH}/fulfilment/create/id-type`,
            STATIC: `${COMMON_NON_MEMBER_PATH}/fulfilment/create/static/`,
            CAMERA: `${COMMON_NON_MEMBER_PATH}/fulfilment/create/camera/`,
            PREVIEW: `${COMMON_NON_MEMBER_PATH}/fulfilment/create/preview/`,
        },

        REQUEST: {
            INITIAL_VERIFY: `${COMMON_NON_MEMBER_PATH}/requests/initial_verify`,
            VERIFY: `${COMMON_NON_MEMBER_PATH}/requests/verify`,
            ACCEPTED: `${COMMON_NON_MEMBER_PATH}/requests/accepted`,
            RESULTS: `${COMMON_NON_MEMBER_PATH}/requests/results`,
            CANCELLED: `${COMMON_NON_MEMBER_PATH}/requests/cancelled`,
        },
    },

    MEMBER: {
        FAILED: `${COMMON_PRIVATE_PATH}/failed`,

        HOME: {
            MAIN: `${COMMON_PRIVATE_PATH}/main`,
        },

        MESSAGES: {
            MAIN: `${COMMON_PRIVATE_PATH}/message`,
        },

        VAAZCREDITS: {
            MAIN: `${COMMON_PRIVATE_PATH}/vaazcredits`,
            ALL: `${COMMON_PRIVATE_PATH}/vaazcredits/all`,
            TOPUP: `${COMMON_PRIVATE_PATH}/vaazcredits/topup`,
            RESULT: `${COMMON_PRIVATE_PATH}/vaazcredits/result`,
            CONTACT: `${COMMON_PRIVATE_PATH}/vaazcredits/contact`,
            TOPUP_OFFLINE: `${COMMON_PRIVATE_PATH}/vaazcredits/topupOffine`,
            TOPUP_OFFLINE_SUCCESS: `${COMMON_PRIVATE_PATH}/vaazcredits/topupOffineSuccessful`,
        },

        FULFILMENT: {
            ID_TYPE: `${COMMON_PRIVATE_PATH}/fulfilment/create/id-type`,
            STATIC: `${COMMON_PRIVATE_PATH}/fulfilment/create/static/`,
            CAMERA: `${COMMON_PRIVATE_PATH}/fulfilment/create/camera/`,
            PREVIEW: `${COMMON_PRIVATE_PATH}/fulfilment/create/preview/`,
            RESULT: `${COMMON_PRIVATE_PATH}/fulfilment/result`,
            SELF_VERIFICATION: {
                CONTACT: `${COMMON_PRIVATE_PATH}/self-verification`,
                RESULT: `${COMMON_PRIVATE_PATH}/self-verification/result`,
            },
        },

        HISTORY: {
            MAIN: `${COMMON_PRIVATE_PATH}/history`,
            REQUEST: `${COMMON_PRIVATE_PATH}/history/requests`,
        },

        PROFILE: {
            MAIN: `${COMMON_PRIVATE_PATH}/profile`,
            EDIT: `${COMMON_PRIVATE_PATH}/profile/edit`,
            EDIT_SETTING_PAGE: `${COMMON_PRIVATE_PATH}/profile/setting`,
        },

        REQUEST: {
            CREATE_NEW_REQUEST_CHECK_ITEMS: `${COMMON_PRIVATE_PATH}/request/create/check-items`,
            CREATE_NEW_REQUEST_SUBJECT_DETAILS: `${COMMON_PRIVATE_PATH}/request/create/subject-details`,
            CREATE_NEW_REQUEST_CONFIRM: `${COMMON_PRIVATE_PATH}/request/create/confirm`,
            CREATE_NEW_REQUEST_COMPLETE: `${COMMON_PRIVATE_PATH}/request/create/complete`,

            INITIAL_VERIFY: `${COMMON_PRIVATE_PATH}/requests/initial_verify`,
            FULFILMENT_OPTION: `${COMMON_PRIVATE_PATH}/requests/fulfilment_option`,
            VERIFY: `${COMMON_PRIVATE_PATH}/requests/verify`,
            ACCEPTED: `${COMMON_PRIVATE_PATH}/requests/accepted`,

            RESULTS: `${COMMON_PRIVATE_PATH}/requests/results`,
            DETAILS: `${COMMON_PRIVATE_PATH}/requests/details`,
            CANCELLED: `${COMMON_PRIVATE_PATH}/requests/cancelled`,
        },

        SHARE: {
            RESULTS: `${COMMON_PRIVATE_PATH}/share/results`,
        },
    },
};
