import { combineReducers } from '@reduxjs/toolkit';
import getRequestParametersSlice from './parameterSlice';
import getOrderSummarySlice from './orderSummarySlice';
import createNewRequestSlice from './createRequestsSlice';
import getRequestsFulfilmentSlice from './requestsFulfilmentSlice';
import submitRequestFulfilmentSlice from './submitRequestFulfimentSlice';
import getRequestHistorySlice from './historySlice';
import getRequestsDetailSlice from './detailSlice';
import getRequestResultsSlice from './requestResultsSlice';
import cancelRequestSlice from './cancelRequestSlice';
import generateRequestResultPdfSlice from './generateRequestResultPdfSlice';
import verifyMemberRequestSlice from './verifyMemberRequestSlice';
import selfVerificationSlice from './selfVerificationSlice';
import requestSelfVerificationResultsSlice from './requestSelfVerificationResultsSlice';

export default combineReducers({
    parameters: getRequestParametersSlice,
    orderSummary: getOrderSummarySlice,
    newRequest: createNewRequestSlice,
    requestFulfilment: getRequestsFulfilmentSlice,
    submitFulfilment: submitRequestFulfilmentSlice,
    history: getRequestHistorySlice,
    detail: getRequestsDetailSlice,
    results: getRequestResultsSlice,
    cancel: cancelRequestSlice,
    generate: generateRequestResultPdfSlice,
    verification: verifyMemberRequestSlice,
    selfVerification: selfVerificationSlice,
    selfVerificationResults: requestSelfVerificationResultsSlice,
});
