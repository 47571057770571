/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/no-danger */
/* eslint-disable jsx-a11y/alt-text */
import VerifiedIcon from '@mui/icons-material/Verified';
import { Divider, IconButton, TextField, Tooltip } from '@mui/material';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { LocalizationProvider, MobileDatePicker } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import moment, { Moment } from 'moment';
import React from 'react';
import { FieldErrors, UseFormRegister } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import check from '../../assets/img/check-1@2x.svg';
import checkFailed from '../../assets/img/check-failed.png';
import checkSuccess from '../../assets/img/check-success.png';
import noImageCaptured from '../../assets/img/image-not-captured.png';
import xcross from '../../assets/img/x-3@2x.svg';
import { IFulfilment } from '../../services/api/initialStates';
import { MASTER_MEMBER_CODE } from '../../services/constant';
import {
    CardChecklistBoxProps,
    CardDetailCertProps,
    CardDetailCompletedProps,
    CardDetailProps,
    CardImageBoxProps,
    CardTextProps,
    CheckItemsWithDescriptionProps,
    cardMenuStyle2Prop,
} from '../../services/interfaces';
import { properties } from '../../services/properties';
import { CardBoxDescriptionTypes, FormValuesTypes } from '../../services/types';
import { checkDateIsValid } from '../../services/utils';
import { PrimaryButton } from '../Button';
import './CardMenu.css';

export function CardMenuStyle1(
    props: Readonly<{
        title: string;
        description: string;
        imgUrl: string;
        link: string;
        selfVerificationPopUp?: boolean;
        callback?: () => void;
    }>,
) {
    const {
        title,
        description,
        imgUrl,
        link,
        selfVerificationPopUp,
        callback,
    } = props;
    const navigate = useNavigate();

    const handleClick = () => {
        if (selfVerificationPopUp) {
            callback?.();
        } else {
            navigate(link);
        }
    };

    return (
        <Box onClick={() => handleClick()} className="home-menu-box">
            <Grid container>
                <Grid item xs={8} sx={{ p: 3, textAlign: 'left' }}>
                    <span className="montserrat-semi-bold-koromiko-15px">
                        {title}
                    </span>
                    <br />
                    <span className="montserrat-medium-black-pearl-11px">
                        {description}
                    </span>
                </Grid>
                <Grid item xs={4} sx={{ p: 1, textAlign: 'right' }}>
                    <img className="card-img" alt="" src={imgUrl} />
                </Grid>
            </Grid>
        </Box>
    );
}

// CardMenuStyle1.defaultProps = {
//     selfVerificationPopUp: false, // Default value for selfVerificationPopUp
//     callback: () => null, // Default value for callback
// };

export function CardMenuStyle2(props: cardMenuStyle2Prop) {
    const { title, description, imgUrl, link, isSelfVerified, callback } =
        props;
    const navigate = useNavigate();

    return (
        <Box
            onClick={() => {
                // it will only true if got specific to pass in something, otherwise it is false/null
                if (isSelfVerified === true) {
                    callback?.();
                } else {
                    navigate(link);
                }
            }}
            // Use a ternary operator to conditionally apply the home-menu-box or home-menu-box-disable class
            className={`home-menu-box ${
                isSelfVerified === false ? 'home-menu-box-disable' : ''
            }`}
            // Use a ternary operator to conditionally set the pointerEvents style to 'none' or 'auto'
            style={{
                pointerEvents: isSelfVerified === false ? 'none' : 'auto',
            }}
        >
            <Grid container>
                <Grid item xs={8} sx={{ p: 3, textAlign: 'left' }}>
                    <span className="montserrat-semi-bold-eastern-blue-15px">
                        {title}
                    </span>
                    <br />
                    <span className="montserrat-medium-black-pearl-11px">
                        {description}
                    </span>
                </Grid>
                <Grid item xs={4} sx={{ p: 1, textAlign: 'right' }}>
                    <img className="card-img" alt="" src={imgUrl} />
                </Grid>
            </Grid>
        </Box>
    );
}

export function CardDescriptionBox(props: CardBoxDescriptionTypes) {
    const { title, name, email, phoneNumber, userVerifyStatus } = props;
    const { t } = useTranslation();

    return (
        <Box className="summary-menu-box">
            <Grid container>
                <Grid item xs={12} sx={{ p: 1, textAlign: 'center' }}>
                    <span className="montserrat-medium-black-9px">{title}</span>
                </Grid>
                <Grid
                    item
                    xs={12}
                    sx={{
                        pl: 2,
                        pr: 2,
                        pb: 2,
                        textAlign: 'left',
                    }}
                >
                    <span className="montserrat-medium-shady-lady-9px">
                        {t('label.name')}
                    </span>
                    <br />
                    <Grid container>
                        <Grid
                            item
                            xs={12}
                            style={{
                                textAlign: 'left',
                                marginTop: '5px',
                                display: 'flex',
                            }}
                        >
                            <p className="montserrat-medium-tuatara-14px">
                                {name}
                                {userVerifyStatus &&
                                    userVerifyStatus === 'VERIFIED' && (
                                        <Tooltip
                                            title="Verified User"
                                            placement="right-start"
                                            arrow
                                            disableFocusListener
                                            enterTouchDelay={0}
                                        >
                                            <IconButton
                                                style={{
                                                    padding: 0,
                                                }}
                                            >
                                                <VerifiedIcon className="appbar-verified-icon appbar-verified-icon-additional" />
                                            </IconButton>
                                        </Tooltip>
                                    )}
                            </p>
                            {/* <span className="montserrat-medium-tuatara-14px">
                                {name}
                            </span>

                            {userVerifyStatus &&
                                userVerifyStatus === 'VERIFIED' && (
                                    <div>
                                        <Tooltip
                                            title="Verified User"
                                            placement="right-start"
                                            arrow
                                            disableFocusListener
                                            enterTouchDelay={0}
                                        >
                                            <IconButton
                                                style={{
                                                    padding: 0,
                                                }}
                                            >
                                                <VerifiedIcon className="appbar-verified-icon" />
                                            </IconButton>
                                        </Tooltip>
                                    </div>
                                )} */}
                        </Grid>
                    </Grid>
                    {/* <div style={{ display: 'flex', marginTop: '5px' }}>
                    <span className="montserrat-medium-tuatara-14px">
                        {name}
                    </span>

                        {userVerifyStatus && userVerifyStatus === 'VERIFIED' && (
                            <div style={{ paddingLeft: '5px' }}>
                                <Tooltip
                                    title="Verified User"
                                    placement="right-start"
                                    arrow
                                    disableFocusListener
                                    enterTouchDelay={0}
                                >
                                    <IconButton
                                        style={{
                                            padding: 0,
                                        }}
                                    >
                                        <VerifiedIcon className="appbar-verified-icon" />
                                    </IconButton>
                                </Tooltip>
                            </div>
                        )}
                    </div> */}
                </Grid>
                <Grid
                    item
                    xs={12}
                    sx={{
                        pl: 2,
                        pr: 2,
                        pb: 2,
                        textAlign: 'left',
                    }}
                >
                    <span className="montserrat-medium-shady-lady-9px">
                        {t('label.email_address')}
                    </span>
                    <br />
                    <span className="montserrat-medium-tuatara-14px">
                        {email}
                    </span>
                </Grid>
                {phoneNumber !== '' ? (
                    <Grid
                        item
                        xs={12}
                        sx={{
                            pl: 2,
                            pr: 2,
                            pb: 2,
                            textAlign: 'left',
                        }}
                    >
                        <span className="montserrat-medium-shady-lady-9px">
                            {t('label.phone_number')}
                        </span>
                        <br />
                        <span className="montserrat-medium-tuatara-14px">
                            {phoneNumber}
                        </span>
                    </Grid>
                ) : (
                    <div />
                )}
                <Grid
                    item
                    xs={12}
                    sx={{
                        pb: 2,
                    }}
                />
            </Grid>
        </Box>
    );
}

export function CardCheckListBox(props: CardChecklistBoxProps) {
    const { title, checkItems, checkItemWithDesc } = props;

    return (
        <Box className="summary-menu-box">
            <Grid container>
                <Grid item xs={12} sx={{ p: 1, textAlign: 'center' }}>
                    <span className="montserrat-medium-black-9px">{title}</span>
                </Grid>
                {checkItems &&
                    checkItems.map((checkItem: string) => (
                        <Grid
                            key={checkItem}
                            item
                            xs={12}
                            sx={{
                                pl: 2,
                                pr: 2,
                                pt: 2,
                                pb: 1,
                                textAlign: 'left',
                            }}
                        >
                            <div>
                                <span className="warning-dot" />
                                <span className="montserrat-medium-tuatara-14px">
                                    {checkItem}
                                </span>
                            </div>
                        </Grid>
                    ))}

                {checkItemWithDesc &&
                    checkItemWithDesc.map(
                        (data: CheckItemsWithDescriptionProps) => (
                            <Grid
                                key={data.item}
                                item
                                xs={12}
                                sx={{
                                    pl: 2,
                                    pr: 2,
                                    pt: 2,
                                    pb: 1,
                                    textAlign: 'left',
                                }}
                            >
                                <div>
                                    <span className="warning-dot" />
                                    <span className="montserrat-medium-tuatara-14px">
                                        {data.item}
                                    </span>
                                </div>
                                <div
                                    style={{
                                        paddingLeft: '18px',
                                        paddingTop: '5px',
                                    }}
                                >
                                    <span className="montserrat-bold-tuatara-12px">
                                        {data.description}
                                    </span>
                                </div>
                            </Grid>
                        ),
                    )}
                <Grid
                    item
                    xs={12}
                    sx={{
                        pb: 2,
                    }}
                />
            </Grid>
        </Box>
    );
}

export function CardTextBox(props: CardTextProps) {
    const { t } = useTranslation();
    const {
        text,
        boxStyle,
        isScorecard,
        scorecardDetails,
        labelTextStyle,
        scorecardRatingLabel,
    } = props;

    return (
        <Box className={`summary-menu-box ${boxStyle}`}>
            <Grid container>
                <Grid item xs={12} sx={{ p: 1, textAlign: 'center' }}>
                    <span
                        className={`montserrat-bold-white-14px ${labelTextStyle}`}
                    >
                        {text}
                    </span>

                    {scorecardRatingLabel === 'Pass' && (
                        <>
                            <br />
                            <br />
                            <div className="additionalText">
                                {t('text.additionalText.additionalTextTitle')}
                                <ul className="list">
                                    <li
                                        style={{
                                            lineHeight: '15px',
                                            paddingTop: '10px',
                                        }}
                                    >
                                        {t(
                                            'text.additionalText.additionalTextListItem1',
                                        )}
                                    </li>
                                    <li
                                        style={{
                                            lineHeight: '15px',
                                            paddingTop: '10px',
                                        }}
                                    >
                                        {t(
                                            'text.additionalText.additionalTextListItem2',
                                        )}
                                    </li>
                                    <li
                                        style={{
                                            lineHeight: '15px',
                                            paddingTop: '10px',
                                        }}
                                    >
                                        {t(
                                            'text.additionalText.additionalTextListItem3',
                                        )}
                                    </li>
                                    {isScorecard &&
                                        scorecardDetails &&
                                        scorecardDetails.map((data: string) => (
                                            <li
                                                style={{
                                                    lineHeight: '15px',
                                                    paddingTop: '10px',
                                                }}
                                            >
                                                {data}
                                            </li>
                                        ))}
                                </ul>
                            </div>
                        </>
                    )}
                </Grid>
            </Grid>
        </Box>
    );
}

export function CardImageBox(props: CardImageBoxProps) {
    const {
        image1,
        imageDesc1,
        imageIsCheck1,
        image2,
        imageDesc2,
        imageIsCheck2,
        image3,
        imageDesc3,
        imageIsCheck3,
        withBox,
    } = props;

    return (
        <Box className={withBox ? 'summary-menu-box' : ''}>
            <Grid container>
                <Grid item xs={5} sx={{ p: 2 }}>
                    <div className="img-overlap">
                        {image1 ? (
                            <>
                                <div className="img-rectangle" />
                                <div
                                    className={
                                        imageIsCheck1
                                            ? 'img-ellipse-positive'
                                            : 'img-ellipse-negative'
                                    }
                                />
                                <img
                                    className="check"
                                    src={imageIsCheck1 ? check : xcross}
                                />
                                <img className="img-ic" src={image1} />
                            </>
                        ) : (
                            <img className="img-ic" src={noImageCaptured} />
                        )}
                    </div>
                </Grid>
                {imageDesc1 && (
                    <Grid
                        item
                        xs={7}
                        sx={{ textAlign: 'left' }}
                        style={{
                            paddingLeft: '10px',
                            paddingRight: '10px',
                            paddingTop: '10px',
                            alignContent: 'center',
                        }}
                    >
                        <span
                            className="montserrat-medium-tuatara-14px"
                            dangerouslySetInnerHTML={{ __html: imageDesc1 }}
                        />
                    </Grid>
                )}
            </Grid>

            {image2 !== null ? (
                <>
                    <Divider variant="middle" />
                    <Grid container>
                        <Grid item xs={5} sx={{ p: 2 }}>
                            <div className="img-overlap">
                                {image2 ? (
                                    <>
                                        <div className="img-rectangle" />
                                        <div
                                            className={
                                                imageIsCheck2
                                                    ? 'img-ellipse-positive'
                                                    : 'img-ellipse-negative'
                                            }
                                        />
                                        <img
                                            className="check"
                                            src={imageIsCheck2 ? check : xcross}
                                        />
                                        <img className="img-ic" src={image2} />
                                    </>
                                ) : (
                                    <img
                                        className="img-ic"
                                        src={noImageCaptured}
                                    />
                                )}
                            </div>
                        </Grid>
                        {imageDesc2 && (
                            <Grid
                                item
                                xs={7}
                                sx={{ textAlign: 'left' }}
                                style={{
                                    paddingLeft: '10px',
                                    paddingRight: '10px',
                                    paddingTop: '10px',
                                    alignContent: 'center',
                                }}
                            >
                                <span
                                    className="montserrat-medium-tuatara-14px"
                                    dangerouslySetInnerHTML={{
                                        __html: imageDesc2,
                                    }}
                                />
                            </Grid>
                        )}
                    </Grid>
                </>
            ) : (
                <div />
            )}

            <Divider variant="middle" />
            <Grid container>
                <Grid item xs={5} sx={{ p: 2 }}>
                    <div className="img-overlap">
                        {image3 ? (
                            <>
                                <div className="img-rectangle" />
                                <div
                                    className={
                                        imageIsCheck3
                                            ? 'img-ellipse-positive'
                                            : 'img-ellipse-negative'
                                    }
                                />
                                <img
                                    className="check"
                                    src={imageIsCheck3 ? check : xcross}
                                />
                                <img className="img-selfie" src={image3} />
                            </>
                        ) : (
                            <img className="img-selfie" src={noImageCaptured} />
                        )}
                    </div>
                </Grid>
                {imageDesc3 && (
                    <Grid
                        item
                        xs={7}
                        sx={{ textAlign: 'left' }}
                        style={{
                            paddingLeft: '10px',
                            paddingRight: '10px',
                            paddingTop: '10px',
                            alignContent: 'center',
                        }}
                    >
                        <span
                            className="montserrat-medium-tuatara-14px"
                            dangerouslySetInnerHTML={{ __html: imageDesc3 }}
                        />
                    </Grid>
                )}
            </Grid>
        </Box>
    );
}

export function CardDetailBox(props: CardDetailProps) {
    const {
        title,
        idType,
        documentNumber,
        name,
        gender,
        birthDate,
        issuingState,
        address,

        editButton,
        onEditCallback,
        isDataChange,

        editedDocumentNumber,
        editedName,
        editedGender,
        editedBirthDate,
        editedIssuingState,
        editedAddress,

        dataObfuscationConsentFlag,
    } = props;

    const { t } = useTranslation();
    return (
        <Box className="summary-menu-box">
            {dataObfuscationConsentFlag && (
                <Grid item xs={12}>
                    <CardTextBox
                        text={`${t('text.subject_has_opted_not_do_share')}`}
                        labelTextStyle="montserrat-bold-red-orange-14px"
                        boxStyle="card-rex-box"
                        textStyle=""
                    />
                </Grid>
            )}
            <Grid container sx={{ p: 2, textAlign: 'left' }}>
                <Grid item xs={12} sx={{ p: 1, textAlign: 'center' }}>
                    <span className="montserrat-medium-black-9px">{title}</span>
                </Grid>
                <Grid container sx={{ pt: 1 }}>
                    <Grid item xs={5}>
                        <span className="montserrat-medium-tuatara-12px">
                            {t('label.document_number')}
                        </span>
                    </Grid>
                    <Grid item xs={1}>
                        :
                    </Grid>
                    <Grid item xs={6}>
                        <span className="montserrat-medium-tuatara-12px">
                            {editedDocumentNumber || documentNumber}
                            {/* {documentNumber} */}
                        </span>
                        {isDataChange && editedDocumentNumber && (
                            <p className="montserrat-medium-sunset-orange-12px">
                                **{t('label.captured')}: {documentNumber}
                            </p>
                        )}
                    </Grid>
                </Grid>
                <Grid container sx={{ pt: 1 }}>
                    <Grid item xs={5}>
                        <span className="montserrat-medium-tuatara-12px">
                            {t('label.name')}
                        </span>
                    </Grid>
                    <Grid item xs={1}>
                        :
                    </Grid>
                    <Grid item xs={6}>
                        <span className="montserrat-medium-tuatara-12px">
                            {editedName || name}
                        </span>
                        {isDataChange && editedName && (
                            <p className="montserrat-medium-sunset-orange-12px">
                                **{t('label.captured')}: {name}
                            </p>
                        )}
                    </Grid>
                </Grid>
                <Grid container sx={{ pt: 1 }}>
                    <Grid item xs={5}>
                        <span className="montserrat-medium-tuatara-12px">
                            {t('label.gender')}
                        </span>
                    </Grid>
                    <Grid item xs={1}>
                        :
                    </Grid>
                    <Grid item xs={6}>
                        <span className="montserrat-medium-tuatara-12px">
                            {editedGender || gender}
                        </span>
                        {isDataChange && editedGender && (
                            <p className="montserrat-medium-sunset-orange-12px">
                                **{t('label.captured')}: {gender}
                            </p>
                        )}
                    </Grid>
                </Grid>
                <Grid container sx={{ pt: 1 }}>
                    <Grid item xs={5}>
                        <span className="montserrat-medium-tuatara-12px">
                            {t('label.date_of_birth')}
                        </span>
                    </Grid>
                    <Grid item xs={1}>
                        :
                    </Grid>
                    <Grid item xs={6}>
                        <span className="montserrat-medium-tuatara-12px">
                            {editedBirthDate
                                ? checkDateIsValid(
                                      moment(
                                          editedBirthDate,
                                          'DD/MM/YYYY',
                                      ).format('MM/DD/YYYY'),
                                  )
                                : checkDateIsValid(birthDate)}
                        </span>
                        {isDataChange && editedBirthDate && (
                            <p className="montserrat-medium-sunset-orange-12px">
                                **{t('label.captured')}:{' '}
                                {checkDateIsValid(birthDate)}
                            </p>
                        )}
                    </Grid>
                </Grid>
                <Grid container sx={{ pt: 1 }}>
                    <Grid item xs={5}>
                        <span className="montserrat-medium-tuatara-12px">
                            {t('label.issuing_state_name')}
                        </span>
                    </Grid>
                    <Grid item xs={1}>
                        :
                    </Grid>
                    <Grid item xs={6}>
                        <span className="montserrat-medium-tuatara-12px">
                            {editedIssuingState || issuingState}
                        </span>

                        {isDataChange && editedIssuingState && (
                            <p className="montserrat-medium-sunset-orange-12px">
                                **{t('label.captured')}: {issuingState}
                            </p>
                        )}
                    </Grid>
                </Grid>
                {idType === MASTER_MEMBER_CODE.ID_MALAYSIA ||
                idType === 'mykad' ? (
                    <Grid container sx={{ pt: 1 }}>
                        <Grid item xs={5}>
                            <span className="montserrat-medium-tuatara-12px">
                                {t('label.address')}
                            </span>
                        </Grid>
                        <Grid item xs={1}>
                            :
                        </Grid>
                        <Grid item xs={6}>
                            <span className="montserrat-medium-tuatara-12px new-line overFlowWrap">
                                {editedAddress || address}
                            </span>
                            {isDataChange && editedAddress && (
                                <p className="montserrat-medium-sunset-orange-12px new-line overFlowWrap">
                                    **{t('label.captured')}: {address}
                                </p>
                            )}
                        </Grid>
                    </Grid>
                ) : (
                    <div />
                )}

                {editButton && (
                    <Grid
                        item
                        xs={12}
                        sx={{
                            paddingTop: 3,
                            textAlign: 'center',
                        }}
                    >
                        <PrimaryButton
                            isFullWidth
                            buttonName={t('button.edit')}
                            callback={onEditCallback}
                            type="submit"
                        />
                    </Grid>
                )}
            </Grid>
        </Box>
    );
}
export function CardDetailBoxCompleted(props: CardDetailCompletedProps) {
    const {
        title,
        idType,
        documentNumber,
        name,
        gender,
        birthDate,
        issuingState,
        address,
        updatedTime,
        updatedDate,
        ipAddress,

        editButton,
        onEditCallback,
        isDataChange,

        editedDocumentNumber,
        editedName,
        editedGender,
        editedBirthDate,
        editedIssuingState,
        editedAddress,
        dataObfuscationConsentFlag,
    } = props;

    const { t } = useTranslation();

    return (
        <Box className="summary-menu-box">
            {dataObfuscationConsentFlag && (
                <Grid item xs={12}>
                    <CardTextBox
                        text={`${t('text.subject_has_opted_not_do_share')}`}
                        labelTextStyle="montserrat-bold-red-orange-14px"
                        boxStyle="card-rex-box"
                        textStyle=""
                    />
                </Grid>
            )}
            <Grid container sx={{ p: 2, textAlign: 'left' }}>
                <Grid item xs={12} sx={{ p: 1, textAlign: 'center' }}>
                    <span className="montserrat-medium-black-9px">{title}</span>
                </Grid>
                <Grid container sx={{ pt: 1 }}>
                    <Grid item xs={5}>
                        <span className="montserrat-medium-tuatara-12px">
                            {t('label.document_number')}
                        </span>
                    </Grid>
                    <Grid item xs={1}>
                        :
                    </Grid>
                    <Grid item xs={6}>
                        <span className="montserrat-medium-tuatara-12px">
                            {editedDocumentNumber || documentNumber}
                            {/* {documentNumber} */}
                        </span>
                        {isDataChange && editedDocumentNumber && (
                            <p className="montserrat-medium-sunset-orange-12px">
                                **{t('label.captured')}: {documentNumber}
                            </p>
                        )}
                    </Grid>
                </Grid>
                <Grid container sx={{ pt: 1 }}>
                    <Grid item xs={5}>
                        <span className="montserrat-medium-tuatara-12px">
                            {t('label.name')}
                        </span>
                    </Grid>
                    <Grid item xs={1}>
                        :
                    </Grid>
                    <Grid item xs={6}>
                        <span className="montserrat-medium-tuatara-12px">
                            {editedName || name}
                        </span>
                        {isDataChange && editedName && (
                            <p className="montserrat-medium-sunset-orange-12px">
                                **{t('label.captured')}: {name}
                            </p>
                        )}
                    </Grid>
                </Grid>
                <Grid container sx={{ pt: 1 }}>
                    <Grid item xs={5}>
                        <span className="montserrat-medium-tuatara-12px">
                            {t('label.gender')}
                        </span>
                    </Grid>
                    <Grid item xs={1}>
                        :
                    </Grid>
                    <Grid item xs={6}>
                        <span className="montserrat-medium-tuatara-12px">
                            {editedGender || gender}
                        </span>
                        {isDataChange && editedGender && (
                            <p className="montserrat-medium-sunset-orange-12px">
                                **{t('label.captured')}: {gender}
                            </p>
                        )}
                    </Grid>
                </Grid>
                <Grid container sx={{ pt: 1 }}>
                    <Grid item xs={5}>
                        <span className="montserrat-medium-tuatara-12px">
                            {t('label.date_of_birth')}
                        </span>
                    </Grid>
                    <Grid item xs={1}>
                        :
                    </Grid>
                    <Grid item xs={6}>
                        <span className="montserrat-medium-tuatara-12px">
                            {editedBirthDate
                                ? checkDateIsValid(editedBirthDate)
                                : checkDateIsValid(birthDate)}
                        </span>
                        {isDataChange && editedBirthDate && (
                            <p className="montserrat-medium-sunset-orange-12px">
                                **{t('label.captured')}:{' '}
                                {checkDateIsValid(birthDate)}
                            </p>
                        )}
                    </Grid>
                </Grid>
                <Grid container sx={{ pt: 1 }}>
                    <Grid item xs={5}>
                        <span className="montserrat-medium-tuatara-12px">
                            {t('label.issuing_state_name')}
                        </span>
                    </Grid>
                    <Grid item xs={1}>
                        :
                    </Grid>
                    <Grid item xs={6}>
                        <span className="montserrat-medium-tuatara-12px">
                            {editedIssuingState || issuingState}
                        </span>

                        {isDataChange && editedIssuingState && (
                            <p className="montserrat-medium-sunset-orange-12px">
                                **{t('label.captured')}: {issuingState}
                            </p>
                        )}
                    </Grid>
                </Grid>
                {idType === MASTER_MEMBER_CODE.ID_MALAYSIA ||
                idType === 'mykad' ? (
                    <Grid container sx={{ pt: 1 }}>
                        <Grid item xs={5}>
                            <span className="montserrat-medium-tuatara-12px">
                                {t('label.address')}
                            </span>
                        </Grid>
                        <Grid item xs={1}>
                            :
                        </Grid>
                        <Grid item xs={6}>
                            <span className="montserrat-medium-tuatara-12px new-line overFlowWrap">
                                {editedAddress || address}
                            </span>
                            {isDataChange && editedAddress && (
                                <p className="montserrat-medium-sunset-orange-12px new-line overFlowWrap">
                                    **{t('label.captured')}: {address}
                                </p>
                            )}
                        </Grid>
                    </Grid>
                ) : (
                    <div />
                )}
                <Grid container sx={{ pt: 1 }}>
                    <Grid item xs={5}>
                        <span className="montserrat-medium-tuatara-12px">
                            {t('label.updatedDate')}
                        </span>
                    </Grid>
                    <Grid item xs={1}>
                        :
                    </Grid>
                    <Grid item xs={6}>
                        <span className="montserrat-medium-tuatara-12px">
                            {updatedDate}
                        </span>
                    </Grid>
                </Grid>
                <Grid container sx={{ pt: 1 }}>
                    <Grid item xs={5}>
                        <span className="montserrat-medium-tuatara-12px">
                            {t('label.updatedTime')}
                        </span>
                    </Grid>
                    <Grid item xs={1}>
                        :
                    </Grid>
                    <Grid item xs={6}>
                        <span className="montserrat-medium-tuatara-12px">
                            {updatedTime}
                        </span>
                    </Grid>
                </Grid>

                <Grid container sx={{ pt: 1 }}>
                    <Grid item xs={5}>
                        <span className="montserrat-medium-tuatara-12px">
                            {t('label.ipAddress')}
                        </span>
                    </Grid>
                    <Grid item xs={1}>
                        :
                    </Grid>
                    <Grid item xs={6}>
                        <span className="montserrat-medium-tuatara-12px">
                            {ipAddress}
                        </span>
                    </Grid>
                </Grid>

                {editButton && (
                    <Grid
                        item
                        xs={12}
                        sx={{
                            paddingTop: 3,
                            textAlign: 'center',
                        }}
                    >
                        <PrimaryButton
                            isFullWidth
                            buttonName={t('button.edit')}
                            callback={onEditCallback}
                            type="submit"
                        />
                    </Grid>
                )}
            </Grid>
        </Box>
    );
}

export function EditableCardDetailBox(props: {
    title: string;
    register: UseFormRegister<FormValuesTypes>;
    errors: FieldErrors<FormValuesTypes>;
    data: IFulfilment['confirmation'];
}) {
    const { title, register, errors, data } = props;
    const { t } = useTranslation();
    const [value, setValue] = React.useState<Moment | null>(
        moment(data.dateOfBirth),
    );
    const handleChange = (newValue: Moment | null) => {
        setValue(newValue);
    };
    return (
        <Box className="summary-menu-box">
            <Grid container sx={{ p: 2, textAlign: 'left' }}>
                <Grid item xs={12} sx={{ p: 1, textAlign: 'center' }}>
                    <span className="montserrat-medium-black-9px">{title}</span>
                </Grid>
                <Grid container sx={{ pt: 1 }}>
                    <Grid item xs={5}>
                        <span
                            className="montserrat-medium-tuatara-12px"
                            style={{ paddingTop: '7px' }}
                        >
                            {t('label.document_number')}
                        </span>
                    </Grid>
                    <Grid item xs={1} style={{ paddingTop: '8px' }}>
                        :
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            fullWidth
                            size="small"
                            {...register('documentNumber', {
                                required: t('error.this_is_required'),
                            })}
                            defaultValue={data.documentNumber}
                            className="montserrat-medium-outer-space-16px"
                            error={!!errors.documentNumber?.message}
                            helperText={
                                errors ? errors.documentNumber?.message : null
                            }
                        />
                    </Grid>
                </Grid>
                <Grid container sx={{ pt: 1 }}>
                    <Grid item xs={5}>
                        <span
                            className="montserrat-medium-tuatara-12px"
                            style={{ paddingTop: '7px' }}
                        >
                            {t('label.name')}
                        </span>
                    </Grid>
                    <Grid item xs={1} style={{ paddingTop: '8px' }}>
                        :
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            fullWidth
                            size="small"
                            {...register('name', {
                                required: t('error.this_is_required'),
                            })}
                            defaultValue={data.name}
                            className="montserrat-medium-outer-space-16px"
                            error={!!errors.name?.message}
                            helperText={errors ? errors.name?.message : null}
                        />
                    </Grid>
                </Grid>
                <Grid container sx={{ pt: 1 }}>
                    <Grid item xs={5}>
                        <span
                            className="montserrat-medium-tuatara-12px"
                            style={{ paddingTop: '7px' }}
                        >
                            {t('label.gender')}
                        </span>
                    </Grid>
                    <Grid item xs={1} style={{ paddingTop: '8px' }}>
                        :
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            fullWidth
                            size="small"
                            {...register('gender', {
                                required: t('error.this_is_required'),
                            })}
                            defaultValue={data.gender}
                            className="montserrat-medium-outer-space-16px"
                            error={!!errors.gender?.message}
                            helperText={errors ? errors.gender?.message : null}
                        />
                    </Grid>
                </Grid>
                <Grid container sx={{ pt: 1 }}>
                    <Grid item xs={5}>
                        <span
                            className="montserrat-medium-tuatara-12px"
                            style={{ paddingTop: '7px' }}
                        >
                            {t('label.date_of_birth')}
                        </span>
                    </Grid>
                    <Grid item xs={1} style={{ paddingTop: '8px' }}>
                        :
                    </Grid>
                    <Grid item xs={6}>
                        {/* <TextField
                            fullWidth
                            size="small"
                            type="date"
                            {...register('dateOfBirth', {
                                required: t('error.this_is_required'),
                            })}
                            value={value}
                            onChange={(
                                event: React.ChangeEvent<HTMLInputElement>,
                            ) => {
                                console.log(event.target.value);
                                setValue(event.target.value);
                            }}
                            // defaultValue={moment(data.dateOfBirth).format(
                            //     'YYYY-MM-DD',
                            // )}
                            className="montserrat-medium-outer-space-16px"
                            error={!!errors.dateOfBirth?.message}
                            helperText={
                                errors ? errors.dateOfBirth?.message : null
                            }
                        /> */}
                        <LocalizationProvider dateAdapter={AdapterMoment}>
                            <MobileDatePicker
                                inputFormat="DD/MM/YYYY"
                                value={value}
                                onChange={handleChange}
                                renderInput={params => (
                                    <TextField
                                        {...params}
                                        fullWidth
                                        size="small"
                                        {...register('dateOfBirth', {
                                            required: t(
                                                'error.this_is_required',
                                            ),
                                        })}
                                        defaultValue={moment(
                                            data.dateOfBirth,
                                        ).format('DD/MM/YYYY')}
                                        className="montserrat-medium-outer-space-16px"
                                        error={!!errors.dateOfBirth?.message}
                                        helperText={
                                            errors
                                                ? errors.dateOfBirth?.message
                                                : null
                                        }
                                    />
                                )}
                            />
                        </LocalizationProvider>
                    </Grid>
                </Grid>
                <Grid container sx={{ pt: 1 }}>
                    <Grid item xs={5}>
                        <span
                            className="montserrat-medium-tuatara-12px"
                            style={{ paddingTop: '7px' }}
                        >
                            {t('label.issuing_state_name')}
                        </span>
                    </Grid>
                    <Grid item xs={1} style={{ paddingTop: '8px' }}>
                        :
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            fullWidth
                            size="small"
                            {...register('issuingStateName', {
                                required: t('error.this_is_required'),
                            })}
                            defaultValue={data.issuingStateName}
                            className="montserrat-medium-outer-space-16px"
                            error={!!errors.issuingStateName?.message}
                            helperText={
                                errors ? errors.issuingStateName?.message : null
                            }
                        />
                    </Grid>
                </Grid>
                {data.idType === MASTER_MEMBER_CODE.ID_MALAYSIA ||
                    (data.idType === 'mykad' && (
                        <Grid container sx={{ pt: 1 }}>
                            <Grid item xs={5}>
                                <span
                                    className="montserrat-medium-tuatara-12px"
                                    style={{ paddingTop: '7px' }}
                                >
                                    {t('label.address')}
                                </span>
                            </Grid>
                            <Grid item xs={1} style={{ paddingTop: '8px' }}>
                                :
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    fullWidth
                                    size="small"
                                    multiline
                                    rows={3}
                                    {...register('address', {
                                        required: t('error.this_is_required'),
                                    })}
                                    defaultValue={data.address}
                                    className="montserrat-medium-outer-space-16px"
                                    error={!!errors.address?.message}
                                    helperText={
                                        errors ? errors.address?.message : null
                                    }
                                />
                            </Grid>
                        </Grid>
                    ))}
                <Grid
                    item
                    xs={12}
                    sx={{
                        paddingTop: 3,
                        textAlign: 'center',
                    }}
                >
                    <PrimaryButton
                        isFullWidth
                        buttonName={t('button.save_changes')}
                        type="submit"
                    />
                </Grid>
            </Grid>
        </Box>
    );
}

export function CardImageWithoutDescBox(props: CardImageBoxProps) {
    const {
        image1,
        imageIsCheck1,
        image2,
        imageIsCheck2,
        image3,
        imageIsCheck3,
    } = props;

    return (
        <Box className="summary-menu-box">
            <Grid container spacing={1} sx={{ p: 2 }}>
                <Grid
                    item
                    xs={12}
                    sx={{ pt: 2 }}
                    style={{ position: 'relative' }}
                >
                    <img
                        src={image1}
                        alt={image1}
                        className="card-img-wo-desc"
                    />
                    <img
                        className="card-img-icon"
                        src={imageIsCheck1 ? checkSuccess : checkFailed}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Divider variant="middle" />
                </Grid>
                {image2 && (
                    <>
                        <Grid
                            item
                            xs={12}
                            sx={{ pt: 2 }}
                            style={{ position: 'relative' }}
                        >
                            <img
                                src={image2}
                                alt={image2}
                                className="card-img-wo-desc"
                            />
                            <img
                                className="card-img-icon"
                                src={imageIsCheck2 ? checkSuccess : checkFailed}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Divider variant="middle" />
                        </Grid>
                    </>
                )}
                <Grid
                    item
                    xs={12}
                    sx={{ pt: 2 }}
                    style={{ position: 'relative' }}
                >
                    <img
                        src={image3}
                        alt={image3}
                        className="card-img-wo-desc"
                    />
                    <img
                        className="card-img-icon"
                        src={imageIsCheck3 ? checkSuccess : checkFailed}
                    />
                </Grid>
            </Grid>
        </Box>
    );
}

export function CertAuthCardBox(props: CardDetailCertProps) {
    const {
        title,
        idType,
        documentNumber,
        documentNumberEdit,
        name,
        nameEdit,
        gender,
        genderEdit,
        birthDate,
        birthDateEdit,
        issuingState,
        issuingStateNameEdit,
        address,
        addressEdit,
        updatedTime,
        updatedDate,
        ipAddress,
        requesterName,
    } = props;
    const { t } = useTranslation();

    return (
        <Box className="summary-menu-box">
            <Grid container sx={{ p: 2, textAlign: 'left' }}>
                <Grid item xs={12} sx={{ p: 1, textAlign: 'center' }}>
                    <span className="montserrat-medium-black-9px">{title}</span>
                </Grid>
                <Grid container sx={{ pt: 1 }}>
                    <Grid item xs={5}>
                        <span className="montserrat-medium-tuatara-12px">
                            {properties.label.document_number}
                        </span>
                    </Grid>
                    <Grid item xs={1}>
                        :
                    </Grid>
                    <Grid item xs={6}>
                        <span className="montserrat-medium-tuatara-12px">
                            {documentNumberEdit || documentNumber}
                        </span>
                        {documentNumberEdit && (
                            <p className="montserrat-medium-sunset-orange-12px">
                                **{t('label.captured')}: {documentNumber}
                            </p>
                        )}
                    </Grid>
                </Grid>
                <Grid container sx={{ pt: 1 }}>
                    <Grid item xs={5}>
                        <span className="montserrat-medium-tuatara-12px">
                            {properties.label.name}
                        </span>
                    </Grid>
                    <Grid item xs={1}>
                        :
                    </Grid>
                    <Grid item xs={6}>
                        <span className="montserrat-medium-tuatara-12px">
                            {nameEdit || name}
                        </span>
                        {nameEdit && (
                            <p className="montserrat-medium-sunset-orange-12px">
                                **{t('label.captured')}: {name}
                            </p>
                        )}
                    </Grid>
                </Grid>
                <Grid container sx={{ pt: 1 }}>
                    <Grid item xs={5}>
                        <span className="montserrat-medium-tuatara-12px">
                            {properties.label.gender}
                        </span>
                    </Grid>
                    <Grid item xs={1}>
                        :
                    </Grid>
                    <Grid item xs={6}>
                        <span className="montserrat-medium-tuatara-12px">
                            {genderEdit || gender}
                        </span>
                        {genderEdit && (
                            <p className="montserrat-medium-sunset-orange-12px">
                                **{t('label.captured')}: {gender}
                            </p>
                        )}
                    </Grid>
                </Grid>
                <Grid container sx={{ pt: 1 }}>
                    <Grid item xs={5}>
                        <span className="montserrat-medium-tuatara-12px">
                            {properties.label.date_of_birth}
                        </span>
                    </Grid>
                    <Grid item xs={1}>
                        :
                    </Grid>
                    <Grid item xs={6}>
                        <span className="montserrat-medium-tuatara-12px">
                            {checkDateIsValid(birthDateEdit || birthDate)}
                        </span>
                        {birthDateEdit && (
                            <p className="montserrat-medium-sunset-orange-12px">
                                **{t('label.captured')}:{' '}
                                {checkDateIsValid(birthDate)}
                            </p>
                        )}
                    </Grid>
                </Grid>
                <Grid container sx={{ pt: 1 }}>
                    <Grid item xs={5}>
                        <span className="montserrat-medium-tuatara-12px">
                            {properties.label.issuing_state_name}
                        </span>
                    </Grid>
                    <Grid item xs={1}>
                        :
                    </Grid>
                    <Grid item xs={6}>
                        <span className="montserrat-medium-tuatara-12px">
                            {issuingStateNameEdit || issuingState}
                        </span>
                        {issuingStateNameEdit && (
                            <p className="montserrat-medium-sunset-orange-12px">
                                **{t('label.captured')}: {issuingState}
                            </p>
                        )}
                    </Grid>
                </Grid>
                {idType === MASTER_MEMBER_CODE.ID_MALAYSIA ||
                idType === 'mykad' ? (
                    <Grid container sx={{ pt: 1 }}>
                        <Grid item xs={5}>
                            <span className="montserrat-medium-tuatara-12px">
                                {properties.label.address}
                            </span>
                        </Grid>
                        <Grid item xs={1}>
                            :
                        </Grid>
                        <Grid item xs={6}>
                            <span className="montserrat-medium-tuatara-12px new-line overFlowWrap">
                                {addressEdit || address}
                            </span>
                            {addressEdit && (
                                <p className="montserrat-medium-sunset-orange-12px">
                                    **{t('label.captured')}: {address}
                                </p>
                            )}
                        </Grid>
                    </Grid>
                ) : (
                    <div />
                )}
                <Grid container sx={{ pt: 1 }}>
                    <Grid item xs={5}>
                        <span className="montserrat-medium-tuatara-12px">
                            {properties.label.updatedDate}
                        </span>
                    </Grid>
                    <Grid item xs={1}>
                        :
                    </Grid>
                    <Grid item xs={6}>
                        <span className="montserrat-medium-tuatara-12px">
                            {checkDateIsValid(updatedDate)}
                        </span>
                    </Grid>
                </Grid>
                <Grid container sx={{ pt: 1 }}>
                    <Grid item xs={5}>
                        <span className="montserrat-medium-tuatara-12px">
                            {properties.label.updatedTime}
                        </span>
                    </Grid>
                    <Grid item xs={1}>
                        :
                    </Grid>
                    <Grid item xs={6}>
                        <span className="montserrat-medium-tuatara-12px">
                            {updatedTime}
                        </span>
                    </Grid>
                </Grid>

                <Grid container sx={{ pt: 1 }}>
                    <Grid item xs={5}>
                        <span className="montserrat-medium-tuatara-12px">
                            {properties.label.ipAddress}
                        </span>
                    </Grid>
                    <Grid item xs={1}>
                        :
                    </Grid>
                    <Grid item xs={6}>
                        <span className="montserrat-medium-tuatara-12px">
                            {ipAddress}
                        </span>
                    </Grid>
                </Grid>
                <Grid container sx={{ pt: 1 }}>
                    <Grid item xs={5}>
                        <span className="montserrat-medium-tuatara-12px">
                            {t('label.requestedBy')}
                        </span>
                    </Grid>
                    <Grid item xs={1}>
                        :
                    </Grid>
                    <Grid item xs={6}>
                        <span className="montserrat-medium-tuatara-12px">
                            {requesterName}
                        </span>
                    </Grid>
                </Grid>
            </Grid>
        </Box>
    );
}

export function CardImageCertBox(props: CardImageBoxProps) {
    const {
        image1,
        imageDesc1,
        imageIsCheck1,
        image2,
        imageDesc2,
        imageIsCheck2,
        image3,
        imageDesc3,
        imageIsCheck3,
        withBox,
    } = props;

    return (
        <Box className={withBox ? 'summary-menu-box' : ''}>
            <Grid container>
                <Grid item xs={5} sx={{ p: 2 }}>
                    <div className="img-overlap">
                        {image1 ? (
                            <>
                                <div className="img-rectangle" />
                                <div
                                    className={
                                        imageIsCheck1
                                            ? 'img-ellipse-positive'
                                            : 'img-ellipse-negative'
                                    }
                                />
                                <img
                                    className="check"
                                    src={imageIsCheck1 ? check : xcross}
                                />
                                <img className="img-ic" src={image1} />
                            </>
                        ) : (
                            <img className="img-ic" src={noImageCaptured} />
                        )}
                    </div>
                </Grid>
                {imageDesc1 && (
                    <Grid
                        item
                        xs={7}
                        sx={{ pt: 7, textAlign: 'left' }}
                        style={{ paddingLeft: '10px', paddingRight: '10px' }}
                    >
                        <span
                            className="montserrat-medium-tuatara-14px"
                            dangerouslySetInnerHTML={{ __html: imageDesc1 }}
                        />
                    </Grid>
                )}
            </Grid>

            {image2 !== null ? (
                <>
                    <Divider variant="middle" />
                    <Grid container>
                        <Grid item xs={5} sx={{ p: 2 }}>
                            <div className="img-overlap">
                                {image2 ? (
                                    <>
                                        <div className="img-rectangle" />
                                        <div
                                            className={
                                                imageIsCheck2
                                                    ? 'img-ellipse-positive'
                                                    : 'img-ellipse-negative'
                                            }
                                        />
                                        <img
                                            className="check"
                                            src={imageIsCheck2 ? check : xcross}
                                        />
                                        <img className="img-ic" src={image2} />
                                    </>
                                ) : (
                                    <img
                                        className="img-ic"
                                        src={noImageCaptured}
                                    />
                                )}
                            </div>
                        </Grid>
                        {imageDesc2 && (
                            <Grid
                                item
                                xs={7}
                                sx={{ pt: 7, textAlign: 'left' }}
                                style={{
                                    paddingLeft: '10px',
                                    paddingRight: '10px',
                                }}
                            >
                                <span
                                    className="montserrat-medium-tuatara-14px"
                                    dangerouslySetInnerHTML={{
                                        __html: imageDesc2,
                                    }}
                                />
                            </Grid>
                        )}
                    </Grid>
                </>
            ) : (
                <div />
            )}

            <Divider variant="middle" />
            <Grid container>
                <Grid item xs={5} sx={{ p: 2 }}>
                    <div className="img-overlap">
                        {image3 ? (
                            <>
                                <div className="img-rectangle" />
                                <div
                                    className={
                                        imageIsCheck3
                                            ? 'img-ellipse-positive'
                                            : 'img-ellipse-negative'
                                    }
                                />
                                <img
                                    className="check"
                                    src={imageIsCheck3 ? check : xcross}
                                />
                                <img className="img-selfie" src={image3} />
                            </>
                        ) : (
                            <img className="img-selfie" src={noImageCaptured} />
                        )}
                    </div>
                </Grid>
                {imageDesc3 && (
                    <Grid
                        item
                        xs={7}
                        sx={{ pt: 7, textAlign: 'left' }}
                        style={{ paddingLeft: '10px', paddingRight: '10px' }}
                    >
                        <span
                            className="montserrat-medium-tuatara-14px"
                            dangerouslySetInnerHTML={{ __html: imageDesc3 }}
                        />
                    </Grid>
                )}
            </Grid>
        </Box>
    );
}

export function WebScreenCardImageCertBox(props: CardImageBoxProps) {
    const {
        image1,
        imageIsCheck1,
        image2,
        imageIsCheck2,
        image3,
        imageIsCheck3,
    } = props;

    return (
        <Box>
            <Grid container>
                <Grid item xs={12}>
                    <div className="img-cert-overlap">
                        {image1 ? (
                            <>
                                <div className="img-cert-rectangle" />
                                <div
                                    className={
                                        imageIsCheck1
                                            ? 'img-ellipse-positive-cert'
                                            : 'img-ellipse-negative-cert'
                                    }
                                />
                                <img
                                    className="cert-check"
                                    src={imageIsCheck1 ? check : xcross}
                                />
                                <img className="img-cert-ic" src={image1} />
                            </>
                        ) : (
                            <img
                                className="img-cert-ic"
                                src={noImageCaptured}
                            />
                        )}
                    </div>
                </Grid>
            </Grid>

            <Grid container sx={{ pt: 1 }}>
                <Grid item xs={12}>
                    {image2 !== null ? (
                        <Grid container>
                            <Grid item xs={5} sx={{ p: 2 }}>
                                <div className="img-cert-overlap">
                                    {image2 ? (
                                        <>
                                            <div className="img-cert-rectangle" />
                                            <div
                                                className={
                                                    imageIsCheck2
                                                        ? 'img-ellipse-positive-cert'
                                                        : 'img-ellipse-negative-cert'
                                                }
                                            />
                                            <img
                                                className="cert-check"
                                                src={
                                                    imageIsCheck2
                                                        ? check
                                                        : xcross
                                                }
                                            />
                                            <img
                                                className="img-cert-ic"
                                                src={image2}
                                            />
                                        </>
                                    ) : (
                                        <img
                                            className="img-cert-ic"
                                            src={noImageCaptured}
                                        />
                                    )}
                                </div>
                            </Grid>
                        </Grid>
                    ) : (
                        <div />
                    )}
                </Grid>
            </Grid>

            <Grid container sx={{ pt: 1 }}>
                <Grid item xs={12}>
                    <div className="img-cert-overlap">
                        {image3 ? (
                            <>
                                <div className="img-cert-rectangle" />
                                <div
                                    className={
                                        imageIsCheck3
                                            ? 'img-ellipse-positive-cert'
                                            : 'img-ellipse-negative-cert'
                                    }
                                />
                                <img
                                    className="cert-check"
                                    src={imageIsCheck3 ? check : xcross}
                                />
                                <img className="img-cert-selfie" src={image3} />
                            </>
                        ) : (
                            <img
                                className="img-cert-selfie"
                                src={noImageCaptured}
                            />
                        )}
                    </div>
                </Grid>
            </Grid>
        </Box>
    );
}
