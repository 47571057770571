import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import { useForm, Controller } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { PrimaryButton } from '../../../components/Button';
import GraphicType2 from '../../../components/GraphicType2';
import loginGraphic from '../../../assets/img/frame-8@2x.svg';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import '../index.css';
import React, { useEffect, useState } from 'react';
import {
    getLoginResults,
    submitLogin,
} from '../../../store/slices/authentication/loginSlice';
import {
    commonStatusTypes,
    localStorageTypes,
} from '../../../services/constant';
import AlertMessage from '../../../components/Alert';
import Spinner from '../../../components/Spinner';
import { AuthTokens } from '../../../services/interfaces';
import AppBarWithoutMenu from '../../../components/AppBar/AppBarWithoutMenu';
import { AppBarBody } from '../../../components/AppBar/AppBarBody';
import { URLS } from '../../../services/urls';
import { ReqAuthentication } from '../../../services/api/requestBody';
import { FormValuesTypes } from '../../../services/types';
import { resendTokenOtp } from '../../../store/slices/authentication/resendOtpSlice';
import { ILogin } from '../../../services/api/initialStates';
import CustomizedDialogs from '../../../components/CustomizedDialogs';
import { getProfile } from '../../../store/slices/profile/profileSlice';
import { useTranslation } from 'react-i18next';

function LoginOTPPage() {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const [showAlertMessage, setShowAlertMessage] = useState(false);
    const [loading, setLoading] = useState(false);
    const [backdropOpen, setBackdropOpen] = useState(false);
    const DISABLED_TIME = 120; // in seconds
    const LOCAL_STORAGE_KEY = 'otpDisabledUntil';
    const [disabled, setDisabled] = useState(false);
    const [timer, setTimer] = useState(DISABLED_TIME);
    const [clicked, setClicked] = useState(false);
    const [openDialog, setOpenDialog] = useState(false);
    const { t } = useTranslation();

    const email = localStorage.getItem(localStorageTypes.EMAIL) || '';

    const { control, handleSubmit } = useForm<FormValuesTypes>({
        mode: 'onChange',
    });

    const authTokens: AuthTokens = JSON.parse(
        localStorage.getItem(localStorageTypes.AUTH_TOKENS) || '{}',
    );

    useEffect(() => {
        if (authTokens.refreshToken) {
            navigate(URLS.MEMBER.HOME.MAIN);
        }
    }, [authTokens.refreshToken, navigate]);

    const [otp, setOtp] = useState(new Array(4).fill(''));

    const handleOtpChange = (
        event: React.BaseSyntheticEvent,
        index: number,
    ) => {
        const otpValue = event.target.value.replace(/\s/g, '');
        setOtp([...otp.map((d, idx) => (idx === index ? otpValue : d))]);

        const nativeEvent = event.nativeEvent as InputEvent;
        // Focus next input
        if (
            event.target.nextSibling &&
            nativeEvent.inputType === 'insertText' &&
            otpValue !== ''
        ) {
            event.target.nextSibling.focus();
        }

        if (
            event.target.previousSibling &&
            nativeEvent.inputType === 'deleteContentBackward' &&
            otpValue === ''
        ) {
            event.target.previousSibling.focus();
        }
    };

    useEffect(() => {
        const disabledUntil = localStorage.getItem(LOCAL_STORAGE_KEY);
        if (disabledUntil) {
            const now = Date.now() / 1000; // convert to seconds
            const disabledUntilSeconds = parseInt(disabledUntil, 10);
            const remainingSeconds = disabledUntilSeconds - now;
            if (remainingSeconds > 0) {
                setDisabled(true);
                setTimer(remainingSeconds);
                const interval = setInterval(() => {
                    setTimer(prevTimer => {
                        const newTimer = prevTimer - 1;
                        if (newTimer === 0) {
                            clearInterval(interval);
                            setDisabled(false);
                            localStorage.removeItem(LOCAL_STORAGE_KEY);
                        }
                        return newTimer;
                    });
                }, 1000);
            } else {
                localStorage.removeItem(LOCAL_STORAGE_KEY);
            }
        } else {
            localStorage.setItem(
                LOCAL_STORAGE_KEY,
                String(Math.floor(Date.now() / 1000) + DISABLED_TIME),
            );
            setDisabled(true);
            setTimer(DISABLED_TIME);
        }

        const handleBeforeUnload = () => {
            localStorage.removeItem(LOCAL_STORAGE_KEY);
        };

        window.addEventListener('beforeunload', handleBeforeUnload);

        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, []);

    const resendOtpCode = () => {
        if (!disabled && !clicked) {
            localStorage.setItem(
                LOCAL_STORAGE_KEY,
                String(Math.floor(Date.now() / 1000) + DISABLED_TIME),
            );
            setDisabled(true);
            setClicked(true);
            setTimer(DISABLED_TIME);
            const interval = setInterval(() => {
                setTimer(prevTimer => {
                    const newTimer = prevTimer - 1;
                    if (newTimer === 0) {
                        clearInterval(interval);
                        setDisabled(false);
                        localStorage.removeItem(LOCAL_STORAGE_KEY);
                    }
                    return newTimer;
                });
            }, 1000);
            setBackdropOpen(true);
            setLoading(true);

            dispatch(resendTokenOtp(email)).then(res => {
                setLoading(false);
                if (res.meta.requestStatus === 'fulfilled') {
                    localStorage.setItem(
                        localStorageTypes.EMAIL,
                        res.payload.email,
                    );
                }
            });

            setTimeout(() => {
                setBackdropOpen(false);
                setOpenDialog(true);
                localStorage.removeItem(LOCAL_STORAGE_KEY);
            }, 180 * 10 * 1000); // 30 minutes timeout
        }
    };

    useEffect(() => {
        const timerId = setInterval(() => {
            setTimer(prevTimer => {
                const newTimer = prevTimer - 1;
                if (newTimer <= 0) {
                    setDisabled(false);
                    clearInterval(timerId);
                    localStorage.setItem(
                        'expireOtpTimer',
                        Date.now().toString(),
                    );
                }
                return newTimer;
            });
        }, 1000);

        return () => clearInterval(timerId);
    }, [loading]);

    const onSubmit = () => {
        setLoading(true);
        setBackdropOpen(true);

        const dt: ReqAuthentication['oauthToken'] = {
            email,
            otp: otp.join(''),
        };

        dispatch(submitLogin(dt)).then(res => {
            setLoading(false);
            if (res.meta.requestStatus === 'fulfilled') {
                const data: ILogin = res.payload;
                localStorage.setItem(localStorageTypes.USER_AUTH, 'true');
                localStorage.setItem(
                    localStorageTypes.AUTH_TOKENS,
                    JSON.stringify(data),
                );

                if (!data.lastLoginDatetime) {
                    localStorage.setItem(
                        localStorageTypes.FIRST_TIME_LOGIN,
                        'true',
                    );
                }

                dispatch(getProfile(res.payload.userId)).then(resp => {
                    if (resp.meta.requestStatus === 'fulfilled') {
                        if (resp.payload.preferredLanguageCode) {
                            localStorage.setItem(
                                'i18nextLng',
                                resp.payload.preferredLanguageCode.toLowerCase(),
                            );
                            window.location.reload();
                        }
                    }
                });

                navigate(URLS.MEMBER.HOME.MAIN);
            }

            if (
                res.meta.requestStatus === 'rejected' &&
                res.payload.status !== 401
            ) {
                setShowAlertMessage(true);
            }
        });
    };

    const submitResult = useAppSelector(getLoginResults);

    return (
        <>
            {!authTokens.refreshToken && (
                <>
                    {loading ? <Spinner open={backdropOpen} /> : <div />}

                    <AppBarWithoutMenu
                        title={t('app_bar_title.login')}
                        link={URLS.PUBLIC.LOGIN.MAIN}
                    />
                    <AppBarBody />

                    <form onSubmit={handleSubmit(onSubmit)}>
                        <Box sx={{ p: 4, textAlign: 'center' }}>
                            <Grid container spacing={3}>
                                {showAlertMessage &&
                                    submitResult.status ===
                                        commonStatusTypes.FAILED && (
                                        <Grid item xs={12}>
                                            <AlertMessage
                                                showAlert
                                                errorMessage={
                                                    submitResult.errorMsg
                                                }
                                            />
                                        </Grid>
                                    )}
                                <Grid item xs={12}>
                                    <GraphicType2 src={loginGraphic} />
                                    <div
                                        className="montserrat-medium-gunsmoke-12px"
                                        style={{
                                            textAlign: 'left',
                                            paddingTop: '20px',
                                        }}
                                    >
                                        One-Time Password
                                    </div>
                                </Grid>
                                <Grid item xs={12} style={{ display: 'none' }}>
                                    <Controller
                                        name="email"
                                        control={control}
                                        defaultValue={email}
                                        render={() => (
                                            <TextField
                                                fullWidth
                                                id="email"
                                                label="Email Address"
                                                className="montserrat-medium-outer-space-16px"
                                                value={email}
                                                type="hidden"
                                            />
                                        )}
                                        rules={{
                                            required: 'Email required',
                                            pattern: {
                                                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                                message:
                                                    'Invalid email address',
                                            },
                                        }}
                                    />
                                </Grid>
                                <Grid
                                    item
                                    xs={12}
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                    }}
                                >
                                    {otp.map((data, index) => {
                                        return (
                                            <Controller
                                                // eslint-disable-next-line react/no-array-index-key
                                                key={`otp-${index}`}
                                                name="otp"
                                                control={control}
                                                defaultValue=""
                                                render={({
                                                    field: { onChange },
                                                }) => (
                                                    <input
                                                        id={`otp-${index}`}
                                                        className="otp-field montserrat-medium-outer-space-16px"
                                                        type="tel"
                                                        name="otp"
                                                        value={data}
                                                        onChange={e => {
                                                            e.currentTarget.setCustomValidity(
                                                                '',
                                                            );
                                                            handleOtpChange(
                                                                e,
                                                                index,
                                                            );
                                                            onChange(
                                                                e.target.value,
                                                            );
                                                        }}
                                                        onFocus={e =>
                                                            e.target.select()
                                                        }
                                                        inputMode="numeric"
                                                        pattern="[0-9]*"
                                                        maxLength={1}
                                                        onInvalid={e => {
                                                            if (
                                                                e.currentTarget
                                                                    .validity
                                                                    .patternMismatch
                                                            ) {
                                                                e.currentTarget.setCustomValidity(
                                                                    'Numbers only',
                                                                );
                                                            }
                                                        }}
                                                        style={{
                                                            whiteSpace:
                                                                'nowrap',
                                                        }}
                                                        required
                                                    />
                                                )}
                                                rules={{
                                                    required: 'Required',
                                                }}
                                            />
                                        );
                                    })}
                                </Grid>
                                <Grid
                                    item
                                    xs={12}
                                    style={{ textAlign: 'left' }}
                                >
                                    <p className="montserrat-medium-tuatara-12px">
                                        Input the 4-digit code that was sent to
                                        your email address
                                    </p>
                                    <br />
                                    <br />
                                    {openDialog && (
                                        <CustomizedDialogs
                                            open={openDialog}
                                            title="OTP Expired"
                                            body="OTP has expired, please log in again"
                                            primaryButton="OK"
                                            onPrimaryButtonClick={() =>
                                                navigate(URLS.PUBLIC.LOGIN.MAIN)
                                            }
                                            isContainTwoButtons={false}
                                            isContainDialogAction
                                        />
                                    )}
                                    <p className="montserrat-medium-gray-10px">
                                        Haven&rsquo;t received the code yet?
                                    </p>
                                    <br />
                                    {clicked ? (
                                        <p className="montserrat-medium-gray-10px">
                                            Code resent
                                        </p>
                                    ) : (
                                        <span
                                            className="montserrat-medium-gray-10px"
                                            onClick={resendOtpCode}
                                            onKeyDown={event => {
                                                if (event.key === 'Enter') {
                                                    resendOtpCode();
                                                }
                                            }}
                                            role="button"
                                            tabIndex={0}
                                            style={{
                                                cursor: disabled
                                                    ? 'not-allowed'
                                                    : 'pointer',
                                                textDecoration: !disabled
                                                    ? 'underline'
                                                    : 'none',
                                                color: disabled
                                                    ? 'grey'
                                                    : 'blue',
                                            }}
                                        >
                                            {disabled
                                                ? `Resend code in ${timer} seconds`
                                                : 'Click here to resend the code'}
                                        </span>
                                    )}
                                </Grid>
                                <Grid item xs={12}>
                                    <PrimaryButton
                                        isFullWidth
                                        buttonName={`${t('button.login_now')}!`}
                                        disabled={false}
                                        type="submit"
                                    />
                                </Grid>
                                <Grid item xs={12} />
                            </Grid>
                        </Box>
                    </form>
                </>
            )}
            <div />
        </>
    );
}

export default LoginOTPPage;
