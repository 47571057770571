import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import { useForm, Controller } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { PrimaryButton, SecondaryButton } from '../../../components/Button';
import AlertMessage from '../../../components/Alert';
import GraphicType2 from '../../../components/GraphicType2';
import loginGraphic from '../../../assets/img/frame-8@2x.svg';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import {
    getTokenOtp,
    getTokenOtpResults,
} from '../../../store/slices/authentication/otpSlice';
import {
    commonStatusTypes,
    ERROR_CODE,
    localStorageTypes,
} from '../../../services/constant';
import './LoginPage.css';
import { useEffect, useState } from 'react';
import Spinner from '../../../components/Spinner';
import { AuthTokens } from '../../../services/interfaces';
import AppBarWithoutMenu from '../../../components/AppBar/AppBarWithoutMenu';
import { AppBarBody } from '../../../components/AppBar/AppBarBody';
import {
    resendVerificationEmail,
    resendVerificationEmailResult,
} from '../../../store/slices/register/resendVerificationEmailSlice';
import { URLS } from '../../../services/urls';
import { FormValuesTypes } from '../../../services/types';
import { ReqRegister } from '../../../services/api/requestBody';
import { useTranslation } from 'react-i18next';

function LoginEmailPage() {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const submitResult = useAppSelector(getTokenOtpResults);
    const [loading, setLoading] = useState(false);
    const [backdropOpen, setBackdropOpen] = useState(false);
    const [errorCode, setErrorCode] = useState('');
    const [alertMessage, setAlertMessage] = useState('');
    const { t } = useTranslation();

    const { control, handleSubmit, getValues } = useForm<FormValuesTypes>({
        mode: 'onChange',
    });

    const authTokens: AuthTokens = JSON.parse(
        localStorage.getItem(localStorageTypes.AUTH_TOKENS) || '{}',
    );

    useEffect(() => {
        if (authTokens.refreshToken) {
            navigate(URLS.MEMBER.HOME.MAIN);
        }
    });

    const onSubmit = (
        data: FormValuesTypes,
        // event: React.BaseSyntheticEvent,
    ) => {
        // event.preventDefault();
        // event.stopPropagation();

        setBackdropOpen(true);
        setLoading(true);

        dispatch(getTokenOtp(data)).then(res => {
            setLoading(false);
            if (res.meta.requestStatus === 'fulfilled') {
                localStorage.setItem(
                    localStorageTypes.EMAIL,
                    res.payload.email,
                );
                navigate(URLS.PUBLIC.LOGIN.OTP);
            }

            if (
                res.meta.requestStatus === 'rejected' &&
                res.payload.status !== 401
            ) {
                setErrorCode(res.payload.code);
                setAlertMessage(res.payload.message);
            }
        });
    };

    const onClickResendButton = () => {
        const email = getValues('email');
        const reqBody: ReqRegister['resendVerificationEmail'] = {
            email,
            verificationCode: null,
        };
        dispatch(resendVerificationEmail(reqBody)).then(res => {
            if (res.meta.requestStatus === 'fulfilled') {
                window.location.reload();
                setAlertMessage(res.payload);
            }

            if (
                res.meta.requestStatus === 'rejected' &&
                res.payload.status !== 401
            ) {
                setErrorCode(res.payload.code);
                setAlertMessage(res.payload.message);
            }
        });
    };

    const getResendEmailResult = useAppSelector(resendVerificationEmailResult);

    return (
        <>
            {!authTokens.refreshToken && (
                <>
                    {loading ? <Spinner open={backdropOpen} /> : <div />}

                    <AppBarWithoutMenu
                        title={t('app_bar_title.login')}
                        link="/"
                    />
                    <AppBarBody />

                    <Box sx={{ p: 4, textAlign: 'center' }}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <form onSubmit={handleSubmit(onSubmit)}>
                                    <Grid container spacing={3}>
                                        <Grid item xs={12}>
                                            {(submitResult.status ===
                                                commonStatusTypes.FAILED ||
                                                getResendEmailResult.response ===
                                                    t('text.success') ||
                                                getResendEmailResult.status ===
                                                    commonStatusTypes.FAILED) && (
                                                <AlertMessage
                                                    showAlert
                                                    severity={
                                                        getResendEmailResult.response ===
                                                        t('text.success')
                                                            ? 'success'
                                                            : 'error'
                                                    }
                                                >
                                                    <p>
                                                        {alertMessage}{' '}
                                                        {errorCode ===
                                                        ERROR_CODE.EMAIL_NOT_FOUND ? (
                                                            <a
                                                                href={
                                                                    URLS.PUBLIC
                                                                        .REGISTER
                                                                        .MAIN
                                                                }
                                                            >
                                                                [
                                                                {t(
                                                                    'text.proceed_to_registration',
                                                                )}
                                                                ?]
                                                            </a>
                                                        ) : (
                                                            ''
                                                        )}
                                                    </p>
                                                </AlertMessage>
                                            )}

                                            {/* {getResendEmailResult.status ===
                            commonStatusTypes.FAILED &&
                            errorCode ===
                                ERROR_CODE.EMAIL_NOT_FOUND && (
                                <AlertMessage showAlert>
                                    <p>
                                        {submitResult.errorMsg}{' '}
                                        <a href="/vm/register">
                                            [
                                            {
                                                t(text_proceed_to_registration
                                            }
                                            ?]
                                        </a>
                                    </p>
                                </AlertMessage>
                            )} */}
                                            <GraphicType2 src={loginGraphic} />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Controller
                                                name="email"
                                                control={control}
                                                defaultValue=""
                                                render={({
                                                    field: { onChange, value },
                                                    fieldState: { error },
                                                }) => (
                                                    <TextField
                                                        fullWidth
                                                        id="email"
                                                        type="email"
                                                        label="Email Address"
                                                        className="montserrat-medium-outer-space-16px"
                                                        value={value}
                                                        onChange={onChange}
                                                        error={!!error}
                                                        helperText={
                                                            error
                                                                ? error.message
                                                                : null
                                                        }
                                                    />
                                                )}
                                                rules={{
                                                    required: 'Email required',
                                                    pattern: {
                                                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                                        message: t(
                                                            'error.invalid_email',
                                                        ),
                                                    },
                                                }}
                                            />
                                        </Grid>
                                        <Grid
                                            item
                                            xs={12}
                                            style={{ textAlign: 'left' }}
                                        >
                                            <p className="montserrat-medium-tuatara-12px">
                                                {t('text.OTP_email')}
                                            </p>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <PrimaryButton
                                                isFullWidth
                                                buttonName={t('button.next')}
                                                disabled={false}
                                                type="submit"
                                            />
                                        </Grid>
                                    </Grid>
                                </form>
                            </Grid>
                            {(errorCode === ERROR_CODE.ACCOUNT_NOT_VERIFIED ||
                                errorCode ===
                                    ERROR_CODE.LOGIN_VERIFICATION_LINK_EXPIRED) && (
                                <Grid item xs={12}>
                                    <Grid item xs={12}>
                                        <SecondaryButton
                                            isFullWidth
                                            buttonName={t('button.resend')}
                                            callback={onClickResendButton}
                                            disabled={false}
                                            type="button"
                                        />
                                    </Grid>
                                    <Grid item xs={12} />
                                </Grid>
                            )}
                        </Grid>
                    </Box>
                </>
            )}
            <div />
        </>
    );
}

export default LoginEmailPage;
