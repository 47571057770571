import { combineReducers } from '@reduxjs/toolkit';
import parameterSlice from './parameterSlice';
import registerSlice from './registerSlice';
import verifyRegisterUserSlice from './verifyRegisterUserSlice';
import resendVerificationEmailSlice from './resendVerificationEmailSlice';

export default combineReducers({
    parameter: parameterSlice,
    register: registerSlice,
    verifyRegister: verifyRegisterUserSlice,
    resend: resendVerificationEmailSlice,
});
