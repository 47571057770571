/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { axiosPrivate } from '../../../services/api/apiCommonConfig';
import { VaazException } from '../../../services/api/initialStates';
import { commonStatusTypes } from '../../../services/constant';
import { API } from '../../api.v1';
import { RootState } from '../../store';

export const getProfile = createAsyncThunk(
    '/profile/get',
    async (userId: string, { rejectWithValue }) => {
        try {
            const { data } = await axiosPrivate.get(
                `${API.PROFILE.PROFILES}${userId}`,
            );
            return data;
        } catch (error: unknown) {
            const err = error as VaazException;
            return rejectWithValue(err.response.data);
        }
    },
);

const profileSlice = createSlice({
    name: '/profile/get',
    initialState: {
        list: {
            userId: '',
            fullName: '',
            email: '',
            phoneNumber: '',
            countryCode: '',
            countryName: '',
            status: '',
            selfVerifyStatusCode: '',
            selfVerifyStatusDesc: '',
            preferredLanguageCode: '',
            preferredLanguageDesc: '',
            companyName: '',
            companyAddress: '',
            companyTelNo: '',
            selfVerificationRequestId: '',
            showRemoveButtonFlag: false,
            resultPrice: 0,
        },
        loading: false,
        errorMsg: '',
        status: commonStatusTypes.IDLE,
    },
    reducers: {},
    extraReducers: builder => {
        builder
            .addCase(getProfile.pending, state => {
                state.loading = true;
                state.status = commonStatusTypes.IDLE;
            })
            .addCase(getProfile.rejected, (state, action) => {
                state.loading = false;
                state.status = commonStatusTypes.FAILED;

                const { payload } = action;
                const errMsg = payload as VaazException['response']['data'];
                const error = errMsg || action.error;
                state.errorMsg = error.message || '';
            })
            .addCase(getProfile.fulfilled, (state, action) => {
                state.status = 'idle';
                state.list = action.payload;
            });
    },
});

export const getProfileResults = (state: RootState) => state.profile.profile;

export default profileSlice.reducer;
