/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { axiosPrivate } from '../../../services/api/apiCommonConfig';
import { VaazException } from '../../../services/api/initialStates';
import { commonStatusTypes } from '../../../services/constant';
import { API } from '../../api.v1';
import { RootState } from '../../store';

export const getCreditsParameter = createAsyncThunk(
    '/credit/parameters/get',
    async (_, { rejectWithValue }) => {
        try {
            const { data } = await axiosPrivate.get(API.VAAZ_CREDIT.PARAMETERS);
            return data;
        } catch (error: unknown) {
            const err = error as VaazException;
            return rejectWithValue(err.response.data);
        }
    },
);

const getCreditsParameterSlice = createSlice({
    name: '/credit/parameters/get',
    initialState: {
        response: {
            parameters: [],
        },
        loading: false,
        errorMessage: '',
        status: commonStatusTypes.IDLE,
    },
    reducers: {},
    extraReducers: builder => {
        builder
            .addCase(getCreditsParameter.pending, state => {
                state.loading = true;
                state.errorMessage = '';
                state.status = commonStatusTypes.IDLE;
            })
            .addCase(getCreditsParameter.rejected, (state, action) => {
                state.loading = false;
                state.status = commonStatusTypes.FAILED;

                const { payload } = action;
                const errMsg = payload as VaazException['response']['data'];
                const error = errMsg || action.error;
                state.errorMessage = error.message || '';
            })
            .addCase(getCreditsParameter.fulfilled, (state, action) => {
                state.loading = false;
                state.status = commonStatusTypes.SUCCESS;
                state.errorMessage = '';
                state.response = action.payload;
            });
    },
});

export const getCreditsParameterResults = (state: RootState) =>
    state.credit.parameters;

export default getCreditsParameterSlice.reducer;
