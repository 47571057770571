import { Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import './Button.css';
import LockOutlined from '@mui/icons-material/LockOutlined';
import { ButtonProps } from '../../services/interfaces';
import { localStorageTypes } from '../../services/constant';

export function PrimaryButton(props: ButtonProps) {
    const {
        isFullWidth,
        buttonName,
        link,
        callback,
        disabled,
        type,
        customStyle,
        buttonStyle,
    } = props;
    const navigate = useNavigate();

    const handleClick = () => {
        if (link) {
            navigate(link);
        } else {
            callback?.();
        }
    };

    return (
        <Button
            variant="contained"
            fullWidth={isFullWidth}
            className={`primary-button ${customStyle}`}
            onClick={handleClick}
            disabled={disabled}
            type={type}
            style={buttonStyle}
        >
            {buttonName}
        </Button>
    );
}

export function CertPrimaryButton(props: ButtonProps) {
    const { isFullWidth, buttonName, link, callback, disabled, type } = props;
    const navigate = useNavigate();

    const handleClick = () => {
        if (link) {
            navigate(link);
        } else {
            callback?.();
        }
    };

    return (
        <Button
            className="raisedRounded"
            variant="contained"
            fullWidth={isFullWidth}
            onClick={handleClick}
            disabled={disabled}
            type={type}
        >
            {buttonName}
        </Button>
    );
}

export function RemoveButton(props: ButtonProps) {
    const { isFullWidth, buttonName, link, callback, disabled, type } = props;
    const navigate = useNavigate();

    const handleClick = () => {
        if (link) {
            navigate(link);
        } else {
            callback?.();
        }
    };

    return (
        <Button
            variant="contained"
            fullWidth={isFullWidth}
            className="close-button"
            onClick={handleClick}
            disabled={disabled}
            type={type}
        >
            {buttonName}
        </Button>
    );
}

export function PositiveButton(props: ButtonProps) {
    const {
        isFullWidth,
        buttonName,
        link,
        callback,
        disabled,
        type,
        customStyle,
    } = props;
    const navigate = useNavigate();

    const handleClick = () => {
        if (link) {
            navigate(link);
        } else {
            callback?.();
        }
    };

    return (
        <Button
            variant="contained"
            fullWidth={isFullWidth}
            className={`positive-button ${customStyle}`}
            onClick={() => {
                handleClick();
            }}
            disabled={disabled}
            type={type}
        >
            {buttonName}
        </Button>
    );
}

export function NegativeButton(props: ButtonProps) {
    const {
        isFullWidth,
        buttonName,
        link,
        callback,
        disabled,
        type,
        customStyle,
    } = props;
    const navigate = useNavigate();

    const handleClick = () => {
        if (link) {
            navigate(link);
        } else {
            callback?.();
        }
    };

    return (
        <Button
            variant="contained"
            fullWidth={isFullWidth}
            className={`negative-button ${customStyle}`}
            onClick={() => {
                handleClick();
            }}
            disabled={disabled}
            type={type}
        >
            {buttonName}
        </Button>
    );
}

export function SecondaryButton(props: ButtonProps) {
    const { isFullWidth, buttonName, link, callback, disabled, type } = props;
    const navigate = useNavigate();

    return (
        <Button
            variant="contained"
            fullWidth={isFullWidth}
            className="secondary-button"
            onClick={() => (link ? navigate(link) : callback?.())}
            disabled={disabled}
            type={type}
        >
            {buttonName}
        </Button>
    );
}

export function ViewAllButton(props: ButtonProps) {
    const {
        isFullWidth,
        buttonName,
        link,
        callback,
        disabled,
        type,
        endIcon,
        isSelfVerified,
    } = props;
    const navigate = useNavigate();

    return (
        <Button
            variant="contained"
            fullWidth={isFullWidth}
            className="view-all-button"
            onClick={() => {
                // store the isSelfVerified status in localStorage
                // so that it can be used to determined at the history page
                localStorage.setItem(
                    localStorageTypes.IS_SELF_VERIFIED,
                    String(isSelfVerified),
                );
                if (link) {
                    navigate(link);
                } else {
                    callback?.();
                }
            }}
            disabled={disabled}
            type={type}
            endIcon={endIcon ?? null}
        >
            {buttonName}
        </Button>
    );
}

export function NegativeBorderButton(props: ButtonProps) {
    const { isFullWidth, buttonName, link, callback, disabled, type } = props;
    const navigate = useNavigate();

    const handleClick = () => {
        if (link) {
            navigate(link);
        } else {
            callback?.();
        }
    };

    return (
        <Button
            variant="contained"
            fullWidth={isFullWidth}
            className="negative-border-button"
            onClick={() => {
                handleClick();
            }}
            disabled={disabled}
            type={type}
        >
            {buttonName}
        </Button>
    );
}

export function ButtonWithImage(props: ButtonProps) {
    const { buttonName, disabled } = props;

    return (
        <Button
            style={{
                textTransform: 'none',
                textAlign: 'left',
                backgroundColor: 'var(--mercury-2)',
                color: 'var(--black)',
            }}
            disabled={disabled}
            variant="outlined"
            startIcon={<LockOutlined style={{ width: '20px' }} />}
        >
            <p
                className="montserrat-medium-black-8px"
                style={{ letterSpacing: 0 }}
            >
                {buttonName}
            </p>
        </Button>
    );
}

export function CustomizedColorButton(props: ButtonProps) {
    const {
        isFullWidth,
        buttonName,
        link,
        callback,
        disabled,
        type,
        bgColor,
        borderColor,
        textColor,
        variant,
    } = props;

    const navigate = useNavigate();

    const handleClick = () => {
        if (link) {
            navigate(link);
        } else {
            callback?.();
        }
    };

    return (
        <Button
            variant={variant}
            fullWidth={isFullWidth}
            className="cutomized-button"
            onClick={handleClick}
            disabled={disabled}
            type={type}
            style={{
                backgroundColor: bgColor,
                border: borderColor,
                color: textColor,
            }}
        >
            {buttonName}
        </Button>
    );
}
