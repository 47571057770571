import moment from 'moment';
/* Pagination */
export const commonPagination = {
    page: 0,
    size: 20,
    sort: '',
};

/* Common Usage Value */
export const commonStatusTypes = {
    IDLE: 'idle',
    LOADING: 'loading',
    SUCCESS: 'success',
    FAILED: 'failed',
};

/* local storage value */
export const localStorageTypes = {
    CODE: 'code',
    EMAIL: 'email',
    AUTH_TOKENS: 'authTokens',
    USER_AUTH: 'userAuth',
    PREV_URL: 'prevUrl',
    FULFILMENT_IMAGE: 'fulfilmentImage',
    FULFILMENT_ID_TYPE: 'fulfilmentIdType',
    FULFILMENT_REQUEST_ID: 'fulfilmentRequestId',
    IS_FROM_REQUEST: 'isFromRequest',
    NON_MEMBER: 'nonMember',
    REQUEST_CHECK_ITEMS: 'requestCheckItems',
    REQUEST_INPUT_DETAILS: 'requestInputDetails',
    REQUEST_TNC_PDPA: 'requestTnCPdpa',
    FIRST_TIME_LOGIN: 'firstTimeLogin',
    VERIFICATION_REQUEST_CHECK_ITEMS: 'verificationRequestCheckItems',
    SELF_VERIFICATION: 'selfVerification',
    IS_SELF_VERIFIED: 'isSelfVerified',
    SELF_VERIFICATION_STATUS: 'selfVerificationStatus',
    IS_USE_SELF_VERIFICATION: 'isUseSelfVerification',
};

/* common fields id */
export const commonFieldsId = {
    NAME: 'name',
    EMAIL: 'email',
    PHONE_NUMBER: 'phoneNumber',
    COUNTRY: 'country',
    PDPA: 'pdpa',
    TERMS: 'terms',
    MARKETING_MATERIALS: 'marketingMaterials',
    ID_TYPE: 'idType',
    NUMBER_OF_CREDITS: 'numberOfCredits',
    PAYMENT_METHOD: 'paymentMethod',
};

export const MASTER_MEMBER_DESCRIPTION = {
    REQUEST_ACCEPTED: 'REQUEST_ACCEPTED',
    IN_PROGRESS: 'IN_PROGRESS',
    COMPLETED: 'COMPLETED',
    NOT_STARTED: 'NOT_STARTED',
    CANCELLED: 'CANCELLED',
};

export const MASTER_CODE = {
    COUNTRY: 'COUNTRY',
    VAAZME_CHECK_ITEMS: 'VAAZME_CHECK_ITEMS',
    PAYMENT_METHOD: 'PAYMENT_METHOD',
    LANGUAGE_TYPE: 'LANGUAGE_TYPE',
};

export const MASTER_MEMBER_CODE = {
    ACTIVE: 'ACTIVE',
    FULFIL_MARKETING: 'FULFIL_MARKETING',
    FULFIL_PDPA: 'FULFIL_PDPA',
    FULFIL_TERMS_CONDITIONS: 'FULFIL_TERMS_CONDITIONS',
    FULFIL_IDV: 'FULFIL_IDV',
    FULFIL_DATA_OBFUSCATION: 'FULFIL_DATA_OBFUSCATION',
    USER_TERMS_CONDITION: 'USER_TERMS_CONDITIONS',
    USER_PDPA: 'USER_PDPA',
    USER_MARKETING: 'USER_MARKETING',
    REQUEST_SELF_VERIFICATION: 'REQUEST_SELF_VERIFICATION',
    REQUEST_CONSENT: 'REQUEST_CONSENT',
    REQUEST_PDPA: 'REQUEST_PDPA',
    REQUEST_TERMS_CONDITIONS: 'REQUEST_TERMS_CONDITIONS',
    REQUEST_ACCEPTED: 'REQUEST_ACCEPTED',
    IN_PROGRESS: 'IN_PROGRESS',
    COMPLETED: 'COMPLETED',
    NOT_STARTED: 'NOT_STARTED',
    CANCELLED: 'CANCELLED',
    MSG_REQUEST: 'MSG_REQUEST',
    MSG_FULFILMENT: 'MSG_FULFILMENT',
    UNREAD: 'UNREAD',
    READ: 'READ',
    USER_PENDING_VERIFICATION: 'USER_PENDING_VERIFICATION',
    ID_MALAYSIA: 'ID_MALAYSIA',
    ID_PASSPORT: 'ID_PASSPORT',
    VC_SUCCESS: 'VC_SUCCESS',
    DEDUCT: 'DEDUCT',
    VISA: 'VISA',
    GRAB_PAY: 'GRAB_PAY',
    TNG: 'TNG',
    BOOST: 'BOOST',
    CIMB_PAY: 'CIMB_PAY',
    MAE: 'MAE',
    MYS: 'MYS',
    KHM: 'KHM',
    SGP: 'SGP',
    EN: 'EN',
    MS: 'MS',
    ZH: 'ZH',
    SHARE_RESULTS: 'SHARE_RESULTS',
    ADMIN_DEDUCT: 'ADMIN_DEDUCT',
};

export const ERROR_CODE = {
    ACCOUNT_NOT_VERIFIED: 'otp.error.2',
    LOGIN_VERIFICATION_LINK_EXPIRED: 'otp.error.3',
    EMAIL_NOT_FOUND: 'registration.email.err.2',
    REGISTER_VERIFICATION_LINK_EXPIRED:
        'registration.verification.expired.err.1',
    EMAIL_ALREADY_EXIST: 'registration.email.err.3',
    AVAILABLE: 'AVAILABLE',
    UNAVAILABLE: 'UNAVAILABLE',
};

export const CHECK_ITEMS_TOOLTIPS = {
    IDV: 'Use this to verify that the Subject has legitimate identity documents (e.g. NRIC, Passport).',
};

export const MOBILE_OS = [
    {
        Name: 'Chrome',
        AnKey: 'Chrome',
        AKey: 'CriOS',
        Expected: 55,
    },
    {
        Name: 'Firefox',
        AnKey: 'Firefox',
        AKey: 'FxiOS',
        Expected: 15,
    },
    {
        Name: 'Opera',
        AnKey: 'Version',
        AKey: 'Version',
        Expected: 42,
    },
    {
        Name: 'Safari',
        AnKey: 'Version',
        AKey: 'Version',
        Expected: 11,
    },
    {
        Name: 'Samsung',
        AnKey: '',
        AKey: '',
        Expected: 6.0,
    },
    {
        Name: 'WebView',
        AnKey: '',
        AKey: '',
        Expected: 55,
    },
];
export const WEB_OS = [
    {
        Name: 'Chrome',
        Key: 'Chrome',
        Expected: 55,
    },
    {
        Name: 'Edge',
        Key: 'Edg',
        Expected: 12,
    },
    {
        Name: 'Firefox',
        Key: 'Firefox',
        Expected: 15,
    },
    {
        Name: 'Safari',
        Key: 'Safari',
        Expected: 11,
    },
    {
        Name: 'Opera',
        Key: 'OPR',
        Expected: 42,
    },
];

export const BASE64_COMMON_STRING_PNG = 'data:image/png;base64,';
export const BASE64_COMMON_STRING = 'data:image/jpg;base64,';
export const MALAYSIA_PHONE_CODES = '(+60) ';
export const TIMER_MILLISECONDS = 10000;

export function customFormatDate(
    dateTime: any,
    formatDate: any,
    isMalay: any,
    upperCase: boolean,
) {
    const formatString = formatDate;
    const formatted = moment(dateTime)
        .locale(isMalay ? 'ms' : 'en')
        .format(formatString);

    if (isMalay) {
        return formatted
            .replace('pagi', upperCase ? 'AM' : 'am')
            .replace('petang', upperCase ? 'PM' : 'pm')
            .replace('tengahari', upperCase ? 'PM' : 'pm')
            .replace('malam', upperCase ? 'PM' : 'pm');
    }

    return formatted;
}

export const countryCodes: Record<string, string> = {
    Malaysia: '60',
    Afghanistan: '93',
    Albania: '355',
    Algeria: '213',
    'American Samoa': '1-684',
    Andorra: '376',
    Angola: '244',
    Anguilla: '1-264',
    Antarctica: '672',
    'Antigua and Barbuda': '1-268',
    Argentina: '54',
    Armenia: '374',
    Aruba: '297',
    Australia: '61',
    Austria: '43',
    Azerbaijan: '994',
    Bahamas: '1-242',
    Bahrain: '973',
    Bangladesh: '880',
    Barbados: '1-246',
    Belarus: '375',
    Belgium: '32',
    Belize: '501',
    Benin: '229',
    Bermuda: '1-441',
    Bhutan: '975',
    Bolivia: '591',
    'Bosnia and Herzegovina': '387',
    Botswana: '267',
    Brazil: '55',
    'British Indian Ocean Territory': '246',
    'British Virgin Islands': '1-284',
    Brunei: '673',
    Bulgaria: '359',
    'Burkina Faso': '226',
    Burundi: '257',
    Cambodia: '855',
    Cameroon: '237',
    Canada: '1',
    'Cape Verde': '238',
    'Cayman Islands': '1-345',
    'Central African Republic': '236',
    Chad: '235',
    Chile: '56',
    China: '86',
    'Christmas Island': '61',
    'Cocos Islands': '61',
    Colombia: '57',
    Comoros: '269',
    'Cook Islands': '682',
    'Costa Rica': '506',
    Croatia: '385',
    Cuba: '53',
    Curacao: '599',
    Cyprus: '357',
    'Czech Republic': '420',
    'Democratic Republic of the Congo': '243',
    Denmark: '45',
    Djibouti: '253',
    Dominica: '1-767',
    'Dominican Republic': '1-8',
    'East Timor': '670',
    Ecuador: '593',
    Egypt: '20',
    'El Salvador': '503',
    'Equatorial Guinea': '240',
    Eritrea: '291',
    Estonia: '372',
    Ethiopia: '251',
    'Falkland Islands': '500',
    'Faroe Islands': '298',
    Fiji: '679',
    Finland: '358',
    France: '33',
    'French Polynesia': '689',
    Gabon: '241',
    Gambia: '220',
    Georgia: '995',
    Germany: '49',
    Ghana: '233',
    Gibraltar: '350',
    Greece: '30',
    Greenland: '299',
    Grenada: '1-473',
    Guam: '1-671',
    Guatemala: '502',
    Guernsey: '44-1481',
    Guinea: '224',
    'Guinea-Bissau': '245',
    Guyana: '592',
    Haiti: '509',
    Honduras: '504',
    'Hong Kong': '852',
    Hungary: '36',
    Iceland: '354',
    India: '91',
    Indonesia: '62',
    Iran: '98',
    Iraq: '964',
    Ireland: '353',
    'Isle of Man': '44-1624',
    Israel: '972',
    Italy: '39',
    'Ivory Coast': '225',
    Jamaica: '1-876',
    Japan: '81',
    Jersey: '44-1534',
    Jordan: '962',
    Kazakhstan: '7',
    Kenya: '254',
    Kiribati: '686',
    Kosovo: '383',
    Kuwait: '965',
    Kyrgyzstan: '996',
    Laos: '856',
    Latvia: '371',
    Lebanon: '961',
    Lesotho: '266',
    Liberia: '231',
    Libya: '218',
    Liechtenstein: '423',
    Lithuania: '370',
    Luxembourg: '352',
    Macau: '853',
    Macedonia: '389',
    Madagascar: '261',
    Malawi: '265',
    Maldives: '960',
    Mali: '223',
    Malta: '356',
    'Marshall Islands': '692',
    Mauritania: '222',
    Mauritius: '230',
    Mayotte: '262',
    Mexico: '52',
    Micronesia: '691',
    Moldova: '373',
    Monaco: '377',
    Mongolia: '976',
    Montenegro: '382',
    Montserrat: '1-664',
    Morocco: '212',
    Mozambique: '258',
    Myanmar: '95',
    Namibia: '264',
    Nauru: '674',
    Nepal: '977',
    Netherlands: '31',
    'Netherlands Antilles': '599',
    'New Caledonia': '687',
    'New Zealand': '64',
    Nicaragua: '505',
    Niger: '227',
    Nigeria: '234',
    Niue: '683',
    'North Korea': '850',
    'Northern Mariana Islands': '1-670',
    Norway: '47',
    Oman: '968',
    Pakistan: '92',
    Palau: '680',
    Palestine: '970',
    Panama: '507',
    'Papua New Guinea': '675',
    Paraguay: '595',
    Peru: '51',
    Philippines: '63',
    Pitcairn: '64',
    Poland: '48',
    Portugal: '351',
    'Puerto Rico': '1-787, 1-939',
    Qatar: '974',
    'Republic of the Congo': '242',
    Reunion: '262',
    Romania: '40',
    Russia: '7',
    Rwanda: '250',
    'Saint Barthelemy': '590',
    'Saint Helena': '290',
    'Saint Kitts and Nevis': '1-869',
    'Saint Lucia': '1-758',
    'Saint Martin': '590',
    'Saint Pierre and Miquelon': '508',
    'Saint Vincent and the Grenadines': '1-784',
    Samoa: '685',
    'San Marino': '378',
    'Sao Tome and Principe': '239',
    'Saudi Arabia': '966',
    Senegal: '221',
    Serbia: '381',
    Seychelles: '248',
    'Sierra Leone': '232',
    Singapore: '65',
    'Sint Maarten': '1-721',
    Slovakia: '421',
    Slovenia: '386',
    'Solomon Islands': '677',
    Somalia: '252',
    'South Africa': '27',
    'South Korea': '82',
    'South Sudan': '211',
    Spain: '34',
    'Sri Lanka': '94',
    Sudan: '249',
    Suriname: '597',
    'Svalbard and Jan Mayen': '47',
    Swaziland: '268',
    Sweden: '46',
    Switzerland: '41',
    Syria: '963',
    Taiwan: '886',
    Tajikistan: '992',
    Tanzania: '255',
    Thailand: '66',
    Togo: '228',
    Tokelau: '690',
    Tonga: '676',
    'Trinidad and Tobago': '1-868',
    Tunisia: '216',
    Turkey: '90',
    Turkmenistan: '993',
    'Turks and Caicos Islands': '1-649',
    Tuvalu: '688',
    'U.S. Virgin Islands': '1-340',
    Uganda: '256',
    Ukraine: '380',
    'United Arab Emirates': '971',
    'United Kingdom': '44',
    'United States': '1',
    Uruguay: '598',
    Uzbekistan: '998',
    Vanuatu: '678',
    Vatican: '379',
    Venezuela: '58',
    Vietnam: '84',
    'Wallis and Futuna': '681',
    'Western Sahara': '212',
    Yemen: '967',
    Zambia: '260',
    Zimbabwe: '263',
};
