import { combineReducers } from '@reduxjs/toolkit';
import sharesRequestSlice from './shareSlice';
import sharesValidateLinkSlice from './validateLinkSlice';
import sharesValidateOtpSlice from './validateOtpSlice';
import getShareResultSlice from './shareResultSlice';

export default combineReducers({
    shares: sharesRequestSlice,
    result: getShareResultSlice,
    validateLink: sharesValidateLinkSlice,
    validateOtp: sharesValidateOtpSlice,
});
