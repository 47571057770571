/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { axiosPrivate } from '../../../services/api/apiCommonConfig';
import { VaazException, ISRequest } from '../../../services/api/initialStates';
import { commonStatusTypes } from '../../../services/constant';
import { API } from '../../api.v1';
import { RootState } from '../../store';

export const getRequestsFulfilment = createAsyncThunk(
    '/requests/fulfilment/get',
    async (requestId: string, { rejectWithValue }) => {
        try {
            const data = await axiosPrivate.get(
                `${API.REQUEST.FULFILMENT}/${requestId}`,
            );

            return data.data;
        } catch (error: unknown) {
            const err = error as VaazException;
            return rejectWithValue(err.response.data);
        }
    },
);

const getRequestsFulfilmentSlice = createSlice({
    name: '/requests/fulfilment/get',
    initialState: {
        response: ISRequest.fulfilment,
        errorMessage: '',
        status: commonStatusTypes.IDLE,
        loading: false,
    },
    reducers: {},
    extraReducers: builder => {
        builder
            .addCase(getRequestsFulfilment.pending, state => {
                state.loading = true;
                state.errorMessage = '';
                state.status = commonStatusTypes.IDLE;
            })
            .addCase(getRequestsFulfilment.rejected, (state, action) => {
                state.loading = false;
                state.status = commonStatusTypes.FAILED;

                const { payload } = action;
                const errMsg = payload as VaazException['response']['data'];
                const error = errMsg || action.error;
                state.errorMessage = error.message || '';
            })
            .addCase(getRequestsFulfilment.fulfilled, (state, action) => {
                state.loading = false;
                state.status = commonStatusTypes.SUCCESS;
                state.errorMessage = '';
                state.response = action.payload;
            });
    },
});

export const getRequestsFulfilmentResults = (state: RootState) =>
    state.requests.requestFulfilment;

export default getRequestsFulfilmentSlice.reducer;
