/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { axiosPrivate } from '../../../services/api/apiCommonConfig';
import { VaazException } from '../../../services/api/initialStates';
import { ReqShares } from '../../../services/api/requestBody';
import { commonStatusTypes } from '../../../services/constant';
import { API } from '../../api.v1';
import { RootState } from '../../store';

export const sharesValidateLink = createAsyncThunk(
    '/public/shares/validateLink',
    async (body: ReqShares['validateLink'], { rejectWithValue }) => {
        try {
            const { data } = await axiosPrivate.post(
                API.SHARES.VALIDATE_LINK,
                body,
            );
            return data;
        } catch (error: unknown) {
            const err = error as VaazException;
            return rejectWithValue(err.response.data);
        }
    },
);

const sharesValidateLinkSlice = createSlice({
    name: '/public/shares/validateLink',
    initialState: {
        response: {},
        loading: false,
        errorMsg: '',
        status: commonStatusTypes.IDLE,
    },
    reducers: {},
    extraReducers: builder => {
        builder
            .addCase(sharesValidateLink.pending, state => {
                state.loading = true;
                state.errorMsg = '';
                state.status = commonStatusTypes.IDLE;
            })
            .addCase(sharesValidateLink.rejected, (state, action) => {
                state.loading = false;
                state.status = commonStatusTypes.FAILED;

                const { payload } = action;
                const errMsg = payload as VaazException['response']['data'];
                const error = errMsg || action.error;
                state.errorMsg = error.message || '';
            })
            .addCase(sharesValidateLink.fulfilled, (state, action) => {
                state.loading = false;
                state.status = commonStatusTypes.SUCCESS;
                state.errorMsg = '';
                state.response = action.payload;
            });
    },
});

export const sharesValidateLinkResult = (state: RootState) =>
    state.shares.validateLink;

export default sharesValidateLinkSlice.reducer;
