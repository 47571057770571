/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { axiosPrivate } from '../../../services/api/apiCommonConfig';
import { VaazException } from '../../../services/api/initialStates';
import { commonStatusTypes } from '../../../services/constant';
import { API } from '../../api.v1';
import { RootState } from '../../store';

export const submitBeginFulfilment = createAsyncThunk(
    '/fulfilment/begin/submit',
    async (requestId: string, { rejectWithValue }) => {
        try {
            const { data } = await axiosPrivate.post(
                `${API.FULFILMENT.BEGIN}?requestId=${requestId}`,
            );
            return data;
        } catch (error: unknown) {
            const err = error as VaazException;
            return rejectWithValue(err.response.data);
        }
    },
);

const submitBeginFulfilmentSlice = createSlice({
    name: '/fulfilment/begin/submit',
    initialState: {
        response: {},
        loading: false,
        errorMessage: '',
        status: commonStatusTypes.IDLE,
    },
    reducers: {},
    extraReducers: builder => {
        builder
            .addCase(submitBeginFulfilment.pending, state => {
                state.loading = true;
                state.errorMessage = '';
                state.status = commonStatusTypes.IDLE;
            })
            .addCase(submitBeginFulfilment.rejected, (state, action) => {
                state.loading = false;
                state.status = commonStatusTypes.FAILED;

                const { payload } = action;
                const errMsg = payload as VaazException['response']['data'];
                const error = errMsg || action.error;
                state.errorMessage = error.message || '';
            })
            .addCase(submitBeginFulfilment.fulfilled, (state, action) => {
                state.loading = false;
                state.status = commonStatusTypes.SUCCESS;
                state.errorMessage = '';
                state.response = action.payload;
            });
    },
});

export const getBeginFulfilmentResults = (state: RootState) =>
    state.fulfilment.begin;

export default submitBeginFulfilmentSlice.reducer;
