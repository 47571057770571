/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { axiosPrivate } from '../../../services/api/apiCommonConfig';
import { VaazException } from '../../../services/api/initialStates';
import { commonStatusTypes } from '../../../services/constant';
import { API } from '../../api.v1';
import { RootState } from '../../store';

export const getFulfilmentRequestPDF = createAsyncThunk(
    '/fulfilments/generate/get',
    async (requestId: string, { rejectWithValue }) => {
        try {
            const { data, headers } = await axiosPrivate.get(
                `${API.FULFILMENT.GENERATE}${requestId}`,
                { responseType: 'blob' },
            );

            return { data, headers };
        } catch (error: unknown) {
            const err = error as VaazException;
            return rejectWithValue(err.response.data);
        }
    },
);

const generateFulfilmentRequestPdfSlice = createSlice({
    name: '/fulfilments/generate/get',
    initialState: {
        response: {},
        loading: false,
        errorMessage: '',
        status: commonStatusTypes.IDLE,
    },
    reducers: {},
    extraReducers: builder => {
        builder
            .addCase(getFulfilmentRequestPDF.pending, state => {
                state.loading = true;
                state.status = commonStatusTypes.IDLE;
            })
            .addCase(getFulfilmentRequestPDF.rejected, (state, action) => {
                state.loading = false;
                state.status = commonStatusTypes.FAILED;

                const { payload } = action;
                const errMsg = payload as VaazException['response']['data'];
                const error = errMsg || action.error;
                state.errorMessage = error.message || '';
            })
            .addCase(getFulfilmentRequestPDF.fulfilled, (state, action) => {
                state.loading = false;
                state.status = commonStatusTypes.SUCCESS;
                const { headers } = action.payload;
                state.response = { headers };
            });
    },
});

export const getGenerateFulfilmentRequestPdf = (state: RootState) =>
    state.requests.results.response;

export default generateFulfilmentRequestPdfSlice.reducer;
