/* eslint-disable react/jsx-props-no-spreading */
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import AppBarWithoutMenu from '../../components/AppBar/AppBarWithoutMenu';
import { AppBarBody } from '../../components/AppBar/AppBarBody';
import '../HistoryPage/HistoryPage.css';
import RemoveDataTab from './RemoveDataTab';
import { URLS } from '../../services/urls';
import { useTranslation } from 'react-i18next';
import { PrimaryButton } from '../../components/Button';
import { useNavigate, useParams } from 'react-router-dom';
import { useAppDispatch } from '../../store/hooks';
import { remove } from '../../store/slices/data/removeSlice';
import Spinner from '../../components/Spinner';
import { useState, useEffect } from 'react';
import CustomizedDialogs from '../../components/CustomizedDialogs';

export default function RemoveDataPage() {
    const { verificationCode } = useParams();
    const { t } = useTranslation();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    const [loading, setLoading] = useState(false);
    const [backdropOpen, setBackdropOpen] = useState(false);
    const [openDialog, setOpenDialog] = useState(false);
    const [titleDialog, setTitleDialog] = useState('Alert');
    const [dialogErrorMsg, setDialogErrorMsg] = useState('');
    const [isRemoveData, setIsRemoveData] = useState(true);

    useEffect(() => {
        localStorage.clear();
        setIsRemoveData(true);
    }, []);

    const onClickRemoveAllData = () => {
        console.log('onClickRemoveAllData go');
        setBackdropOpen(true);
        setLoading(true);
        const email = localStorage.getItem('userEmail');

        dispatch(remove(email as string)).then(res => {
            setLoading(false);
            setBackdropOpen(false);
            if (res.meta.requestStatus === 'fulfilled') {
                navigate(URLS.PUBLIC.REMOVE_DATA.REMOVED);
            }
            if (
                res.meta.requestStatus === 'rejected' &&
                res.payload.status !== 401
            ) {
                setTitleDialog(t('text.alert'));
                setDialogErrorMsg(res.payload.message);
                setOpenDialog(true);
            }
        });
    };

    return (
        <>
            {loading ? <Spinner open={backdropOpen} /> : <div />}
            {openDialog && (
                <CustomizedDialogs
                    open={openDialog}
                    title={titleDialog}
                    body={dialogErrorMsg}
                    primaryButton={t('button.ok')}
                    onPrimaryButtonClick={() => setOpenDialog(false)}
                    isContainDialogAction
                    isContainTwoButtons={false}
                />
            )}

            <AppBarWithoutMenu
                title={t('app_bar_title.remove_your_data')}
                link={URLS.MEMBER.HOME.MAIN}
            />
            <AppBarBody />
            <Box sx={{ pt: 2, pb: 4, pl: 4, pr: 4 }}>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <div className="montserrat-medium-black-17px">
                            {t('label.remove_your_data')}
                        </div>
                        <p
                            className="montserrat-medium-black-12px"
                            style={{ paddingTop: '10px' }}
                        >
                            {t('text.id_details_stored_in_the_request')}
                        </p>
                    </Grid>
                    <Grid item xs={12}>
                        <PrimaryButton
                            isFullWidth
                            buttonName={t('button.remove_your_data')}
                            type="reset"
                            disabled={false}
                            callback={onClickRemoveAllData}
                        />
                    </Grid>
                </Grid>
            </Box>
            <Box
                sx={{
                    pt: 2,
                    px: 1,
                    textAlign: 'center',
                }}
            >
                <Grid container spacing={1}>
                    <Grid item xs={12}>
                        <RemoveDataTab
                            isRemoveData={isRemoveData}
                            verificationCode={verificationCode as string}
                        />
                    </Grid>
                </Grid>
            </Box>
        </>
    );
}
