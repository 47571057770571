import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { useForm, Controller } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';
import { PrimaryButton } from '../../../components/Button';
import GraphicType2 from '../../../components/GraphicType2';
import loginGraphic from '../../../assets/img/frame-8@2x.svg';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import '../index.css';
import { useState } from 'react';
import {
    commonStatusTypes,
    localStorageTypes,
} from '../../../services/constant';
import AlertMessage from '../../../components/Alert';
import Spinner from '../../../components/Spinner';
import AppBarWithoutMenu from '../../../components/AppBar/AppBarWithoutMenu';
import { AppBarBody } from '../../../components/AppBar/AppBarBody';
import {
    sharesValidateOtp,
    sharesValidateOtpResult,
} from '../../../store/slices/shares/validateOtpSlice';
import { URLS } from '../../../services/urls';
import { ReqShares } from '../../../services/api/requestBody';
import { CustomizedState } from '../../../services/interfaces';
import { useTranslation } from 'react-i18next';

export function SharesOTPPage() {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const [loading, setLoading] = useState(false);
    const [backdropOpen, setBackdropOpen] = useState(false);
    const [otp, setOtp] = useState(new Array(4).fill(''));
    const location = useLocation();
    const { t } = useTranslation();

    const { verificationCode } = location.state as CustomizedState;

    const { control, handleSubmit } = useForm({
        mode: 'onChange',
    });

    const handleOtpChange = (
        event: React.BaseSyntheticEvent,
        index: number,
    ) => {
        setOtp([
            ...otp.map((d, idx) => (idx === index ? event.target.value : d)),
        ]);

        const nativeEvent = event.nativeEvent as InputEvent;

        // Focus next input
        if (
            event.target.nextSibling &&
            nativeEvent.inputType === 'insertText' &&
            event.target.value !== ''
        ) {
            event.target.nextSibling.focus();
        }

        if (
            event.target.previousSibling &&
            nativeEvent.inputType === 'deleteContentBackward' &&
            event.target.value === ''
        ) {
            event.target.previousSibling.focus();
        }
    };

    const onSubmit = () => {
        setLoading(true);
        setBackdropOpen(true);

        const requestBody: ReqShares['validateOtp'] = {
            code: verificationCode,
            otp: otp.join(''),
        };

        dispatch(sharesValidateOtp(requestBody)).then(res => {
            setLoading(false);
            if (res.meta.requestStatus === 'fulfilled') {
                const { requestId } = res.payload;

                localStorage.setItem(localStorageTypes.USER_AUTH, 'true');
                localStorage.setItem(localStorageTypes.NON_MEMBER, 'true');
                localStorage.setItem(
                    localStorageTypes.AUTH_TOKENS,
                    JSON.stringify(res.payload),
                );

                // navigate to request page
                navigate(URLS.NON_MEMBER.REQUEST.RESULTS, {
                    state: { requestId },
                });
            }
        });
    };

    const submitOtpResult = useAppSelector(sharesValidateOtpResult);

    return (
        <>
            {loading ? <Spinner open={backdropOpen} /> : <div />}

            <AppBarWithoutMenu
                title={t('app_bar_title.login')}
                link={URLS.PUBLIC.LOGIN.MAIN}
            />
            <AppBarBody />

            <form onSubmit={handleSubmit(() => onSubmit())}>
                <Box sx={{ p: 4, textAlign: 'center' }}>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            {submitOtpResult.status ===
                            commonStatusTypes.FAILED ? (
                                <AlertMessage
                                    showAlert
                                    errorMessage={submitOtpResult.errorMsg}
                                />
                            ) : (
                                <div />
                            )}
                            <GraphicType2 src={loginGraphic} />
                            <div
                                className="montserrat-medium-gunsmoke-12px"
                                style={{
                                    textAlign: 'left',
                                    paddingTop: '20px',
                                }}
                            >
                                One-Time Password
                            </div>
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                            }}
                        >
                            {otp.map((data, index) => {
                                return (
                                    <Controller
                                        // eslint-disable-next-line react/no-array-index-key
                                        key={`otp-${index}`}
                                        name="otp"
                                        control={control}
                                        defaultValue=""
                                        render={({ field: { onChange } }) => (
                                            <input
                                                id={`otp-${index}`}
                                                className="otp-field montserrat-medium-outer-space-16px"
                                                type="tel"
                                                name="otp"
                                                value={data}
                                                onChange={e => {
                                                    handleOtpChange(e, index);
                                                    onChange(e.target.value);
                                                }}
                                                onFocus={e => e.target.select()}
                                                inputMode="numeric"
                                                pattern="[0-9]*"
                                                maxLength={1}
                                            />
                                        )}
                                        rules={{
                                            required: 'Required',
                                        }}
                                    />
                                );
                            })}
                        </Grid>
                        <Grid item xs={12} style={{ textAlign: 'left' }}>
                            <p className="montserrat-medium-tuatara-12px">
                                Input the 4-digit code that was sent to your
                                email address
                            </p>
                        </Grid>
                        <Grid item xs={12}>
                            <PrimaryButton
                                isFullWidth
                                buttonName={`${t('button.next')}`}
                                disabled={false}
                                type="submit"
                            />
                        </Grid>
                        <Grid item xs={12} />
                    </Grid>
                </Box>
            </form>
        </>
    );
}
