import { Share } from '@mui/icons-material';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Toolbar from '@mui/material/Toolbar';
import { useNavigate } from 'react-router-dom';
import BackIcon from '../../../assets/img/arrow-left-1@2x.svg';
import FileDownload from '../../../assets/img/download-icon.png';
import { localStorageTypes } from '../../../services/constant';
import { AppBarProps } from '../../../services/interfaces';
import './AppBarWithoutMenu.css';

export default function AppBarWithoutMenu(props: AppBarProps) {
    const {
        title,
        link,
        includeShareButton,
        includeDownloadButton,
        callbackShareButton,
        callbackBackButton,
        callbackDownloadButton,
    } = props;

    const navigate = useNavigate();

    const onClickBackButton = () => {
        if (link !== '') {
            // remove the isUseSelfVerification upon click back
            localStorage.removeItem(localStorageTypes.IS_USE_SELF_VERIFICATION);
            navigate(link);
        } else {
            callbackBackButton?.();
        }
    };

    return (
        <Box sx={{ display: 'flex' }}>
            <AppBar position="fixed" className="appbar-style">
                <Toolbar>
                    <Grid container>
                        <Grid item xs={1} style={{ textAlign: 'left' }}>
                            <IconButton
                                onClick={onClickBackButton}
                                color="inherit"
                            >
                                <img alt="" src={BackIcon} />
                            </IconButton>
                        </Grid>
                        <Grid
                            item
                            xs={10}
                            style={{ textAlign: 'center', paddingTop: '13px' }}
                        >
                            <span className="montserrat-medium-black-16px">
                                {title}
                            </span>
                        </Grid>
                        {includeShareButton && (
                            <Grid item xs={1} style={{ textAlign: 'right' }}>
                                <IconButton
                                    onClick={callbackShareButton}
                                    color="inherit"
                                >
                                    <Share />
                                </IconButton>
                            </Grid>
                        )}
                        {includeDownloadButton && (
                            <Grid item xs={1} style={{ textAlign: 'right' }}>
                                <IconButton
                                    onClick={callbackDownloadButton}
                                    color="inherit"
                                    size="small"
                                >
                                    <img
                                        alt=""
                                        src={FileDownload}
                                        style={{ width: 35, height: 30 }}
                                    />
                                </IconButton>
                            </Grid>
                        )}
                    </Grid>
                </Toolbar>
            </AppBar>
        </Box>
    );
}
