/* eslint-disable react/jsx-props-no-spreading */
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import AppBarWithoutMenu from '../../../components/AppBar/AppBarWithoutMenu';
import { AppBarBody } from '../../../components/AppBar/AppBarBody';
import { URLS } from '../../../services/urls';
import { useTranslation } from 'react-i18next';
import GraphicType2 from '../../../components/GraphicType2';
import GraphicSuccess from '../../../assets/img/graphic-success.png';

export default function RemovedDataPage() {
    const { t } = useTranslation();

    return (
        <>
            <AppBarWithoutMenu
                title={t('app_bar_title.data_removed')}
                link={URLS.MEMBER.HOME.MAIN}
            />
            <AppBarBody />
            <Box sx={{ pt: 4, pl: 5, pr: 5, textAlign: 'center' }}>
                <Grid container spacing={1}>
                    <Grid item xs={12}>
                        <GraphicType2 src={GraphicSuccess} />
                    </Grid>
                </Grid>
            </Box>
            <Box sx={{ pt: 2, pb: 4, pl: 4, pr: 4 }}>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <div
                            className="montserrat-medium-black-17px"
                            style={{ lineHeight: '30px' }}
                        >
                            {t('text.successful_remove_data')}
                        </div>
                    </Grid>
                </Grid>
            </Box>
        </>
    );
}
