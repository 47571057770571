import { combineReducers } from '@reduxjs/toolkit';
import getOtpSlice from './otpSlice';
import getLoginSlice from './loginSlice';
import getLogoutSlice from './logoutSlice';
import getRefreshTokenSlice from './refreshTokenSlice';
import verifyRequestSlice from './verifyRequestsSlice';

export default combineReducers({
    otp: getOtpSlice,
    login: getLoginSlice,
    logout: getLogoutSlice,
    refreshToken: getRefreshTokenSlice,
    verifyRequest: verifyRequestSlice,
});
