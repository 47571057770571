/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { axiosPrivate } from '../../services/api/apiCommonConfig';
import { VaazException } from '../../services/api/initialStates';
import { commonStatusTypes } from '../../services/constant';
import { API } from '../api.v1';
import { RootState } from '../store';

export const getDashboard = createAsyncThunk(
    'register/parameter/get',
    async (email: string, { rejectWithValue }) => {
        try {
            const { data } = await axiosPrivate.get(`${API.DASHBOARD}${email}`);
            return data;
        } catch (error: unknown) {
            const err = error as VaazException;
            return rejectWithValue(err.response.data);
        }
    },
);

const getDashboardSlice = createSlice({
    name: 'register/parameter/get',
    initialState: {
        list: {
            email: '',
            fullName: '',
            recentActivities: [],
            creditBalance: 0,
            unreadMessageCount: 0,
            lastCreditTransactionDateTime: '',
            selfVerificationStatus: '',
            requestLeft: 0,
            requestSent: 0,
        },
        loading: false,
        errorMsg: '',
        status: commonStatusTypes.IDLE,
    },
    reducers: {},
    extraReducers: builder => {
        builder
            .addCase(getDashboard.pending, state => {
                state.loading = true;
                state.status = commonStatusTypes.IDLE;
            })
            .addCase(getDashboard.rejected, (state, action) => {
                state.loading = false;
                state.status = commonStatusTypes.FAILED;

                const { payload } = action;
                const errMsg = payload as VaazException['response']['data'];
                const error = errMsg || action.error;
                state.errorMsg = error.message || '';
            })
            .addCase(getDashboard.fulfilled, (state, action) => {
                state.loading = false;
                state.status = 'idle';
                state.errorMsg = '';
                state.list = action.payload;
            });
    },
});

export const getDashboardResults = (state: RootState) => state.dashboard;

export default getDashboardSlice.reducer;
