/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '../../../services/api/apiCommonConfig';
import { RootState } from '../../store';
import { commonStatusTypes } from '../../../services/constant';
import { VaazException } from '../../../services/api/initialStates';
import { FormValuesTypes } from '../../../services/types';
import { API } from '../../api.v1';

export const getTokenOtp = createAsyncThunk(
    '/oauth/token/otp',
    async (body: FormValuesTypes, { rejectWithValue }) => {
        try {
            const { data } = await axios.post(API.AUTHENTICATION.OTP, body);
            return data;
        } catch (error: unknown) {
            const err = error as VaazException;
            return rejectWithValue(err.response.data);
        }
    },
);

const getOtpSlice = createSlice({
    name: '/oauth/token/otp',
    initialState: {
        response: {
            email: '',
        },
        loading: false,
        errorMsg: '',
        status: commonStatusTypes.IDLE,
    },
    reducers: {},
    extraReducers: builder => {
        builder
            .addCase(getTokenOtp.pending, state => {
                state.loading = true;
                state.status = commonStatusTypes.IDLE;
            })
            .addCase(getTokenOtp.rejected, (state, action) => {
                state.loading = false;
                state.status = commonStatusTypes.FAILED;

                const { payload } = action;
                const errMsg = payload as VaazException['response']['data'];
                const error = errMsg || action.error;
                state.errorMsg = error.message || '';
            })
            .addCase(getTokenOtp.fulfilled, (state, action) => {
                state.loading = true;
                state.status = commonStatusTypes.SUCCESS;
                state.response = action.payload;
            });
    },
});

export const getTokenOtpResults = (state: RootState) =>
    state.authentication.otp;

export default getOtpSlice.reducer;
