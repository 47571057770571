import { lazy } from 'react';
import { URLS } from '../services/urls';

const routes = [
    {
        path: URLS.MEMBER.HOME.MAIN,
        exact: true,
        element: lazy(() => import('../containers/HomePage/index')),
    },
    {
        path: URLS.MEMBER.MESSAGES.MAIN,
        exact: true,
        element: lazy(() => import('../containers/MessagePage/index')),
    },
    {
        path: `${URLS.MEMBER.HISTORY.MAIN}/:tab`,
        exact: true,
        element: lazy(() => import('../containers/HistoryPage/index')),
    },
    {
        path: URLS.MEMBER.PROFILE.MAIN,
        exact: true,
        element: lazy(() => import('../containers/ProfilePage/MainPage/index')),
    },
    {
        path: URLS.MEMBER.PROFILE.EDIT,
        exact: true,
        element: lazy(() => import('../containers/ProfilePage/EditPage/index')),
    },
    {
        path: URLS.MEMBER.PROFILE.EDIT_SETTING_PAGE,
        exact: true,
        element: lazy(
            () => import('../containers/ProfilePage/SettingPage/index'),
        ),
    },

    // request
    {
        path: URLS.MEMBER.REQUEST.CREATE_NEW_REQUEST_CHECK_ITEMS,
        exact: true,
        element: lazy(
            () => import('../containers/RequestsPage/CreatePage/Page1/index'),
        ),
    },
    {
        path: URLS.MEMBER.REQUEST.CREATE_NEW_REQUEST_SUBJECT_DETAILS,
        exact: true,
        element: lazy(
            () => import('../containers/RequestsPage/CreatePage/Page2/index'),
        ),
    },
    {
        path: URLS.MEMBER.REQUEST.CREATE_NEW_REQUEST_CONFIRM,
        exact: true,
        element: lazy(
            () => import('../containers/RequestsPage/CreatePage/Page3/index'),
        ),
    },

    {
        path: URLS.MEMBER.REQUEST.INITIAL_VERIFY,
        exact: true,
        element: lazy(
            () =>
                import(
                    '../containers/RequestsPage/InitialVerificationPage/index'
                ),
        ),
    },
    {
        path: URLS.MEMBER.REQUEST.FULFILMENT_OPTION,
        exact: true,
        element: lazy(
            () =>
                import('../containers/RequestsPage/FulfilmentOptionPage/index'),
        ),
    },
    {
        path: URLS.MEMBER.REQUEST.VERIFY,
        exact: true,
        element: lazy(
            () => import('../containers/RequestsPage/VerificationPage/index'),
        ),
    },
    {
        path: URLS.MEMBER.REQUEST.ACCEPTED,
        exact: true,
        element: lazy(
            () => import('../containers/RequestsPage/TermAcceptedPage/index'),
        ),
    },

    {
        path: URLS.MEMBER.REQUEST.RESULTS,
        exact: true,
        element: lazy(
            () => import('../containers/RequestsPage/ResultsPage/index'),
        ),
    },
    {
        path: URLS.MEMBER.REQUEST.DETAILS,
        exact: true,
        element: lazy(
            () => import('../containers/RequestsPage/DetailsPage/index'),
        ),
    },
    {
        path: URLS.MEMBER.REQUEST.CANCELLED,
        exact: true,
        element: lazy(
            () => import('../containers/RequestsPage/CancelPage/index'),
        ),
    },

    // fulfilment
    {
        path: `${URLS.MEMBER.FULFILMENT.STATIC}:page`,
        exact: true,
        element: lazy(
            () => import('../containers/FulfilmentPage/StaticPage/index'),
        ),
    },
    {
        path: `${URLS.MEMBER.FULFILMENT.CAMERA}:page`,
        exact: true,
        element: lazy(
            () => import('../containers/FulfilmentPage/CameraPage/index'),
        ),
    },
    {
        path: `${URLS.MEMBER.FULFILMENT.PREVIEW}:page`,
        exact: true,
        element: lazy(
            () => import('../containers/FulfilmentPage/PreviewPage/index'),
        ),
    },
    {
        path: URLS.MEMBER.FULFILMENT.ID_TYPE,
        exact: true,
        element: lazy(
            () => import('../containers/FulfilmentPage/IDTypePage/index'),
        ),
    },

    // vaaz credits
    {
        path: URLS.MEMBER.VAAZCREDITS.MAIN,
        exact: true,
        element: lazy(() => import('../containers/CreditPage/MainPage/index')),
    },
    {
        path: URLS.MEMBER.VAAZCREDITS.ALL,
        exact: true,
        element: lazy(
            () => import('../containers/CreditPage/ShowAllPage/index'),
        ),
    },
    {
        path: URLS.MEMBER.VAAZCREDITS.TOPUP,
        exact: true,
        element: lazy(() => import('../containers/CreditPage/TopupPage/index')),
    },
    {
        path: URLS.MEMBER.VAAZCREDITS.CONTACT,
        exact: true,
        element: lazy(
            () => import('../containers/CreditPage/ContactFormPage/index'),
        ),
    },
    {
        path: URLS.MEMBER.VAAZCREDITS.TOPUP_OFFLINE,
        exact: true,
        element: lazy(
            () =>
                import(
                    '../containers/CreditPage/TopUpOfflinePaymentPage/index'
                ),
        ),
    },
    {
        path: URLS.MEMBER.VAAZCREDITS.TOPUP_OFFLINE_SUCCESS,
        exact: true,
        element: lazy(
            () =>
                import(
                    '../containers/CreditPage/TopUpOfflinePaymentSuccessfulPage/index'
                ),
        ),
    },
];

export default routes;
