import { useState } from 'react';
import { Collapse, Alert, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import ScrollToTop from '../ScrollToTop';
import { AlertProps } from '../../services/interfaces';

export default function AlertMessage(props: AlertProps) {
    const { showAlert, errorMessage, severity, children } = props;
    const [openAlert, setOpenAlert] = useState(showAlert);

    return (
        <>
            <ScrollToTop />
            <Collapse in={openAlert}>
                <Alert
                    severity={severity || 'error'}
                    action={
                        <IconButton
                            aria-label="close"
                            color="inherit"
                            size="small"
                            onClick={() => {
                                setOpenAlert(false);
                            }}
                        >
                            <CloseIcon fontSize="inherit" />
                        </IconButton>
                    }
                    sx={{ mb: 2 }}
                >
                    {children || errorMessage}
                </Alert>
            </Collapse>
        </>
    );
}
