/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { axiosPrivate } from '../../../services/api/apiCommonConfig';
import { VaazException } from '../../../services/api/initialStates';
import { commonStatusTypes } from '../../../services/constant';
import { API } from '../../api.v1';
import { RootState } from '../../store';

export const getSponsoredMessage = createAsyncThunk(
    '/requests/history/get',
    async (param: string, { rejectWithValue }) => {
        try {
            const { data } = await axiosPrivate.get(
                `${API.PROMOCODE.SPONSOR}${param}`,
            );
            return data;
        } catch (error: unknown) {
            const err = error as VaazException;
            return rejectWithValue(err.response.data);
        }
    },
);

const getSponsoredMessageSlice = createSlice({
    name: '/requests/history/get',
    initialState: {
        response: {
            totalPages: 0,
            totalElements: 0,
            contents: [],
        },
        loading: false,
        errorMessage: '',
        status: commonStatusTypes.IDLE,
    },
    reducers: {},
    extraReducers: builder => {
        builder
            .addCase(getSponsoredMessage.pending, state => {
                state.loading = true;
                state.errorMessage = '';
                state.status = commonStatusTypes.IDLE;
            })
            .addCase(getSponsoredMessage.rejected, (state, action) => {
                state.loading = false;
                state.status = commonStatusTypes.FAILED;

                const { payload } = action;
                const errMsg = payload as VaazException['response']['data'];
                const error = errMsg || action.error;
                state.errorMessage = error.message || '';
            })
            .addCase(getSponsoredMessage.fulfilled, (state, action) => {
                state.loading = false;
                state.status = commonStatusTypes.SUCCESS;
                state.errorMessage = '';
                state.response = action.payload;
            });
    },
});

export const getSponsoredMessageResults = (state: RootState) =>
    state.requests.history;

export default getSponsoredMessageSlice.reducer;
